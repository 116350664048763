import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import i18n from "../../../../i18n/i18n";
import { Lang } from "../../../../utils";
import DialogModal from "../../../Shared/Components/DialogModal/DialogModal";
import LoadingSpinner from "../../../Shared/Components/LoadingSpinner/LoadingSpinner";
import SharedSlider from "../../../Shared/SharedSlider/SharedSlider";
import TrainingRatingForm from "./TrainingRatingForm";
import TrainingRatingInfo from "./TrainingRatingInfo";
import classes from "./trainingRatingModal.module.css";
const TrainingRatingModal = ({ handleSubmit, handleCancel, courseInfo }) => {
  const { t } = useTranslation();
  const [trainersList, setTrainersList] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const trainers = courseInfo?.trainers;
    setTrainersList([...trainers]);
    setSelectedTrainer(courseInfo.manager);
  }, []);

  return (
    <DialogModal
      onCancel={handleCancel}
      className="tw-bg-white sm:tw-rounded-lg tw-shadow-xl tw-p-4 tw-space-y-4 tw-w-full sm:tw-w-2/3 lg:tw-w-1/2"
    >
      <div className={classes["rating-modal__container"]}>
        {isLoading ? (
          <div className={classes["loading__container"]}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            <div>
              <div className="tw-text-lg sm:tw-text-xl tw-text-teal-600">{t("courses_list.add_rate")}</div>
            </div>
            <SharedSlider
              slidesToShow={1}
              slidesToScroll={1}
              initialSlide={trainersList?.findIndex((trainer) => trainer.id === selectedTrainer?.id) || 0}
              isCustomButtons={true}
            >
              {trainersList?.map((trainer, index) => (
                <div
                  className={classes["training-rating__list"]}
                  key={index + 1}
                  dir={i18n.language === Lang.AR ? "rtl" : "ltr"}
                >
                  <TrainingRatingInfo
                    image={trainer?.img}
                    name={i18n.language === Lang.AR ? trainer?.name_ar : trainer?.name_en}
                    rating={trainer?.ratings_avg_stars}
                    description={trainer?.description}
                  />
                  <TrainingRatingForm
                    handleSubmit={(data) => {
                      handleSubmit(data, trainer?.id);
                    }}
                    rating={trainer?.ratings_avg_stars}
                  />
                </div>
              ))}
            </SharedSlider>
          </>
        )}
      </div>
    </DialogModal>
  );
};

export default TrainingRatingModal;
