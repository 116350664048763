import React, {createContext, useState} from "react";

const CourseContext = createContext();

function CourseProvider(props) {
    const [courses, setCourses] = useState([]);
    const [coursesHome, setCoursesHome] = useState([]);
    const [courseFilter, setCourseFilter] = useState([]);
    const [coursesSearch, setCoursesSearch] = useState([]);
    const [courseDetails, setCourseDetails] = useState([]);
    const [isContentLoaded, setIsContentLoaded] = useState(false);
    const [initialSpinnersCount] = useState(6);
    const [isLoading, setIsLoading] = useState(true);
    const [department, setDepartment] = useState(0);
    const [purchaseStatus, setPurchaseStatus] = useState("")
    const [coupon, setCoupon] = useState([])

    return (
        <CourseContext.Provider
            value={{
                courses,
                setCourses,
                coursesHome,
                setCoursesHome,
                coursesSearch,
                setCoursesSearch,
                courseDetails,
                setCourseDetails,
                courseFilter,
                setCourseFilter,
                isContentLoaded,
                setIsContentLoaded,
                initialSpinnersCount,
                isLoading,
                setIsLoading,
                department,
                setDepartment,
                coupon,
                setCoupon,
                purchaseStatus,
                setPurchaseStatus
            }}
        >
            {props.children}
        </CourseContext.Provider>
    );
}

export {CourseContext, CourseProvider};
