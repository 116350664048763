import React from "react";
import { useTranslation } from "react-i18next";
import ExportIcon from "../../../../assets/icons/exportCertificate.png";
import CloseIcon from "../../../../assets/icons/remove-red.svg";

export default function CertificateModal({ handleCancel, handleExport, selectedCourse }) {
  const { t } = useTranslation();
  return (
    <div
      className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-p-8  tw-text-center tw-space-y-4"
      style={{
        borderRadius: "20px",
        backgroundColor: "#fff",
        width: "350px",
      }}
    >
      <div className="tw-flex tw-items-center tw-justify-start tw-w-full">
        <div className="tw-cursor-pointer" onClick={handleCancel}>
          <img src={CloseIcon} alt="" className="tw-w-6 tw-h-6 tw-mr-2" />
        </div>
      </div>

      <img src={ExportIcon} alt="" className="tw-w-20 tw-h-20 tw-mb-4" />

      <div className="tw-text-lg tw-font-bold tw-text-gray-700 tw-mb-4" style={{ color: "#555555" }}>
        {t("export_language")}
      </div>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center" style={{ gap: "10px", width: "100%" }}>
        <button
          type="button"
          onClick={() => {
            handleExport({ courseId: selectedCourse, lang: "ar" });
          }}
          className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-32 tw-rounded tw-border-none -tw-top-4 export-btn"
          style={{ backgroundColor: "#ededed", width: "100%" }}
        >
          {t("export_arabic")}
        </button>
        <button
          type="button"
          onClick={() => {
            handleExport({ courseId: selectedCourse, lang: "en" });
          }}
          className="tw-flex tw-items-center tw-justify-center tw-h-12 tw-w-32 tw-rounded tw-border-none export-btn tw-mb-4"
          style={{ backgroundColor: "#ededed", width: "100%" }}
        >
          {t("export_english")}
        </button>
      </div>
    </div>
  );
}
