import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { NavLink, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getExamResult } from "../../../Services/api/Trainee/TraineeProvider";
import ArrowIcon from "../../../assets/icons/arrow.png";
import CheckIcon from "../../../assets/icons/check-circle.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import HomeIcon from "../../../assets/icons/home.svg";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import classes from "./ListExams.module.css";

function ExamResult(props) {
  require("./ExamResult.css");
  const history = useHistory();
  const [result, setResult] = useState(null);
  const [isPassed, setIsPassed] = useState(false);
  const [resultPercentage, setResultPercentage] = useState(0);
  const [showAnswers, setshowAnswers] = useState(false);
  const { t } = useTranslation();
  const { courseId, quizableId, quizId } = useParams();
  const location = useLocation();
  // const breadcrumbList = [
  //   {
  //     id: "home",
  //     page: <img src={HomeIcon} alt="" />,
  //     pagePath: "/",
  //   },
  //   {
  //     id: t("Courseoutline"),
  //     page: t("Courseoutline"),
  //     pagePath:
  //       props.quizable == "chapter"
  //         ? `/training-courses/${courseId}/content`
  //         : `/training-courses/${courseId}/content/${location.state.chapterId}/${location.state.supChapterId}/lessons`,
  //   },
  //   {
  //     id: t("trainer.quiz.exams"),
  //     page: t("trainer.quiz.exams"),
  //     pagePath: `/trainees/course/${courseId}/${props.quizable}/${quizableId}/exams`,
  //   },
  //   {
  //     id: "result",
  //     page: t("exam_result"),
  //     pagePath: `#`,
  //     active: true,
  //   },
  // ];

  useEffect(() => {
    getExamResult(quizId)
      .then((res) => {
        if (res.status == 200 && res.data.response.show_my_answered) {
          setResult(res.data.response);
          if (res.data.response.show_my_answered.score == 0) setIsPassed(false);
          else {
            setIsPassed(
              (res.data.response.show_my_answered.score / res.data.response.total_grade) * 100 >=
                res.data.response.success_rate
            );
            setResultPercentage((res.data.response.show_my_answered.score / res.data.response.total_grade) * 100);
            setshowAnswers(res.data.response.show_answers);
          }
        } else {
          history.goBack();
          toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{t("no_grade_yet")}</span>);
        }
      })
      .catch((err) => {
        history.goBack();
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg ? err.response.data.msg : "Failure in service"}
          </span>
        );
      });
  }, []);
  return (
    <div className={classes["exam_result"]}>
      <div className="container-fluid">
        <div className={classes["exam_result__container"]}>
          {/* <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div> */}
          <div className="row">
            <div className="col-12">
              <MainBox>
                <div className={classes["back"]} onClick={() => history.goBack()}>
                  <div className={classes["back-icon"]}>
                    <img src={ArrowIcon} alt="back" />
                  </div>
                  <div className={classes["back-text"]}>{t("back")}</div>
                </div>
                <div>
                  {result != null ? (
                    <div className="tajah-list-exams-result-header">
                      {isPassed ? (
                        <div className={classes["modal__icon"]}>
                          <img src={CheckIcon} alt="" />
                        </div>
                      ) : (
                        <div className={classes["modal__icon"]}>
                          <img src={DeleteIcon} alt="" />
                        </div>
                      )}
                      <p
                        style={{
                          fontSize: "22px",
                          color: "black",
                        }}
                      >
                        {t("trainee.quiz.exam_result")} {result.title}
                      </p>
                      <p
                        style={{
                          fontSize: "20px",
                          color: "black",
                        }}
                      >
                        {t("trainee.quiz.total_grade")} {result.total_grade}\{result.show_my_answered.score}
                      </p>

                      <div
                        style={{
                          width: "150px",
                          height: "150px",
                          margin: "auto",
                        }}
                      >
                        <CircularProgressbar
                          value={resultPercentage}
                          text={`${resultPercentage.toFixed(2)}%`}
                          styles={buildStyles({
                            rotation: 0.25,
                            strokeLinecap: "round",
                            textSize: "16px",
                            pathColor: isPassed ? "#046C77dd" : "#e62626",
                            textColor: "black",
                            trailColor: "#d6d6d6",
                            backgroundColor: "#126e77",
                          })}
                        />
                      </div>

                      {isPassed ? (
                        <p
                          style={{
                            fontSize: "20px",
                            color: "black",
                          }}
                        >
                          {t("trainee.quiz.congratulations")}
                        </p>
                      ) : (
                        <p
                          style={{
                            fontSize: "20px",
                            color: "black",
                          }}
                        >
                          {t("trainee.quiz.failed")}
                        </p>
                      )}
                      {showAnswers ? (
                        <NavLink
                          to={`/trainees/course/${courseId}/${props.quizable}/${quizableId}/exam/${quizId}/answers`}
                        >
                          <div className={`${classes["create_tax__form__field-actions"]}`}>
                            <button className={classes["create_tax__form__field-actions-submit"]} type="submit">
                              {t("trainee.quiz.check_your_answers")}
                            </button>
                          </div>
                        </NavLink>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : null}
                </div>
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExamResult;
