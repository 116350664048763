export const advertisementsListConstants = {
  // GET ALL   LIST
  GET_ADVERTISEMENTS_LIST_REQUEST: "GET_ADVERTISEMENTS_LIST_REQUEST",
  GET_ADVERTISEMENTS_LIST_SUCCESS: "GET_ADVERTISEMENTS_LIST_SUCCESS",
  GET_ADVERTISEMENTS_LIST_REJECTED: "GETADVERTISEMENTS_LIST_REJECTED",

  // ACTIVE
  ACTIVE_ADVERTISEMENT_REQUEST: "ACTIVE_ADVERTISEMENT_REQUEST",
  ACTIVE_ADVERTISEMENT_SUCCESS: "ACTIVE_ADVERTISEMENT_SUCCESS",
  ACTIVE_ADVERTISEMENT_REJECTED: "ACTIVE_ADVERTISEMENT_REJECTED",
  // DEACTIVE
  DEACTIVE_ADVERTISEMENT_REQUEST: "DEACTIVE_ADVERTISEMENT_REQUEST",
  DEACTIVE_ADVERTISEMENT_SUCCESS: "DEACTIVE_ADVERTISEMENT_SUCCESS",
  DEACTIVE_ADVERTISEMENT_REJECTED: "DEACTIVE_ADVERTISEMENT_REJECTED",
  // DELETE
  DELETE_ADVERTISEMENT_REQUEST: "DELETE_ADVERTISEMENT_REQUEST",
  DELETE_ADVERTISEMENT_SUCCESS: "DELETE_ADVERTISEMENT_SUCCESS",
  DELETE_ADVERTISEMENT_REJECTED: "DELETE_ADVERTISEMENT_REJECTED",
};
