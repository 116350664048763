import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  closestCenter,
  pointerWithin,
  rectIntersection,
  DndContext,
  DragOverlay,
  getFirstCollision,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensors,
  useSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  arrayMove,
  defaultAnimateLayoutChanges,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { coordinateGetter as multipleContainersCoordinateGetter } from "./multipleContainersKeyboardCoordinates";

import { Item, Container } from "./components";

import { useTranslation } from "react-i18next";

export default {
  title: "Presets/Sortable/Multiple Containers",
};

const animateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

function DroppableContainer({
  children,
  columns = 1,
  disabled,
  id,
  items,
  style,
  ...props
}) {
  const {
    active,
    attributes,
    isDragging,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
  } = useSortable({
    id,
    data: {
      type: "container",
      children: items,
    },
    animateLayoutChanges,
  });
  const isOverContainer = over
    ? (id === over.id && active?.data.current?.type !== "container") ||
      items.includes(over.id)
    : false;

  return (
    <Container
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
        transform: CSS.Translate.toString(transform),
        opacity: isDragging ? 0.5 : undefined,
      }}
      hover={isOverContainer}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      columns={columns}
      {...props}
    >
      {children}
    </Container>
  );
}

const dropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.5",
      },
    },
  }),
};

export function MultipleContainers({
  cancelDrop,
  options,
  answers,
  setAnswers,
  columns,
  handle = false,
  containerStyle,
  coordinateGetter = multipleContainersCoordinateGetter,
  getItemStyles = () => ({}),
  wrapperStyle = () => ({}),
  minimal = false,
  modifiers,
  renderItem,
  strategy = verticalListSortingStrategy,
  scrollable = false,
}) {
  const [items, setItems] = useState(options);

  const getOptionById = (id) => {
    var option = null;
    options.map((o) => {
      if (o.id == id) {
        option = o;
      }
    });
    return option;
  };

  const getFlagColor = (id) =>
    answers.find((ans) => ans.option_id == id)?.flagColor;

  const [activeId, setActiveId] = useState(null);
  const lastOverId = useRef(null);
  const recentlyMovedToNewContainer = useRef(false);
  const { t } = useTranslation();

  const getFromList = items.filter(
    (option) => !answers.find((ans) => ans.option_id == option.id)
  );

  const getToList = answers
    .sort((a, b) => a.value - b.value)
    .map((answer) => getOptionById(answer.option_id));

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy = useCallback(
    (args) => {
      if (activeId && ["A", "B"].includes(activeId)) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter((container) =>
            ["A", "B"].includes(container.id)
          ),
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);
      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);
      let overId = getFirstCollision(intersections, "id");
      if (overId != null) {
        if (["A", "B"].includes(overId)) {
          const containerItems = getContainer(overId).map((o) => o.id);

          // If a container is matched and it contains items (columns 'A', 'B', 'C')
          if (containerItems.length > 0) {
            // Return the closest droppable within that container
            overId = closestCenter({
              ...args,
              droppableContainers: args.droppableContainers.filter(
                (container) =>
                  container.id !== overId &&
                  containerItems.includes(container.id)
              ),
            })[0]?.id;
          }
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items, answers]
  );
  const [clonedItems, setClonedItems] = useState(null);
  const [clonedAnswers, setClonedAnswers] = useState(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter,
    })
  );
  const findContainer = (id) => {
    if (["A", "B"].includes(id)) {
      return id;
    }

    return getFromList.find((o) => o.id == id)
      ? "A"
      : getToList.find((o) => o.id == id)
      ? "B"
      : null;
  };

  const getContainer = (id) => {
    if (id == "A") {
      return getFromList;
    } else if (id == "B") {
      return getToList;
    }
  };

  const getIndex = (id) => {
    const container = findContainer(id);
    let index = -1;
    if (!container) {
      return -1;
    } else if (container == "A") {
      index = getFromList.findIndex((o) => o.id == id);
    } else if (container == "B") {
      index = getToList.findIndex((o) => o.id == id);
    }

    return index;
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items, answers]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
        setClonedAnswers(answers);
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;
        if (overId == null || ["A", "B"].includes(active.id)) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          const activeItems = getContainer(activeContainer);
          const overItems = getContainer(overContainer);
          const overIndex = overItems.findIndex((o) => o.id == overId);
          const activeIndex = activeItems.findIndex((o) => o.id == active.id);

          let newIndex;

          if (["A", "B"].includes(overId)) {
            newIndex = overItems.length + 1;
          } else {
            const isBelowOverItem =
              over &&
              active.rect.current.translated &&
              active.rect.current.translated.top >
                over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;

            newIndex =
              overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          recentlyMovedToNewContainer.current = true;

          const activeList = getContainer(activeContainer).filter(
            (item) => item.id !== active.id
          );

          const overList = [
            ...getContainer(overContainer).slice(0, newIndex),
            getContainer(activeContainer)[activeIndex],
            ...getContainer(overContainer).slice(
              newIndex,
              getContainer(overContainer).length
            ),
          ];
          if (activeContainer == "A" || overContainer == "B") {
            setAnswers(
              overList.map((item, index) => ({
                option_id: item.id,
                value: index,
              }))
            );
          } else if (activeContainer == "B" || overContainer == "A") {
            setAnswers(
              activeList.map((item, index) => ({
                option_id: item.id,
                value: index,
              }))
            );
          }
        }
      }}
      onDragEnd={({ active, over }) => {
        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        const overId = over?.id;

        if (overId == null) {
          setActiveId(null);
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          const activeIndex = getContainer(activeContainer).findIndex(
            (o) => o.id == active.id
          );
          const overIndex = getContainer(overContainer).findIndex(
            (o) => o.id == overId
          );

          if (activeIndex !== overIndex) {
            if (overContainer == "A") {
              let list = arrayMove(getFromList, activeIndex, overIndex);
              let listIds = list.map((l) => l.id);

              setItems([
                ...list,
                ...items.filter((item) => !listIds.includes(item.id)),
              ]);
            } else if (overContainer == "B") {
              setAnswers(
                arrayMove(getToList, activeIndex, overIndex).map(
                  (item, index) => ({ option_id: item.id, value: index })
                )
              );
            }
          }
        }

        setActiveId(null);
      }}
      onDragCancel={() => {
        if (clonedItems) {
          setItems(clonedItems);
        }

        if (clonedAnswers) {
          setAnswers(clonedAnswers);
        }

        setActiveId(null);
        setClonedItems(null);
        setClonedAnswers(null);
      }}
      cancelDrop={cancelDrop}
      modifiers={modifiers}
    >
      <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
        <DroppableContainer
          id={"A"}
          label={t(`drag_to_other_box`)}
          columns={columns}
          items={getFromList}
          scrollable={scrollable}
          style={containerStyle}
          unstyled={minimal}
        >
          <SortableContext items={getFromList} strategy={strategy}>
            {getFromList.map((value, index) => {
              return (
                <SortableItem
                  disabled={false}
                  key={value.id}
                  id={value.id}
                  value={value.value}
                  // index={index}
                  handle={handle}
                  style={getItemStyles}
                  wrapperStyle={wrapperStyle}
                  renderItem={renderItem}
                  containerId={"A"}
                  getIndex={getIndex}
                />
              );
            })}
          </SortableContext>
        </DroppableContainer>
        <DroppableContainer
          id={"B"}
          label={t(`drop_items_here`)}
          columns={columns}
          items={getToList}
          scrollable={scrollable}
          style={containerStyle}
          unstyled={minimal}
        >
          <SortableContext items={getToList} strategy={strategy}>
            {getToList.map((value, index) => {
              return (
                <SortableItem
                  disabled={false}
                  key={value.id}
                  id={value.id}
                  value={value.value}
                  index={index}
                  handle={handle}
                  style={getItemStyles}
                  wrapperStyle={wrapperStyle}
                  renderItem={renderItem}
                  containerId={"B"}
                  getIndex={getIndex}
                />
              );
            })}
          </SortableContext>
        </DroppableContainer>
      </div>
      {createPortal(
        <DragOverlay adjustScale={false} dropAnimation={dropAnimation}>
          {activeId && !["A", "B"].includes(activeId)
            ? renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );

  function renderSortableItemDragOverlay(id) {
    return (
      <Item
        value={getOptionById(id).value}
        flagColor={getFlagColor(id)}
        handle={handle}
        style={getItemStyles({
          containerId: findContainer(id),
          overIndex: -1,
          index: getIndex(id),
          value: id,
          isSorting: true,
          isDragging: true,
          isDragOverlay: true,
        })}
        color={"#fff"}
        wrapperStyle={wrapperStyle({ index: 0 })}
        renderItem={renderItem}
        dragOverlay
      />
    );
  }

  function SortableItem({
    disabled,
    id,
    value,
    index,
    handle,
    renderItem,
    style,
    containerId,
    getIndex,
    wrapperStyle,
  }) {
    const {
      setNodeRef,
      setActivatorNodeRef,
      listeners,
      isDragging,
      isSorting,
      over,
      overIndex,
      transform,
      transition,
    } = useSortable({
      id,
    });
    const mounted = useMountStatus();
    const mountedWhileDragging = isDragging && !mounted;

    return (
      <Item
        ref={disabled ? undefined : setNodeRef}
        value={value}
        flagColor={getFlagColor(id)}
        dragging={isDragging}
        sorting={isSorting}
        handle={handle}
        handleProps={handle ? { ref: setActivatorNodeRef } : undefined}
        index={index}
        wrapperStyle={wrapperStyle({ index })}
        style={style({
          index,
          value: id,
          isDragging,
          isSorting,
          overIndex: over ? getIndex(over.id) : overIndex,
          containerId,
        })}
        color={"#fff"}
        transition={transition}
        transform={transform}
        fadeIn={mountedWhileDragging}
        listeners={listeners}
        renderItem={renderItem}
      />
    );
  }
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}
