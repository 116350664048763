import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import EditIcon from "../../../assets/icons/pen.svg";
import MainTable from "../../Shared/Components/MainTable/MainTable";
import DeleteIcon from "../../../assets/icons/deleteTable.svg";
import DeleteIcon2 from "../../../assets/icons/delete.svg";
import classes from "./PlatformManagement.module.css";
import TableActionsColumn from "../../Shared/Components/TableActionsColumn/TableActionsColumn";
import SharedModal from "../../Shared/Components/SharedModal/SharedModal";
import SwitchToggle from "../../Shared/Components/SwitchToggle/SwitchToggle";
import { getMyPlatform } from "../../../Services/api/PlatformManagment/PlatformManagmentProvider";
import { deletePlatform } from "../../../Redux/Actions/PlatformManagement/PlatformManagement.action";
// import { cancelEvent } from "../../../Redux/Actions/events/events.action";
// import { toast } from "react-toastify";

const PlatformManagementTable = ({ sortAction, currentPage }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [platformId, setPlatformId] = useState(null);
  const [myGroups, setMyGroups] = useState([]);
  const [moveToId, setMoveToId] = useState(null);

  const handleChange = (id) => {
    setMoveToId(id);
  };

  const confirmDeleteEventHandler = () => {
    if (!platformId) return;
    dispatch(deletePlatform(platformId, moveToId));
    setShowDeleteModal(false);
  };
  const deleteHandler = (id) => {
    setShowDeleteModal(true);
    setPlatformId(id);
  };

  const { platformsList, platformsListLoading } = useSelector(
    (state) => state.platformsListReducer
  );

  const { t, i18n } = useTranslation();

  const utcToLocal = (utcDate) => {
    return moment.utc(utcDate).local().format("hh:mm A");
  };
  //   useEffect(() => {}, [systemUsersList]);

  useEffect(() => {
    getMyPlatform(10000)
      .then((res) => {
        if (res.status === 200) {
          //   console.log(res);
          setMyGroups(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const eventsActionsList = [
    {
      id: "edit",
      icon: <img src={EditIcon} alt="" />,
      action: (id, name) => {
        // if (name) {
        //   toast.error(
        //     <span style={{ fontSize: 13, fontWeight: "bold" }}>
        //       {t("cant_edit_cancel")}
        //     </span>
        //   );
        // } else {
        // }
        history.push(`/admin/PlatformManagement/${id}/edit`);
      },
      tooltip: t("general.edit"),
      color: "#24b3b9",
    },

    // {
    //   id: "delete",
    //   icon: <img src={DeleteIcon} alt="" />,
    //   action: (id) => {
    //     deleteHandler(id);
    //   },
    //   tooltip: t("general.delete"),
    //   color: "#e62626",
    // },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => (
        <>{rowIndex + 1 + (currentPage * 10 - 10)}</>
      ),
    },
    {
      id: "name",
      name: t("name"),
      key: "name",
      cell: ({ rowData }) => <div>{rowData?.name || "-"}</div>,
      sortable: false,
    },
    {
      id: "users_count",
      name: t("users_count"),
      key: "users_count",
      cell: ({ rowData }) => (
        <span
        //   className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
        //   style={{ backgroundColor: `${rowData?.users_count?.color}` }}
        >
          {rowData?.users_count}
        </span>
      ),
      sortable: false,
    },
    {
      id: "courses_count",
      name: t("courses_count"),
      key: "courses_count",
      cell: ({ rowData }) => <div>{rowData?.courses_count}</div>,
      sortable: false,
    },
    {
      id: "max_users_count",
      name: t("max_users_count"),
      key: "max_users_count",
      cell: ({ rowData }) => <>{rowData.max_users_count}</>,
      sortable: false,
    },

    {
      id: "created_at",
      name: t("general.created_at"),
      key: "created_at",
      cell: ({ rowData }) => (
        <>
          {moment(rowData.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData.created_at)}
        </>
      ),
      sortable: false,
    },
    {
      id: "status",
      name: t("status"),
      key: "status",
      cell: ({ rowData }) => (
        <span
          className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
          style={{
            color: "#fff",
            backgroundColor:
              rowData?.status.value !== "active"
                ? "rgba(165, 8, 8, 0.685)"
                : "#12C689",
          }}
        >
          {rowData?.status.text}
        </span>
      ),
      sortable: false,
    },

    {
      id: "actions",
      name: t("system_users.actions"),
      key: "actions",
      cell: ({ rowData }) => (
        <TableActionsColumn
          actions={eventsActionsList}
          id={rowData.id}
          //   name={rowData.canceled}
        />
      ),
      sortable: false,
    },
    // {
    //   id: "status",
    //   name: t("general.status"),
    //   key: "status",
    //   cell: ({ rowData }) => (
    //     <SwitchToggle
    //       status={rowData.status === "active" ? true : false}
    //       id={rowData.id}
    //       onStatusChange={() => {
    //         rowData.status === "active"
    //           ? deactiveAdStatusHandler(rowData.id)
    //           : activeAdStatusHandler(rowData.id);
    //       }}
    //     />
    //   ),
    //   sortable: false,
    // },
  ];
  // console.log("systemUsersList", systemUsersList);
  return (
    <>
      <MainTable
        columns={columns}
        data={platformsList || []}
        isLoading={platformsListLoading}
        sortAction={sortAction}
      />
      {showDeleteModal && (
        <SharedModal
          icon={DeleteIcon2}
          title={t("ad_delete_title")}
          confirmMsg={t("ad_delete_msg")}
          confirmText={t("general.confirm")}
          cancelText={t("general.cancel")}
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onConfirm={() => {
            confirmDeleteEventHandler();
          }}
          isDropDownList={true}
          dropDownLabel={t("moveTheDataTo")}
          dropDownOptions={myGroups}
          dropDownNote={t("requierd_if_not_empty")}
          dropDownOnChange={handleChange}
          isLoading={false}
        />
      )}
    </>
  );
};

export default PlatformManagementTable;
