import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getNeededDataCreatePlatform,
  getNeededDataEditPlatform,
  updatePlatform,
} from "../../../Services/api/PlatformManagment/PlatformManagmentProvider";
import PlatformManagmentForm from "./PlatformManagmentForm/PlatformManagmentForm";

export default function PlatformManagementEdit() {
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const [platformDetails, setPlatformDetails] = useState({});
  const [statuses, setStatuses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  // const [statuses, setStatuses] = useState([]);
  useEffect(() => {
    getNeededDataEditPlatform(id)
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          //   console.log(res);
          setStatuses(res.data.statuses);
          setPlatformDetails(res.data.group);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    getNeededDataCreatePlatform()
      .then((res) => {
        if (res.status === 200) {
          //   console.log(res);
          //  setStatuses(res.data.statuses);
          setAdmins(res.data.admins);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const submitHandler = async (formData) => {
    try {
      const response = await updatePlatform(formData, id);
      // console.log(response);
      if (response.status === 200) {
        history.push("/admin/PlatformManagement");
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("add_success")}
          </span>,
          {
            autoClose: 1000,
          }
        );
      } else {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {response.data.msg ? response.data.msg : "Failure in service"}
          </span>
        );
      }
    } catch (err) {
      toast.error(
        <>
          <span
            style={{
              fontSize: 13,
              fontWeight: "bold",
              display: "block",
              paddingBottom: "10px",
            }}
          >
            {err.response.data.msg}
          </span>
          <ul>
            {Object.keys(err.response.data.errors).map((key) => {
              return (
                <li>
                  <span style={{ fontSize: 11 }}>
                    {err.response.data.errors[key]}
                  </span>
                </li>
              );
            })}
          </ul>
        </>
      );
    }
  };

  return (
    <PlatformManagmentForm
      submitHandler={submitHandler}
      statuses={statuses}
      platformDetails={platformDetails}
      loading={loading}
      type={"Edit"}
      admins={admins}
    />
  );
}
