import env from "../Environment";
import connector from "../Handler";

// import LessonTypeURLFactory from "../../../Helpers/LessonTypeURLFactory";


export function getFaqList(params) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(env.FAQ_list,{
                    params: params
                })
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}
