import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router";
import DatePicker from "react-datepicker";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import List from "../../Admin/survey/list/List";

function TrainerSurveyManager({ role }) {
  require("./add.css");
  const { t } = useTranslation();
  const history = useHistory();
  const inputFile = useRef(null);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasFetchedCategories, setHasFetchedCategories] = useState(false);
  const [hasFetchedDepartments, setHasFetchedDepartments] = useState(false);
  const [categories, setCategories] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [toBeShownImage, setToBeShownImage] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  return (
    <>
      <div className="container-fluid">
        <div className="row bg-container">
          <div className="col-12 bg-container">
            <List isTrainer={true} role={role} />
          </div>
        </div>
      </div>
    </>
  );
}

export default TrainerSurveyManager;
