import React from "react";
import {PusherContext} from "../Contexts/PusherContext";

export function PusherProvider(props) {
  return (
    <PusherContext.Provider
      value={{
        pusher: props.instance
      }}
    >
      {props.children}
    </PusherContext.Provider>
  );
}
