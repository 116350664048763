import Paper from "@mui/material/Paper";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TrainerAddExamContext } from "../../../../Providers/TrainerAddExamProvider";
import Stepper from "../../../../Components/Stepper/Stepper";
import ExamBasicInfo from "./BasicInfo/BasicInfo";
import { useParams } from "react-router";
import CircularProgress from "@mui/material/CircularProgress";
import QuestionWrapper from "./Question/Wrapper/Wrapper";
import { NavLink } from "react-router-dom";
import toLocalTimezone from "../../../../Services/Timezone";

function AddExam(props) {
  require("./Add.css");
  const { t } = useTranslation();
  const trainerAddExamContext = useContext(TrainerAddExamContext);
  const [stepperLabels, setStepperLabels] = useState([
    t("trainer.quiz.add.labels.main_info"),
    t("trainer.quiz.add.labels.questions"),
  ]);

  const { id, quizable_id, exam_id } = useParams();

  const onInit = async () => {
    if (props.editable) {
      if (props.examInstance != null) {
        trainerAddExamContext.setExamPayload({
          title: props.examInstance.title,
          description: props.examInstance.description,
          start_date: toLocalTimezone(props.examInstance.start_date),
          end_date: toLocalTimezone(props.examInstance.end_date),
          period: props.examInstance.period,
          total_grade: props.examInstance.total_grade,
          success_rate: props.examInstance.success_rate,
          trainees: props.examInstance.trainees.map((item) => item.id),
          course_objectives: props.examInstance.course_objectives.map(
            (item) => item.id
          ),
        });

        trainerAddExamContext.setAddedQuestions(
          props.examInstance.added_questions
        );
        trainerAddExamContext.setObjectives(props.courseObjectives);
        trainerAddExamContext.setTrainees(props.trainees);
        trainerAddExamContext.setQuestionTypes(props.questionTypes);
        trainerAddExamContext.setActivePanel(props.activePanel);
        trainerAddExamContext.setSelectedQuestionPanel(
          props.selectedQuestionPanel
        );
        trainerAddExamContext.setIsLoading(false);
      } else {
        Promise.all([
          trainerAddExamContext.fetchQuestionTypes(),
          trainerAddExamContext.fetchCourseObjectives(id),
          trainerAddExamContext.fetchCourseTrainees(id),
          trainerAddExamContext.fetchExamData(exam_id),
        ]).then((values) => {
          trainerAddExamContext.setIsLoading(false);
        });
      }
    } else {
      Promise.all([
        trainerAddExamContext.fetchQuestionTypes(),
        trainerAddExamContext.fetchCourseTrainees(id),
        trainerAddExamContext.fetchCourseObjectives(id),
      ]).then((values) => {
        trainerAddExamContext.setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    trainerAddExamContext.setQuizable(props.quizable);
    trainerAddExamContext.setEditable(props.editable);
    onInit();
  }, []);

  return (
    <>
      <div className={"exam-canvas"}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div className="admin_label" style={{ marginBottom: 30 }}>
            <NavLink to={`/trainer/course/${id}`}>
              {t("trainer.quiz.to_course")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            <NavLink
              to={`/trainer/course/${id}/${trainerAddExamContext.quizable}/${quizable_id}/assessments`}
            >
              {t("trainer.quiz.exams")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {trainerAddExamContext.editable
              ? t("trainer.quiz.edit_exam")
              : t("trainer.quiz.add_exam")}
          </div>
        </div>

        <Paper
          variant={"outlined"}
          sx={{
            width: "100%",
            boxShadow: "2px 2px 10px #ddd",
            height: trainerAddExamContext.isLoading ? 500 : "auto",
          }}
        >
          {trainerAddExamContext.isLoading ? (
            <div className={"tajah-exam-progress"}>
              <CircularProgress />
            </div>
          ) : (
            <>
              <Stepper
                items={stepperLabels}
                activePanel={trainerAddExamContext.activePanel}
                nextTabAllowed={false}
              />

              <hr />
              <div
                className={"tajah-form-container"}
                style={{
                  boxShadow: "none",
                  borderRadius: 0,
                }}
              >
                {trainerAddExamContext.activePanel == 0 ? (
                  <ExamBasicInfo />
                ) : (
                  <QuestionWrapper />
                )}
              </div>
            </>
          )}
        </Paper>
      </div>
    </>
  );
}

export default AddExam;
