import connector from "../Handler";
import env from "../Environment";

export function profileInfo() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.profile_info)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function userList() {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.user_list)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function userActive(id) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.active_users, { user_id: id })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function getTrainersDues() {
    const myPromise = new Promise((resolve, reject) => {
      try {
        connector
          .get(env.get_trainers_dues)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  
    return myPromise;
  }