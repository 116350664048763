import React from "react";
import {useTranslation} from "react-i18next";
import DragAndDropSelectedAnswersItem from "./DragAndDropSelectedAnswerItem/DragAndDropSelectedAnswerItem";

function DragAndDropSelectedAnswers(props) {
    const {t} = useTranslation();

    return(
        <>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th scope="col">{t("trainer.quiz.crud.question_selected_option")}</th>
                    <th scope="col">{t("trainer.quiz.crud.image")}</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.answers.map((answer, index) => {
                        return (
                            <DragAndDropSelectedAnswersItem
                                key={index}
                                index={index}
                                name={answer.name}
                                image={answer.image}
                            />
                        )
                    })
                }
                </tbody>
            </table>
        </>
    )
}

export default DragAndDropSelectedAnswers;
