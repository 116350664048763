import { useTranslation } from "react-i18next";
import ViewReports from "../../../../../../assets/icons/analyze.svg";
import DeleteIcon from "../../../../../../assets/icons/delete.svg";
import DownloadIcon from "../../../../../../assets/icons/download2.svg";
import EyeIcon from "../../../../../../assets/icons/eye.svg";
import editIcon from "../../../../../../assets/icons/pen.svg";

export default function FileActions({ downloadAction, deleteAction, editAction, previewAction, reportsAction }) {
  const { t } = useTranslation();
  const actionsList = [
    ...(downloadAction
      ? [
          {
            id: "download",
            icon: <img src={DownloadIcon} alt="" />,
            action: downloadAction,
            toolTip: t("download"),
          },
        ]
      : []),
    ...(reportsAction
      ? [
          {
            id: "reports",
            icon: <img src={ViewReports} alt="" />,
            action: reportsAction,
            toolTip: t("participants"),
          },
        ]
      : []),
    ...(previewAction
      ? [
          {
            id: "preview",
            icon: <img src={EyeIcon} alt="" />,
            action: previewAction,
            toolTip: t("preview_content"),
          },
        ]
      : []),
    ...(editAction
      ? [
          {
            id: "edit",
            icon: <img src={editIcon} alt="" />,
            action: editAction,
            toolTip: t("edit"),
          },
        ]
      : []),
    ...(deleteAction
      ? [
          {
            id: "delete",
            icon: <img src={DeleteIcon} alt="" />,
            action: deleteAction,
            toolTip: t("delete"),
          },
        ]
      : []),
  ];
  return actionsList;
}
