import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import QuestionTypeEnum from "../../../../../../Enums/QuestionTypeEnum";
import { LessonContentContext } from "../../../../../../Providers/LessonContentProvider";
import { downloadFileFromLesson } from "../../../../../../Services/api/courses/courseProvider";
import DeleteIconModal from "../../../../../../assets/icons/delete.svg";
import { AssessmentQuestionPreview } from "../../../../../Admin/Assessments/Preview";
import CourseDetailsHeader from "../../../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import SharedModal from "../../../../../Shared/Components/SharedModal/SharedModal";
import FileActions from "./filesActions";

function LessonFile(props) {
  require("./File.css");

  const { t } = useTranslation();
  const { id } = useParams();
  const [preview, setPreview] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const lessonContentContext = useContext(LessonContentContext);
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    if (props.isTrainer || props.isAdmin || props.isManager) {
      setCanEdit(true);
    }
  }, [props.isTrainer, props.isAdmin, props.isManager]);

  const handleOpenModal = (event, targetId) => {
    event.stopPropagation();
    event.preventDefault();
    lessonContentContext.setTargetModalContent(deleteModal(targetId));
    lessonContentContext.setIsModalOpen(true);
  };

  const handleCloseModal = () => lessonContentContext.setIsModalOpen(false);

  const onDeleteFile = async (targetId) => {
    lessonContentContext.setInitialLoading(true);
    try {
      lessonContentContext.setIsModalOpen(false);
      lessonContentContext.setIsPerformingBackgroundOperation(true);
      props.isActivities
        ? await lessonContentContext.deleteActivityNew(targetId)
        : await lessonContentContext.deleteContent(targetId);
      await lessonContentContext.fetchLessonContent(id);
      lessonContentContext.setIsPerformingBackgroundOperation(false);
      lessonContentContext.setInitialLoading(false);
    } catch (error) {
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("delete_activity_failed_message")}
        </span>
      );
      lessonContentContext.setIsPerformingBackgroundOperation(false);
      lessonContentContext.setInitialLoading(false);
    }
  };

  const onFileDownload = async (name, extension, id) => {
    try {
      lessonContentContext.setIsPerformingBackgroundOperation(true);
      const type = props.isActivities ? "activity" : "contnet";
      await downloadFileFromLesson(name, extension, id, type);
      lessonContentContext.setIsPerformingBackgroundOperation(false);
    } catch (error) {
      lessonContentContext.setIsPerformingBackgroundOperation(false);
    }
  };

  const deleteModal = (targetId) => {
    return (
      <SharedModal
        icon={DeleteIconModal}
        title={t("modal.trainer.course.lesson.material.delete.title")}
        confirmMsg={t("modal.trainer.course.lesson.material.delete.body")}
        confirmText={t("general.confirm")}
        cancelText={t("general.cancel")}
        onCancel={handleCloseModal}
        onConfirm={() => onDeleteFile(targetId)}
        isLoading={false}
      />
    );
  };

  const displayedType = (type) => {
    switch (type) {
      case "directory":
        return t("trainer.course.lesson.directory.title");
      case "file":
        return t("trainer.course.lesson.file.title");
      case "pdf":
        return t("trainer.course.lesson.pdf.title");
      case "text":
        return t("trainer.course.lesson.text.title");
      case "video":
        return t("trainer.course.lesson.video.title");
      case "audio":
        return t("trainer.course.lesson.audio.title");
      case "xapi":
        return t("trainer.course.lesson.xapi.title");
      case "scorm":
        return t("trainer.course.lesson.scorm.title");
      case "html":
        return t("trainer.course.lesson.html.title");
      case "hotspot":
        return t("trainer.course.lesson.hotspot.title");
      case "h5p":
        return t("trainer.course.lesson.h5p.title");
      default:
        return "";
    }
  };
  // QuestionTypeEnum[`${props.type}`]
  const type_id =
    props.file.type === "text"
      ? QuestionTypeEnum[`${props.type}_1`]
      : QuestionTypeEnum[`${props.type}`];
  const questions = [
    {
      id: props.file.id,
      type_id: type_id,
      title: props.name,
      type: { name_ar: props.type, name_en: props.type },
      media:
        props.file.content_type === "content" && props.type === "scorm"
          ? {
              id: props.file.id,
              name: "scorm",
              path: props.file.link,
              size: "",
            }
          : props.file.type === "hotspot" || props.file.type === "html"
          ? { ...props.file.media, path: props.file.download }
          : props.file.media,
      options: [],
      link: props.file.link,
      download: props.file.download,
      extension: props.file.extension,

      content: props.file.content,
    },
  ];

  return (
    <>
      <div
        draggable={
          canEdit && !lessonContentContext.isPerformingBackgroundOperation
        }
        className={
          "tajah-lesson-file-row" +
          (!canEdit ? " tajah-lesson-file-row-trainee" : "") +
          (props.isClicked ? " tajah-lesson-file-row-active" : "")
        }
        id={"tajah-lesson-file-row-" + props.id}
        onClick={() => {
          if (!canEdit && !props.isPartner) {
            props.onFileClick(props.id);
          }
        }}
      >
        <CourseDetailsHeader
          title={`${props.name} - ${displayedType(props.type)}`}
          actions={FileActions({
            ...(props.extension &&
              !props.isPartner && {
                downloadAction: () => {
                  onFileDownload(props.name, props?.extension, props.id);
                },
              }),
            ...(props.isActivities && {
              reportsAction: () => {
                history.push({
                  pathname: `${
                    localStorage.getItem("type") === "group-admin"
                      ? "/group-admin"
                      : localStorage.getItem("type") === "manager"
                      ? "/manager"
                      : props.isAdmin
                      ? "/admin"
                      : localStorage.getItem("type") === "content_developer"
                      ? "/content_developer"
                      : props.isTrainer
                      ? "/trainer"
                      : ""
                  }/course/lesson/${id}/${props.id}/activities-grades`,
                  state: {
                    title: lessonContentContext.lessonInfo.title,
                    courseID: location?.state?.courseID,
                  },
                });
              },
            }),

            ...(canEdit
              ? {
                  deleteAction: (event) => {
                    handleOpenModal(event, props.id);
                  },
                  editAction: () => {
                    props.type === "text"
                      ? props.openTextUploadModal(props.id, props.file)
                      : props.type === "video"
                      ? props.openVideoLinkUploadModal(props.id, props.file)
                      : props.file.content_type === "content" &&
                        props.type === "scorm"
                      ? props.openFileUploadModal(
                          props.type,
                          props.id,
                          props.file,
                          props.isActivities,
                          setPreview,
                          true
                        )
                      : props.openFileUploadModal(
                          props.type,
                          props.id,
                          props.file,
                          props.isActivities,
                          setPreview
                        );
                  },
                }
              : {}),
          })}
          setOpened={() => {
            setPreview(!preview);
          }}
          opened={preview}
        />
        {preview && (
          <AssessmentQuestionPreview
            questions={questions}
            isActivity={true}
            isContent={true}
          />
        )}
      </div>
    </>
  );
}

export default LessonFile;
