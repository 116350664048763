import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import {NavLink} from "react-router-dom";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";
import {toast} from "react-toastify";
import {exportReport, getCourseDueReport} from "../../../../Services/api/report/ReportProvider";
import IconTooltips from '../../../../Components/tooltips/IconsTooltip'
import Xls from './../../../../assets/image/rating/xls.png'
import ReactPaginate from "react-paginate";
import i18n from "../../../../i18n/i18n";
import {Lang} from "../../../../utils";
import {BasicModal} from "../../../Shared/Components/Modal/BasicModal";
import ReportModal from "./ReportModal";

function CourseDueReport(props) {
    require("./totalIncomeReport.css")
    const {t} = useTranslation();
    const history = useHistory();
    const reportsModalRef = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [showFilters, setShowFilters] = useState(false);
    const [isPerformingBackgroundOperation, setIsPerformingBackgroundOperation] = useState(false)
    const [pageCount, setPageCount] = useState({})
    const [pageCountDegree, setPageCountDegree] = useState({})
    const [currentPage, setCurrentPage] = useState(1)
    const [type, setType] = useState("")
    const [statuses, setStatuses] = useState([
        {text_en: "Current", text_ar: 'الحالية', value: 'current'},
        {text_en: "Finished", text_ar: 'المنتهية', value: 'finished'},
        {text_en: "All", text_ar: 'الكل', value: 'all'}
    ]);
    const [currentFilter, setCurrentFilter] = useState({})
    const [currentFilterDegree, setCurrentFilterDegree] = useState({})
    const [reportsTrainee, setReportsTrainee] = useState([])
    const [quizzesTrainee, setQuizzesTrainee] = useState([])


    const fetchData = (params = null) => {
        return new Promise((resolve, reject) => {
            getCourseDueReport(clearEmptyProp(params))
                .then((res) => {
                    if (res.status && res.status == 200 && res.data.status) {
                        setReportsTrainee(res.data.gifts.data);
                        let pageCount = res.data.gifts.meta.total / res.data.gifts.meta.per_page
                        setPageCount(pageCount);
                        setIsLoading(false);
                        resolve();
                    } else {
                        reject("Error");
                    }
                })
                .catch((err) => {
                    toast.error(
                        <span style={{fontSize: 13, fontWeight: "bold"}}>
                    {t("failed_fetching")}
                </span>
                    );
                });
        });
    };

    useEffect(() => {
        fetchData(currentFilter);
    }, []);


    useEffect(() => {
        if (!isLoading) {
            document.getElementById('content-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
                parseInt(document.getElementById('content-header-bar').offsetHeight) + 40
            ).toString() + 'px';

            document.getElementById('content-body-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight)
            ).toString() + 'px'
        }
    }, [isLoading])

    useEffect(() => {
        if (!isLoading) {
            reAlignElementsHeights(showFilters)
        }
    }, [showFilters])


    const reAlignElementsHeights = (isFilterShown) => {
        if (!isFilterShown) {
            document.getElementById('content-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
                parseInt(document.getElementById('content-header-bar').offsetHeight) + 40
            ).toString() + 'px';

            document.getElementById('content-body-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight)
            ).toString() + 'px'
        } else {
            document.getElementById('content-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) +
                parseInt(document.getElementById('content-header-bar').offsetHeight) +
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) + 80
            ).toString() + 'px';

            document.getElementById('content-body-section').style.height = (
                parseInt(document.getElementsByClassName('tajah_table_vertical_movement')[0].offsetHeight) +
                parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) + 40
            ).toString() + 'px'
        }
    }

    const handlePageClick = (event) => {
        const toBeUpdatedFilters = currentFilter
        toBeUpdatedFilters.page = event.selected + 1
        setCurrentFilter(toBeUpdatedFilters);

        fetchData(toBeUpdatedFilters)
    };

    const handlePageClickDegree = (event) => {
        const toBeUpdatedFilters = currentFilterDegree
        toBeUpdatedFilters.page = event.selected + 1
        setCurrentFilterDegree(toBeUpdatedFilters);
    };


    const handleReportsModal = (type, report) => {
        setType(type)
        reportsModalRef.current.showModal();
    }

    return (
        <>
            <div className="container-fluid" id={"content-section"} style={{
                position: 'relative',
                padding: 0,
                paddingLeft: 0,
                paddingRight: 0,
            }}>
                <BasicModal ref={reportsModalRef}>
                    <ReportModal
                        label={type == 'degrees' ? t("modal.report.label.degrees") : t("modal.report.label.financial")}
                        title={type == 'degrees' ? t("modal.report.label.degrees") : t("modal.report.label.financial")}
                        body={type == 'degrees' ? quizzesTrainee : ['financial1', 'financial2']}
                        type={type}
                        reportsModalRef={reportsModalRef}
                        handleReportsModal={handleReportsModal}
                        pagination={
                            <ReactPaginate
                                nextLabel={t("next")}
                                onPageChange={handlePageClickDegree}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={pageCountDegree}
                                previousLabel={t("previous")}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        }
                    />
                </BasicModal>
                <div className="admin_flex" id="content-header-bar">
                    <div className="admin_label">
                        <NavLink to="/admin">
                            {t("admin.label.admin_label")}
                        </NavLink>
                        <p style={{padding: "0 5px"}}>/</p>
                        {t("report.label.trainee_report_courses")}
                    </div>
                </div>
                {isLoading == false ? (
                    <div id="content-body-section" style={{
                        position: 'absolute',
                        width: '100%'
                    }}>
                        {
                            isPerformingBackgroundOperation ?
                                <SkeletonCardOverlay borderRadius={10} top={40}/> : null
                        }

                        <div className="table_bank_tran tajah_table_vertical_movement" style={{
                            top: showFilters ? parseInt(document.getElementsByClassName('tajah_table_fade_change')[0].offsetHeight) + 40 : 0
                        }}>
                            <div className="tajah_card_title_container">
                                <div>{t("report.label.trainee_report_courses")}</div>
                                <div className={"tajah_card_title_container_actions"}>
                                    <button className={"btn btn-sm btn-primary color-button-excel"} style={{
                                        marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                        marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                                        backgroundColor: "transparent",
                                        borderColor: "transparent",
                                        borderWidth: 0
                                    }} onClick={async () => {
                                        try {
                                            setIsPerformingBackgroundOperation(true);
                                            await exportReport('trainee', clearEmptyProp(currentFilter));
                                            setIsPerformingBackgroundOperation(false);
                                        } catch (e) {
                                            setIsPerformingBackgroundOperation(false);
                                            toast.error(
                                                <span style={{fontSize: 13, fontWeight: "bold"}}>
                                                    {t("failed_fetching")}
                                                </span>
                                            );
                                        }
                                    }}>
                                        <IconTooltips
                                            title={t('modal.coupon.hover.titleDownloadExcel')}
                                            content={<img src={Xls} style={{width: 25}}/>}
                                        />
                                    </button>
                                </div>
                            </div>
                            <div className="table-responsive" style={{
                                width: '90%'
                            }}>
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">{t("report.label.trainer")}</th>
                                        <th scope="col"> {t("report.label.course")}</th>
                                        <th scope="col">{t("report.label.price")}</th>
                                        <th scope="col">{t("report.label.paid")}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reportsTrainee.length == 0 ? (
                                        <tr>
                                            <td
                                                colSpan={7}
                                                style={{fontSize: "20px", textAlign: "center"}}
                                            >
                                                {t("no_record")}
                                            </td>
                                        </tr>
                                    ) : (reportsTrainee.map((report, index) => {
                                        return (
                                            <tr key={index}>
                                                <td scope="row">{index + 1}</td>
                                                <td>{i18n.language === Lang.AR ? report.trainer.name_ar : report.trainer.name_en}</td>
                                                <td>{report.course.name}</td>
                                                <td>{report.value}{"        "}{i18n.language === Lang.AR ? "ر.س" : "SAR"}</td>
                                                <td>{report.payment_received}</td>
                                            </tr>
                                        );
                                    }))}
                                    </tbody>
                                </table>
                                <ReactPaginate
                                    nextLabel={t("next")}
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel={t("previous")}
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="mt-5" style={{
                        height: "65vh"
                    }}>
                        <SkeletonCard/>
                    </div>
                )}
            </div>
        </>
    )
}

export default CourseDueReport;
