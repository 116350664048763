import { useTranslation } from "react-i18next";
import classes from "./ContentView.module.css";
function AudioView({ question }) {
  const { t } = useTranslation();
  return (
    <div>
      <div className={classes["exhibition-details__audios-item"]}>
        <div className={classes["exhibition-details__audios-item-title"]}>
          {t("trainer.course.lesson.audio.previewtitle")}
        </div>
        <audio controls>
          <source src={question.download} />
        </audio>
      </div>
      <div style={{ paddingTop: 15 }}>
        <div
          dangerouslySetInnerHTML={{ __html: question.content }}
          className={classes["course-slider-item-content-inner-body"]}
        />
      </div>
    </div>
  );
}
export default AudioView;
