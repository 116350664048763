import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import i18n from "../../../../i18n/i18n";
export const filterActions = ({ showFilter, exportPdf, exportExcel }) => {
  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    // {
    //   id: "pdf",
    //   icon: <img src={PdfIcon} alt="" />,
    //   theme: "pdf",
    //   action: exportPdf,
    // },
    // {
    //   id: "xls",
    //   icon: <img src={XlsIcon} alt="" />,
    //   theme: "xls",
    //   action: exportExcel,
    // },
  ];
  return actionsList;
};
