import { useTranslation } from "react-i18next";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";
import classes from "./departmentsTrainees.module.css";

const DepartmentsTraineesFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  resetFilterHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  activeTab,
  role,
}) => {
  const { t, i18n } = useTranslation();

  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("department_trainees.trainee_name"),
      type: "text",
      placeholder: t("department_trainees.trainee_name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "email",
      component: "input",
      label: t("general.email"),
      type: "text",
      placeholder: t("general.email"),
      name: "email",
      value: filterData.email,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    ...(activeTab === 1
      ? [
          {
            id: "completion_rate",
            component: "input",
            label: t("general.completion_rate"),
            type: "number",
            placeholder: t("general.completion_rate"),
            name: "completion_rate",
            value: filterData.completionRate,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),

    ...(activeTab === 1
      ? [
          {
            id: "certificate",
            label: t("general.issuing_certificate"),
            component: "select",
            placeholder: t("general.issuing_certificate"),
            initialValue: t("general.issuing_certificate"),
            name: "certificate",
            value: filterData.certificate,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "1",
                value: true,
                label: t("yes"),
              },
              {
                id: "2",
                value: false,
                label: t("no"),
              },
            ],
          },
        ]
      : []),

    ...(activeTab === 1
      ? [
          {
            id: "assigned",
            label: t("department_trainees.subscription_set"),
            component: "select",
            placeholder: t("department_trainees.subscription_set"),
            initialValue: t("department_trainees.subscription_set"),
            name: "assigned",
            value: filterData.assigned,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "1",
                value: true,
                label: t("yes"),
              },
              {
                id: "2",
                value: false,
                label: t("no"),
              },
            ],
          },
        ]
      : []),

    {
      id: "enrolment_date",
      label: t("general.subscription_date"),
      component: "datePicker",
      placeholder: t("general.subscription_date"),
      name: "enrolment_date",
      value: filterData.enrolment_date,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
    },

    ...(activeTab === 1
      ? [
          {
            id: "payment_method",
            label: t("general.payment_method"),
            component: "select",
            placeholder: t("general.payment_method"),
            initialValue: t("general.payment_method"),
            name: "payment_method",
            value: filterData.payment_method,
            classes: `col-12 col-md-6 col-xl-${role === "partner" ? "4" : "2"}`,
            onchange: changeHandler,
            options: [
              {
                id: "wallet",
                value: "Wallet",
                label: t("financial.label.wallet"),
              },
              {
                id: "bankTransfer",
                value: "BankTrasfer",
                label: t("financial.label.bankTrasfer"),
              },
            ],
          },
        ]
      : []),
    ...(activeTab === 1 && role !== "partner"
      ? [
          {
            id: "status",
            label: t("general.status"),
            component: "select",
            placeholder: t("general.status"),
            initialValue: t("general.status"),
            name: "status",
            value: filterData.status,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: [
              {
                id: "active",
                value: "1",
                label: t("general.active"),
              },
              {
                id: "inactive",
                value: "0",
                label: t("general.inactive"),
              },
            ],
          },
        ]
      : []),
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default DepartmentsTraineesFilter;
