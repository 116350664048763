import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import classes from "./gift.module.css";

const ReceivedGiftTable = ({ loading, listGift }) => {
  const { t, i18n } = useTranslation();

  //   const [taxId, setTaxId] = useState(null);
  const history = useHistory();
  const displayMode = (is_program) => {
    if (is_program) {
      return t("general.program");
    } else {
      return t("rate.label.course");
    }
  };

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },
    {
      id: "parent_type",
      name: t("zoom.type"),
      key: "parent_type",
      cell: ({ rowData }) => (
        <>
          <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.course?.is_program ? "#12C689" : "#8599B6",
              backgroundColor: rowData?.course?.is_program
                ? "#C1E2C822"
                : "#C1CEE222",
            }}
          >
            {displayMode(rowData?.course?.is_program) || "-"}
          </span>
        </>
      ),
    },
    {
      id: "name",
      name: t("coupon.label.course_or_program"),
      key: "name",
      cell: ({ rowData }) => (
        <div className={classes["gift-list__table-name"]}>
          {rowData?.course?.name || "-"}
        </div>
      ),
    },
    // {
    //   id: "id",
    //   name: t("general.id"),
    //   key: "id",
    //   cell: ({ rowData }) => <>{rowData?.id}</>,
    //   sortable: false,
    // },

    {
      id: "gift_date",
      name: t("gift_date"),
      key: "gift_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.created_at).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.created_at)}
        </>
      ),
    },
    {
      id: "gifted_from",
      name: t("gifted_from"),
      key: "gifted_from",
      cell: ({ rowData }) => (
        <>
          {i18n.language === "ar"
            ? rowData?.sender?.name_ar
            : rowData?.sender?.name_en}
        </>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <MainTable columns={columns} data={listGift || []} isLoading={loading} />
    </>
  );
};

export default ReceivedGiftTable;
