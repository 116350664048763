import env from "./Environment";
import connector from "./Handler";

export function exportPeymentRequestsCourses(data, export_type, role) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          env.get_peyment_request_admin,
          // { role: role },
          {
            /////////////////
            responseType: "blob",
            params: {
              ...data,
              export: export_type,
            },
          }
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Payment Requests" + "." + export_type
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
export function exportPeymentRequestsPrograms(data, export_type, role) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(
          env.get_peyment_request_admin,
          {
            responseType: "blob",
            params: {
              ...data,
              export: export_type,
            },
          }
          // { role: role },
        )
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              "Payment Requests" + "." + export_type
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
