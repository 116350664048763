import React from "react";
import {useTranslation} from "react-i18next";
import DragAndDropProposedAnswersItem from "./DragAndDropProposedAnswersItem/DragAndDropProposedAnswersItem";

function DragAndDropProposedAnswers(props) {
    const {t} = useTranslation();

    const deleteItem = (index) => {
        let newAnswers = props.answers
        newAnswers.splice(index,1);
        props.onDelete(newAnswers);
    }

    return(
        <>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th scope="col">{t("trainer.quiz.crud.question_option")}</th>
                    <th scope="col">{t("trainer.quiz.crud.image")}</th>
                    <th scope="col">{t("crud.list.actions")}</th>
                </tr>
                </thead>
                <tbody>
                {
                    props.answers.map((answer, index) => {
                        return (
                            <DragAndDropProposedAnswersItem
                                key={index}
                                index={index}
                                name={answer.name}
                                image={answer.image}
                                deleteItem={deleteItem}
                                setImage={props.setImage}
                                setSubmitting={props.setSubmitting}
                            />
                        )
                    })
                }
                </tbody>
            </table>
        </>
    )
}

export default DragAndDropProposedAnswers;
