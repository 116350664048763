import { createTheme, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
	palette: {
		primary: {
			light: "#24B3B9",
			main: "#046C77",
		},
		error: {
			main: "#e62626",
		},
		grey: {
			100: "#AAAAAA",
			200: "#777777",
			300: "#EBEBEB",
			400: "#e5e7eb",
			500: "#6A6A6A",
		},
		text: {
			primary: "#121212",
			secondary: "#575757",
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 900,
			lg: 1280,
			xl: 1639,
		},
	},
	typography: {
		h1: {
			fontSize: "2.625rem", // 42px
		},
		h2: {
			fontSize: "2.25rem", // 36px
		},
		h3: {
			fontSize: "2rem", // 32px
		},
		h4: {
			fontSize: "1.75rem", // 28px
		},
		h5: {
			fontSize: "1.563rem", // 25px
		},
		h6: {
			fontSize: "1.25rem", // 20px
		},
		subtitle1: {
			fontSize: "1.125rem", // 18px
		},
		subtitle2: {
			fontSize: "1.063rem", // 17px
		},
		body1: {
			fontSize: "1rem", // 16px
		},
		body2: {
			fontSize: "0.938rem", // 15px
		},
		button: {
			fontSize: "0.875rem", // 14px
		},
		caption: {
			fontSize: "0.813rem", // 13px
		},
		overline: {
			fontSize: "0.75rem", // 12px
		},
	},
	shadows: [
		"none",
		"0px 3px 6px 0px rgba(0 0 0 / 16%)",
		"0px 0px 16px -5px rgb(0 0 0 / 10%)",
		"0px 0px 97px 31px rgb(0 0 0 / 5%)",
		"0px 5px 6px 0px rgba(0 0 0 / 0.06)",
		"0px 0px 25px 0px rgba(0 0 0 / 0.05)",
		"0px 4px 4px 0px rgba(0 0 0 / 0.06)",
		"0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
		"0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
		"0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
		"0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
		"0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px…gba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
		"0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px…gba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
		"0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px…gba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
		"0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px…gba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
		"0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px…gba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
		"0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px…gba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
		"0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2p…gba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
		"0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2p…gba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
		"0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2p…gba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
		"0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2p…gba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
		"0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3…gba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
		"0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3…gba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
		"0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3…gba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
		"0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3…gba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
	],
});

theme = responsiveFontSizes(theme);

export default theme;
