import { Formik } from "formik";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";

import HomeIcon from "../../../../assets/icons/home.svg";

import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";

import Input from "../../../Shared/Components/Input/Input";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";

import classes from "./PlatformManagmentForm.module.css";

import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";

import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import Checkbox from "../../../Shared/Components/Checkbox/Checkbox";
import InputFile from "../../../Shared/Components/InputFile/InputFile";

const PlatformManagmentForm = ({
  type,
  submitHandler,
  platformDetails,
  admins,
  statuses,
  loading,
}) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [admin_details, setAdmin_details] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const downloadFile = (url) => {
    setIsLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = URL.createObjectURL(blob).split("/").slice(-1)[0];
        link.click();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (platformDetails?.admin) {
      setAdmin_details(true);
    }
  }, [platformDetails]);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "events",
      page: t("PlatformManagementlist"),
      pagePath: "/admin/PlatformManagement",
    },
    {
      id: "create_event",
      page: type !== "Edit" ? t("add_platform") : platformDetails?.name,
      active: true,
    },
  ];

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div className={classes["create_event"]}>
      <div className="container-fluid">
        <div className={classes["create_event__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          {!isLoading && !loading && (
            <div className="row">
              <div className="col-12">
                <MainBox>
                  <Formik
                    initialValues={{
                      ...(type === "Edit" ? { _method: "put" } : {}),
                      name_ar: platformDetails?.name_ar || "",
                      name_en: platformDetails?.name_en || "",
                      description_ar: platformDetails?.description_ar || "",
                      description_en: platformDetails?.description_en || "",
                      // location: platformDetails?.location || "",
                      // is_default: platformDetails?.is_default ? 1 : 0 || 0,
                      max_users_count: platformDetails?.max_users_count || "",
                      user_id: platformDetails?.users[0].id || "",
                      // admin: platformDetails?.admin || [],
                      // admin_name_en: "",
                      // admin_name_ar: "",
                      // admin_email: "",
                      status: platformDetails?.status?.value || "",

                      file: platformDetails?.image || "",
                    }}
                    onSubmit={async (values, { setSubmitting }) => {
                      console.log(values);
                      try {
                        if (admin_details) {
                          values.admin = [
                            {
                              name_ar: values.admin_name_ar,
                              name_en: values.admin_name_en,
                              email: values.admin_email,
                            },
                          ];
                        }
                        let formData = new FormData();
                        Object.keys(values).forEach((field) => {
                          if (field === "file") {
                            values[field]?.file
                              ? formData.append("image", values[field].file)
                              : formData.append("remove_image", 1);
                          } else {
                            formData.append(field, values[field]);
                          }
                        });
                        // if (admin_details) {
                        //   formData.delete("user_id");
                        // }

                        // if (type === "Edit") {
                        //   formData.delete("user_id");
                        //   formData.delete("admin");
                        //   formData.delete("admin_name_ar");
                        //   formData.delete("admin_name_en");
                        //   formData.delete("admin_email");
                        // }
                        // if (!admin_details) {
                        //   formData.delete("admin");
                        //   formData.delete("admin_name_ar");
                        //   formData.delete("admin_name_en");
                        //   formData.delete("admin_email");
                        // }

                        await submitHandler(formData);
                        setSubmitting(false);
                      } catch (err) {
                        console.log("err", err);
                        toast.error(
                          <div>
                            {Object.keys(err.response.data.errors).map(
                              (key) => {
                                return (
                                  <span style={{ fontSize: 12 }}>
                                    {err.response.data.errors[key]}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        );
                      }
                    }}
                    validateOnChange={hasSubmitted}
                    validate={(values) => {
                      setHasSubmitted(true);
                      const errors = {};
                      if (!values.name_ar) {
                        errors.name_ar = t("crud.errors.required");
                      }
                      if (!values.name_en) {
                        errors.name_en = t("crud.errors.required");
                      }

                      if (!values.description_ar) {
                        errors.description_ar = t("crud.errors.required");
                      }

                      if (!values.description_en) {
                        errors.description_en = t("crud.errors.required");
                      }

                      if (!values.status) {
                        errors.status = t("crud.errors.required");
                      }
                      if (!values.user_id) {
                        errors.user_id = t("crud.errors.required");
                      }
                      if (
                        values.max_users_count < platformDetails?.users_count
                      ) {
                        errors.max_users_count = t(
                          "crud.errors.max_users_count"
                        );
                      }
                      if (values.max_users_count <= 0) {
                        errors.max_users_count = t(
                          "crud.errors.has_to_be_more_than_zero"
                        );
                      }

                      return errors;
                    }}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      handleBlur,
                      setFieldValue,
                      validateForm,
                    }) => (
                      <form
                        onSubmit={handleSubmit}
                        className={classes["create_event__form"]}
                      >
                        <MainBoxHead
                          title={
                            type !== "Edit"
                              ? t("add_platform")
                              : platformDetails?.title
                          }
                        />
                        <div
                          className={`row ${classes["create_event__form__fields"]}`}
                        >
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("title_ar_input")}
                              type="text"
                              name="name_ar"
                              placeholder={t("title_ar_input")}
                              onChange={handleChange}
                              error={errors.name_ar}
                              value={values.name_ar}
                              required={true}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("title_en_input")}
                              type="text"
                              name="name_en"
                              placeholder={t("title_en_input")}
                              onChange={handleChange}
                              error={errors.name_en}
                              value={values.name_en}
                              required={true}
                            />
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description_ar"
                              style={{ color: "#777777" }}
                            >
                              {t(`specialties_manage.specialty_description_ar`)}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <textarea
                              id="description_ar"
                              name="description_ar"
                              placeholder={t(
                                `specialties_manage.specialty_description_ar`
                              )}
                              value={values.description_ar}
                              defaultValue={values.description_ar}
                              style={{
                                border: "1px solid rgb(201, 200, 200)",
                                display: "block",
                                width: "100%",
                                borderRadius: "6px",
                                padding: "15px",
                              }}
                              maxLength={250}
                              onChange={handleChange}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.description_ar
                                ? errors.description_ar
                                : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <label
                              htmlFor="description_en"
                              style={{ color: "#777777" }}
                            >
                              {t(`specialties_manage.specialty_description_en`)}
                              <span style={{ color: "red" }}>*</span>
                            </label>

                            <textarea
                              id="description_en"
                              name="description_en"
                              placeholder={t(
                                `specialties_manage.specialty_description_en`
                              )}
                              value={values.description_en}
                              defaultValue={values.description_en}
                              style={{
                                border: "1px solid rgb(201, 200, 200)",
                                display: "block",
                                width: "100%",
                                borderRadius: "6px",
                                padding: "15px",
                              }}
                              maxLength={250}
                              onChange={handleChange}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.description_en
                                ? errors.description_en
                                : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <Input
                              label={t("max_users_count")}
                              type="number"
                              name="max_users_count"
                              placeholder={t("max_users_count")}
                              onChange={handleChange}
                              error={errors.max_users_count}
                              value={values.max_users_count}
                              // min={1}
                              required={true}
                              //   required={true}
                              // disabled={values.is_default}
                            />
                          </div>

                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <label htmlFor="mechanism">
                              {t("status")}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              name="status"
                              id="status"
                              options={statuses}
                              value={statuses?.find(
                                (option) => option.value == values.status
                              )}
                              getOptionLabel={(option) => option.text}
                              getOptionValue={(option) => option.value}
                              onChange={(item) => {
                                setFieldValue("status", item.value);
                              }}
                              placeholder={t("status")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.status ? errors.status : null}
                            </p>
                          </div>
                          <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <InputFile
                              name="file"
                              type="document"
                              documentWithoutTitle={true}
                              accept={"image/*"}
                              label={t("Exhibition.upload_image")}
                              placeholder={t("Exhibition.upload_image")}
                              handleDownload={() => {
                                downloadFile(values.file);
                              }}
                              note={t("image_ad_note")}
                              setFieldValue={setFieldValue}
                              value={values.file}
                              error={errors.file}
                            />
                          </div>
                          {/* <div
                            className={`${classes["create_event__form__field"]} col-12`}
                          >
                            <Checkbox
                              name="is_default"
                              label={t(`is_default`)}
                              onChange={(event) => {
                                setFieldValue(
                                  "is_default",
                                  event.target.checked
                                    ? 1
                                    : platformDetails?.is_default
                                    ? 1
                                    : 0
                                );
                              }}
                              checked={
                                values.is_default === true ||
                                values.is_default === 1
                              }
                              error={errors.is_default}
                            />
                          </div> */}

                          <div
                            className={`${classes["create_event__form__field"]} col-12 col-md-6`}
                          >
                            <label htmlFor="user_id">
                              {t("SelectAdmin")}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              name="user_id"
                              id="user_id"
                              options={admins}
                              value={admins?.find(
                                (option) => option.id == values.user_id
                              )}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              onChange={(item) => {
                                setFieldValue("user_id", item.id);
                              }}
                              placeholder={t("SelectAdmin")}
                            />
                            <p className={"form-input-error-space"}>
                              {errors.user_id ? errors.user_id : null}
                            </p>
                          </div>

                          <div
                            className={`${classes["event__form__submit__container"]} col-12`}
                          >
                            <CustomButton
                              type="submit"
                              colors="#036c77"
                              value={type === "Edit" ? t("Edit") : t("create")}
                              disable={isSubmitting}
                              loading={isSubmitting}
                            />
                          </div>
                        </div>
                        {isSubmitting && <SkeletonCardOverlay />}
                      </form>
                    )}
                  </Formik>
                </MainBox>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PlatformManagmentForm;
