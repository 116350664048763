import StarsRating from '../../../Shared/Components/StarsRating/StarsRating'
import classes from "./trainingRatingModal.module.css"

const TrainingRatingInfo = ({
  image,
  name,
  rating,
  description,
}) => {
  return (
    <div className={classes.trainer_container}>
    <div
      className={classes.trainer_image}
    >
      <img src={image} alt={name} />
    </div>
    <div className={classes.trainer_info}>
      <div
        className={classes.trainer_name}
      >
        {name}
      </div>
      <div className={classes.trainer_rating}>
        <StarsRating rating={Number(rating)} cutStyle={classes.star_font_size} />
      </div>
      <div className={classes.trainer_description}>{description}</div>
    </div>
  </div>
  )
}

export default TrainingRatingInfo