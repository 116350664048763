import {useTranslation} from "react-i18next";
import React from "react";

function CoursesCouponModal(props) {
        require("./coursesCouponModal.css");
  const { t } = useTranslation();

    return (
        <>
            <div
                className={"tajah-basic-modal-md"}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className={"tajah-basic-modal-header"}>
                    <h4>{props.title}</h4>
                    <div>
                        <i
                            className="fas fa-times tajah-modal-dismiss"
                            onClick={() => {
                                props.coursesCouponModalRef.current.dismissModal();
                            }}
                        ></i>
                    </div>
                </div>

                <div className={"tajah-basic-modal-body"}>
                <div className="table-responsive" style={{
                    width: '90%'
                  }}>
                  {props.body.length == 0 ? (
                    <p style={{textAlign:'center'}}>{t('coupon.course.notFound')}</p>
                  ):(
                    <table className="table table-bordered">
                      <thead>
                      <tr style={{textAlign:'center'}}>
                        <th scope="col">#</th>
                        <th scope="col">{t("coupon.course.name")}</th>
                        <th scope="col"> {t("coupon.course.status")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {props.body.map((course,index) => {
                        return (
                            <tr style={{textAlign:'center'}} key={course.id}>
                              <td scope="row">{index+1}</td>
                              <td>{course.name}</td>
                              <td>{course.is_used == true ? t("coupon.label.used") : t("coupon.label.not_used")}</td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  )}
                  </div>
                </div>
            </div>
        </>
    );
}

export default CoursesCouponModal;
