import React from "react";
import { ChatProvider } from "../../Services/api/chat/ChatContext";
import ContainerChat from "./ContainerChat";

function Chat(props) {
  return (
    <ChatProvider>
      <ContainerChat />
    </ChatProvider>
  );
}

export default Chat;
