import connector from "../Handler";
import env from "../Environment";
import URLBuilder from "../UrlBuilder";

export function getLearningAnalysisRating(id = null) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(id == null ? env.learning_analysis_rating : URLBuilder(env.learning_analysis_rating_by_id, {id: id}))
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function getLearningAnalysisRegistered(id = null) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get((id == null ? env.learning_analysis_registered : URLBuilder(env.learning_analysis_registered_by_course_id, {id: id})))
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}

export function getLearningAnalysisStatistics(id = null) {
    const myPromise = new Promise((resolve, reject) => {
        try {
            connector
                .get(id == null ? env.learning_analysis_statistics : URLBuilder(env.learning_analysis_statistics_by_id, {id: id}))
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });

    return myPromise;
}