export const downloadFile = (url) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = URL.createObjectURL(blob).split("/").slice(-1)[0];
      link.click();
    })
    .catch(console.error)
    .finally(() => {});
};
