import { faqConstant } from "../../Constants/faq/faq.constant";

const initialState = {
  faqListResponse: {},
  faqList: [],
  loading: false,
  msg: null,
  deleteStatus: null,
  faqGeneralList: [],
  faqGeneral: [],

  addStatus: null,
  addResponse: {},
  deleteResponse: {},
  editResponse: {},
  showResponse: {},
  changeStatusResponse: {},
  reOrderResponse: {},

  // question
  deleteQuestionStatus: null,
  addQuestionStatus: null,
  editQuestionStatus: null,
  addQuestionResponse: {},
  deleteQuestionResponse: {},
  editQuestionResponse: {},
  showQuestionResponse: {},
  changeStatusQuestionResponse: {},
  reOrderQuestionResponse: {},
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case faqConstant.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case faqConstant.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case faqConstant.GET_FAQ_GENERAL_CATEGORIES_LIST:
      return {
        ...state,
        faqGeneralList: action.payload.response,
        faqGeneral: action.payload.data,
      };

    // CATEGORY
    case faqConstant.GET_FAQ_CATEGORY_LIST:
      return {
        ...state,
        faqListResponse: action.payload.response,
        faqList: action.payload.data,
      };

    case faqConstant.GET_FAQ_CATEGORY_ADD:
      return {
        ...state,
        addResponse: action.payload,
      };
    case faqConstant.GET_FAQ_CATEGORY_DELETE:
      return {
        ...state,
        deleteResponse: action.payload,
      };
    case faqConstant.GET_FAQ_CATEGORY_EDIT:
      return {
        ...state,
        editResponse: action.payload,
      };
    case faqConstant.GET_FAQ_CATEGORY_STATUS_CHANGE:
      return {
        ...state,
        changeStatusResponse: action.payload,
      };

    case faqConstant.GET_FAQ_CATEGORY_SHOW:
      return {
        ...state,
        showResponse: action.payload,
      };

    case faqConstant.GET_FAQ_CATEGORY_RE_ORDER:
      return {
        ...state,
        reOrderResponse: action.payload,
      };

    // QUESTION
    case faqConstant.GET_FAQ_QUESTION_ADD:
      return {
        ...state,
        addQuestionStatus: action.payload,
      };
    case faqConstant.GET_FAQ_QUESTION_DELETE:
      return {
        ...state,
        deleteQuestionResponse: action.payload,
      };
    case faqConstant.GET_FAQ_QUESTION_STATUS_CHANGE:
      return {
        ...state,
        changeStatusQuestionResponse: action.payload,
      };

    case faqConstant.GET_FAQ_QUESTION_EDIT:
      return {
        ...state,
        editQuestionStatus: action.payload,
      };

    case faqConstant.GET_FAQ_QUESTION_RESET:
      return {
        ...state,
        addQuestionStatus: null,
        editQuestionStatus: null,
        addResponse: {},
        editResponse: {},
      };

    case faqConstant.GET_FAQ_QUESTION_SHOW:
      return {
        ...state,
        showQuestionResponse: action.payload,
      };

    case faqConstant.GET_FAQ_QUESTION_RE_ORDER:
      return {
        ...state,
        reOrderQuestionResponse: action.payload,
      };

    default:
      return state;
  }
};

export default faqReducer;
