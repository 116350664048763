import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import IconTooltips from "../../../../../Components/tooltips/IconsTooltip";
import { TrainingCourseProfileContext } from "../../../../../Services/api/toutorials/TrainingCourseProfileContext";
import TrainingCourseSyllabusContentRowLesson from "../TrainingCourseSyllabusContentRowLesson/TrainingCourseSyllabusContentRowLesson";

function TrainingCourseSyllabusContentRow(props) {
  require("./TrainingCourseSyllabusContentRow.css");
  const { t } = useTranslation();
  const history = useHistory();
  const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);
  useEffect(() => {
    for (let i = 0; i < props.chapter.sub_chapters.length; i++) {
      props.chapter.sub_chapters[i].number = i + 1;
    }
  }, [props.chapter.sub_chapters]);
  return (
    <>
      <div className="training-course-syllabus-section-container-row">
        <div className="training-course-syllabus-section-container-row-header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span style={{ fontSize: 20, color: "black" }}>
              {t("chapter")} {props.chapter.number} :
            </span>
            <span
              style={{
                fontSize: 20,
                color: "black",
                padding: "0px 5px 0px 5px",
              }}
            >
              {"      "} {props.chapter.name}
            </span>
            <button
              className={"btn btn-sm"}
              style={{
                width: 25,
                height: 25,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#fff",
                backgroundColor: "#28a745",
                borderColor: "#28a745",
              }}
              onClick={() => {
                history.push({
                  pathname: `/course/${props.courseId}/discussion-board/` + props.chapter.id,
                  state: { userType: props.userType },
                });
              }}
            >
              <IconTooltips
                title={t("trainer.course.lesson.iconDiscussion")}
                content={
                  <i
                    className={"fas rotate-icon fa-users-class training-course-lesson-icons"}
                    style={{
                      fontSize: 10,
                    }}
                  ></i>
                }
              />
            </button>
            {props.chapter.quiz_list.length > 0 ? (
              <button
                className={"btn btn-sm"}
                style={{
                  width: 25,
                  height: 25,
                  padding: "0px 5px 0px 5px",
                  margin: "0px 5px 0px 5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#fff",
                  backgroundColor: "#28a745",
                  borderColor: "#28a745",
                }}
                onClick={() => {
                  history.push({
                    pathname: `/trainees/course/${props.courseId}/chapter/${props.chapter.id}/exams`,
                    state: {
                      quizes: props.chapter.quiz_list,
                    },
                  });
                }}
              >
                <IconTooltips
                  title={t("trainer.course.lesson.exams")}
                  content={
                    <i
                      className={"fas rotate-icon fa-chalkboard training-course-lesson-icons"}
                      style={{
                        fontSize: 10,
                      }}
                    ></i>
                  }
                />
              </button>
            ) : null}
          </div>
        </div>
        {props.chapter.sub_chapters.map((item, index) => {
          return <TrainingCourseSyllabusContentRowLesson key={index} subChapter={item} />;
        })}
      </div>
    </>
  );
}

export default TrainingCourseSyllabusContentRow;
