export const specialtiesDetailsConstants = {
  // GET SPECIALTY
  GET_SPECIALTY_REQUEST: "GET_SPECIALTY_REQUEST",
  GET_SPECIALTY_SUCCESS: "GET_SPECIALTY_SUCCESS",
  GET_SPECIALTY_REJECTED: "GET_SPECIALTY_REJECTED",

  // UPDATE SPECIALTY STATUS
  UPDATE_SPECIALTY_STATUS_REQUEST: "UPDATE_SPECIALTY_STATUS_REQUEST",
  UPDATE_SPECIALTY_STATUS_SUCCESS: "UPDATE_SPECIALTY_STATUS_SUCCESS",
  UPDATE_SPECIALTY_STATUS_REJECTED: "UPDATE_SPECIALTY_STATUS_REJECTED",
};
