import { fundConstant } from "../../Constants/fund/fund.constant";

const initialState = {
  loading: false,

  get_fund: {},
};

const fundReducer = (state = initialState, action) => {
  switch (action.type) {
    case fundConstant.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case fundConstant.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    case fundConstant.GET_FUND:
      return {
        ...state,
        get_fund: action.payload,
      };

    default:
      return state;
  }
};

export default fundReducer;
