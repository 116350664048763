import { Pagination } from "@mui/material";
import { Formik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { exportCourseTrainersList } from "../../../../Services/api/CourseTrainerList";
import { useDispatch, useSelector } from "react-redux";
import {
  assignContentDeveloper,
  assignTrainers,
  getTrainers,
} from "../../../../Services/api/toutorials/assignToCourseProvider";
import HomeIcon from "../../../../assets/icons/home.svg";
import exit from "../../../../assets/icons/remove-red.svg";
import Breadcrumb from "../../../Shared/Components/Breadcrumb/Breadcrumb";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../../Shared/Components/MainBoxHead/MainBoxHead";
import { BasicModal } from "../../../Shared/Components/Modal/BasicModal";
import SelectWithSearch from "../../../Shared/Components/SelectWithSearch/SelectWithSearch";
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import DepartmentsTrainersTable from "./DepartmentsTrainersTable";
import { filterActions } from "./DepartmentsTrainersTableActions";
import classes from "./departmentsTrainers.module.css";
import { fetchSystemUsersList } from "../../../../Redux/Actions/systemUsers/systemUsersList";

const DepartmentsTrainers = ({
  isProgram,
  programCourses,
  isGroupAdmin,
  isManager,
}) => {
  const { t, i18n } = useTranslation();
  const { id, courseId } = useParams();
  const addTrainersModal = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const roles = JSON.parse(localStorage.getItem("roles"));
  const role = location?.state?.role
    ? location?.state?.role
    : roles.includes("admin")
    ? "admin"
    : roles.includes("manager")
    ? "manager"
    : "partner";
  const { systemUsersList } = useSelector(
    (state) => state.systemUsersListReducer
  );
  const [showAddTrainersModal, setShowAddTrainersModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [trainers, setTrainers] = useState([]);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [otherTrainers, setOtherTrainers] = useState([]);
  const [otherManagers, setOtherManagers] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [ContentCreators, setContentCreators] = useState([]);

  const getSystemUsersListHandler = () => {
    const filter = { role: 8 };
    dispatch(
      fetchSystemUsersList({
        page: currentPage,
        perPage: 10,
        filter,
      })
    );
  };

  const getTrainersApi = () => {
    getTrainers(programCourses ? courseId : id)
      .then((res) => {
        if (res.status && res.status === 200 && res.data.status) {
          setCourseName(res.data.response.course.name);
          setContentCreators(res.data.response.course.content_creators);
          const dues = res.data.response.course.dues;
          let mainTrainer = res.data.response.course.trainer;
          mainTrainer.dues = dues.filter(
            (due) => Number(due.trainer_id) === mainTrainer.id
          )[0];
          let subTrainers = res.data.response.course.sub_trainers.filter(
            (trainer) => trainer.id !== res.data.response.course.trainer.id
          );
          subTrainers = subTrainers.map((trainer) => {
            trainer.dues = dues.filter(
              (due) => Number(due.trainer_id) === trainer.id
            )[0];
            return trainer;
          });
          const otherTrainers = res.data.response.other_trainers.filter(
            (trainer) => {
              return (
                trainer.roles.filter((role) => role.name === "trainer").length >
                0
              );
            }
          );
          const otherManagers = res.data.response.other_trainers.filter(
            (trainer) => {
              // console.log(trainer.roles);
              return (
                trainer.roles.filter(
                  (role) => role.name === "manager" || role.name === "admin"
                ).length > 0 && trainer.id !== mainTrainer.id
              );
            }
          );
          // setTrainers([mainTrainer, ...subTrainers]);
          setTrainers(
            programCourses ? [...subTrainers] : [mainTrainer, ...subTrainers]
          );
          setOtherTrainers([...subTrainers, ...otherTrainers]);
          setOtherManagers([mainTrainer, ...otherManagers]);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  };

  useEffect(() => {
    getTrainersApi();
    getSystemUsersListHandler();
  }, []);

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "content_development",
      page: t("sidebar.label.content_development"),
      pagePath: "#",
    },
    {
      id: `${isProgram || programCourses ? "programs" : "courses"}`,
      page: t(
        `sidebar.label.${isProgram || programCourses ? "program" : "courses"}`
      ),
      pagePath: `${
        isGroupAdmin ? "/group-admin" : isManager ? "/manager" : "/admin"
      }/${isProgram || programCourses ? "program/list" : "courses"}`,
    },
    ...(programCourses
      ? [
          {
            id: "courses",
            page: t("sidebar.label.courses"),
            pagePath: `${
              isGroupAdmin ? "/group-admin" : isManager ? "/manager" : "/admin"
            }/program/${id}/courses`,
          },
        ]
      : []),
    {
      id: `${isProgram ? "program-name" : "course-name"}`,
      page: courseName,
      pagePath: "#",
    },
    {
      id: "departments-trainers",
      page: t(`courseTrainers`),
      active: true,
    },
  ];

  const handleOpenAddTrainersModal = () => {
    setShowAddTrainersModal(true);
    addTrainersModal.current.showModal();
  };

  const handleCloseAddTrainersModal = () => {
    setShowAddTrainersModal(false);
    addTrainersModal.current.dismissModal();
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      await exportCourseTrainersList(type, programCourses ? courseId : id);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  return (
    <>
      <BasicModal ref={addTrainersModal}>
        {showAddTrainersModal && (
          <MainBox
            className={`p-0 rounded ${classes["add-trainer-modal-container"]}`}
          >
            <div className={classes["add-trainer-modal"]}>
              <div className={classes["add-trainer-modal-header"]}>
                <h2 className={classes["add-trainer-modal-title"]}>
                  {activeTab === 2
                    ? t("department_content_developers")
                    : t(
                        `department_trainers.${
                          isProgram ? "program_trainers" : "course_trainers"
                        }`
                      )}
                </h2>
                <img
                  src={exit}
                  alt="exit"
                  className={classes["add-trainer-modal-exit"]}
                  onClick={handleCloseAddTrainersModal}
                />
              </div>
              <div className={classes["add-trainer-modal-body"]}>
                <Formik
                  initialValues={{
                    manager_trainer: trainers[0]?.id,
                    ...(activeTab === 2
                      ? { content_creators: [] }
                      : { trainers: trainers?.slice(1) }),
                  }}
                  onSubmit={async (values, { setSubmitting }) => {
                    try {
                      setSubmitting(true);
                      setLoading(true);
                      let data = {
                        manager_trainer: values.manager_trainer,
                      };
                      if (activeTab === 2) {
                        data.content_creators = values.content_creators.map(
                          (e) => e.id
                        );
                      } else {
                        if (!isProgram) {
                          data.trainers = values.trainers.map((e) => e.id);
                        }

                        if (programCourses) {
                          data.manager_trainer = 1;
                        }
                      }
                      let response =
                        activeTab === 2
                          ? await assignContentDeveloper(
                              programCourses ? courseId : id,
                              data
                            )
                          : await assignTrainers(
                              programCourses ? courseId : id,
                              data
                            );
                      if (response.status === 200 && response.data.status) {
                        toast.success(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {response.data.msg}
                          </span>
                        );
                        getTrainersApi();
                        setLoading(false);
                        setSubmitting(false);
                      } else {
                        toast.error(
                          <span style={{ fontSize: 13, fontWeight: "bold" }}>
                            {t("failed_fetching")}
                          </span>
                        );
                      }
                    } catch (err) {
                      toast.error(
                        <span style={{ fontSize: 13, fontWeight: "bold" }}>
                          {err.response.data &&
                            err.response.data.errors.manager_trainer[0]}
                        </span>
                      );
                      setLoading(false);
                      setSubmitting(false);
                    }
                    handleCloseAddTrainersModal();
                  }}
                  validateOnChange={true}
                  validate={(values) => {
                    const errors = {};

                    if (!isProgram && values?.trainers?.length === 0) {
                      errors.trainers = t("crud.errors.required");
                    }

                    if (
                      !values.manager_trainer &&
                      !programCourses &&
                      activeTab !== 2
                    ) {
                      errors.manager_trainer = t("crud.errors.required");
                    }

                    return errors;
                  }}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className={classes["add-trainer-modal-form"]}
                    >
                      {!programCourses && activeTab !== 2 && (
                        <div
                          className={classes["width-100"]}
                          style={{ marginBottom: 20 }}
                        >
                          <SelectWithSearch
                            label={t(
                              "assginCourse.label.assign_manager_trainer"
                            )}
                            labelColor="#036c77"
                            subLabel={t(
                              "assginCourse.label.assign_manager_trainer_sub"
                            )}
                            name="manager_trainer"
                            onchange={(value) => {
                              setFieldValue("manager_trainer", value.value);
                            }}
                            options={otherManagers.map((trainer) => {
                              return {
                                id: trainer.id,
                                value: trainer.id,
                                label: `${trainer.name} (${trainer.email})`,
                              };
                            })}
                            value={{
                              id: values.manager_trainer,
                              value: values.manager_trainer,
                              label: values.manager_trainer
                                ? `${
                                    otherManagers.find(
                                      (trainer) =>
                                        trainer?.id === values?.manager_trainer
                                    )?.name
                                  } (${
                                    otherManagers.find(
                                      (trainer) =>
                                        trainer?.id === values?.manager_trainer
                                    )?.email
                                  })`
                                : "",
                            }}
                            error={errors.manager_trainer}
                          />
                        </div>
                      )}
                      {!isProgram && (
                        <div className={classes["width-100"]}>
                          <div
                            className={classes["add-trainer-modal-form-label"]}
                          >
                            {activeTab === 2
                              ? t("department_content_developers")
                              : t("assginCourse.label.assign_trainer")}
                          </div>
                          <Select
                            isMulti
                            name="user"
                            defaultValue={
                              activeTab === 2
                                ? ContentCreators
                                : trainers.slice(1)
                            }
                            options={
                              activeTab == 2 ? systemUsersList : otherTrainers
                            }
                            getOptionLabel={(trainer) =>
                              `${trainer.name} (${trainer.email})`
                            }
                            getOptionValue={(trainer) => trainer.id}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(newTrainers) => {
                              if (activeTab === 2) {
                                setFieldValue("content_creators", newTrainers);
                              } else {
                                setFieldValue("trainers", newTrainers);
                              }
                            }}
                            placeholder={t(
                              "assginCourse.placeholders.trainer_placeholders"
                            )}
                          />

                          <p className={classes["form-input-error-space"]}>
                            {errors.trainers ? errors.trainers : null}
                          </p>
                        </div>
                      )}
                      <div
                        className={classes["add-trainer-modal__form-actions"]}
                      >
                        <CustomButton
                          value={t("assginCourse.label.release")}
                          colors="#036c77"
                          type="submit"
                          loading={loading}
                          disable={loading}
                          classes={
                            classes["add-trainer-modal__form-actions__btn"]
                          }
                        />
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </MainBox>
        )}
      </BasicModal>
      {isExportLoading || loading ? (
        <SkeletonCardOverlay skeletonWidth="100" />
      ) : (
        <div className={classes["departments-trainers"]}>
          <div className="container-fluid">
            <div className={classes["departments-trainers__container"]}>
              <div className="row">
                <div className="col-12">
                  <Breadcrumb list={breadcrumbList} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <MainBox>
                    <MainBoxHead
                      title={t(`courseTrainers`)}
                      actions={filterActions({
                        [role !== "partner" && role !== "trainer"
                          ? "addAction"
                          : ""]: () => {
                          handleOpenAddTrainersModal();
                        },
                        exportPdf: () => {
                          exportHandler("pdf");
                        },
                        exportExcel: () => {
                          exportHandler("xlsx");
                        },
                      })}
                    />
                    <DepartmentsTrainersTable
                      trainers={trainers}
                      ContentCreators={ContentCreators}
                      loading={loading}
                      setActiveTab={setActiveTab}
                      activeTab={activeTab}
                      setLoading={setLoading}
                      getTrainersApi={getTrainersApi}
                      programCourses={programCourses}
                      role={role}
                      isProgram={isProgram}
                    />
                    {/* <Pagination
                      count={1}
                      showFirstButton
                      showLastButton
                      variant="outlined"
                      shape="rounded"
                      className="main-pagination"
                      page={currentPage}
                      onChange={(e, page) => {
                        setCurrentPage(page);
                      }}
                    /> */}
                  </MainBox>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DepartmentsTrainers;
