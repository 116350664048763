import React, { useState, useEffect } from "react";

import { Pagination } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
// import { fetchTaxsList } from "../../../../Redux/Actions/Tax/taxList.action";
import HomeIcon from "../../../assets/icons/home.svg";
import { useLocation } from "react-router-dom";
import SkeletonCardOverlay from "../../Shared/Components/Spinner/SkeletonCardOverlay";
import Breadcrumb from "../../Shared/Components/Breadcrumb/Breadcrumb";
import MainBox from "../../Shared/Components/MainBox/MainBox";
import MainBoxHead from "../../Shared/Components/MainBoxHead/MainBoxHead";
import classes from "./detailedReport.module.css";
import { filterActionsTax } from "./DetialedReportActions";
import { exportTaxList } from "../../../Services/api/TaxList";
import { getAllCourses } from "../../../Services/api/CoursesList";
import DetialedReportFilter from "./DetialedReportFilter";
import DetialedReportTable from "./DetialedReportTable";

function DetialedReport({ isAdmin, isAll }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isResetApplied, setIsResetApplied] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({
    name: "",
    type: "",
    views_sort: "",
    include_view_per: "",
    include_achivement_per: "",
    ...(isAll ? { main_chapter: "", subChapter: "", lesson: "" } : {}),
  });

  //   const { taxsListMeta, taxsListLoading } = useSelector(
  //     (state) => state.taxsListReducer
  //   );

  const breadcrumbList = [
    {
      id: "home",
      page: <img src={HomeIcon} alt="" />,
      pagePath: "/",
    },
    {
      id: "courseOutline",
      page: t("Courseoutline"),
      pagePath: `/admin/course/${location.state.courseID}`,
    },
    {
      id: `content_development_tax`,
      page: "lesson title", ///or course title
      pagePath: "#",
      active: true,
    },
  ];
  const showFilterHandler = () => {
    setShowFilter((prev) => !prev);
  };
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const resetHandler = (name) => {
    setFilterData({ ...filterData, [name]: "" });
  };

  const resetFilterHandler = () => {
    setFilterData({
      name: "",
      type: "",
      views_sort: "",
      include_view_per: "",
      include_achivement_per: "",
      ...(isAll ? { main_chapter: "", subChapter: "", lesson: "" } : {}),
    });
    setIsResetApplied(true);
  };

  //   const getTaxListHandler = () => {
  //     const filter = getFilterData();

  //     dispatch(
  //       fetchTaxsList({
  //         page: currentPage,
  //         perPage: 10,
  //         filter,
  //       })
  //     );
  //   };

  const getFilterData = () => {
    const filter = {};
    filterData.name && (filter.name = filterData.name);
    filterData.main_chapter && (filter.main_chapter = filterData.main_chapter);
    filterData.subChapter && (filter.subChapter = filterData.subChapter);
    filterData.lesson && (filter.lesson = filterData.lesson);
    filterData.type && (filter.type = filterData.type);
    filterData.views_sort && (filter.views_sort = filterData.views_sort);
    filterData.include_view_per &&
      (filter.include_view_per = filterData.include_view_per);
    filterData.include_achivement_per &&
      (filter.include_achivement_per = filterData.include_achivement_per);

    return filter;
  };

  const exportHandler = async (type) => {
    setIsExportLoading(true);
    try {
      const filter = getFilterData();
      await exportTaxList(filter, type);
      setIsExportLoading(false);
    } catch (e) {
      setIsExportLoading(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_fetching")}
        </span>
      );
    }
  };

  useEffect(() => {
    // getTaxListHandler();
    if (isResetApplied) {
      setIsResetApplied(false);
    }
  }, [currentPage, isResetApplied]);

  const statistics = [
    {
      label: t("total_number_student"),
      value: 150,
      color: "#046C77",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="add_1_"
            data-name="add (1)"
            d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
    {
      label: t("total_number_items"),
      value: 20,
      color: "#046C77dd",
      icon: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 67.86 67.86">
          <path
            id="add_1_"
            data-name="add (1)"
            d="M33.93,0A33.93,33.93,0,1,0,67.86,33.93,33.93,33.93,0,0,0,33.93,0Zm0,62.205A28.275,28.275,0,1,1,62.205,33.93,28.275,28.275,0,0,1,33.93,62.205ZM48.068,33.93a2.828,2.828,0,0,1-2.828,2.828H36.758V45.24a2.828,2.828,0,1,1-5.655,0V36.758H22.62a2.828,2.828,0,0,1,0-5.655H31.1V22.62a2.828,2.828,0,1,1,5.655,0V31.1H45.24A2.828,2.828,0,0,1,48.068,33.93Z"
            fill="#fff"
          />
        </svg>
      ),
    },
  ];

  return (
    <div className={classes["tax-list"]}>
      {isExportLoading && <SkeletonCardOverlay skeletonWidth="100" />}
      {/* {taxsListLoading && <SkeletonCardOverlay skeletonWidth="100" />} */}
      <div className="container-fluid">
        <div className={classes["tax-list__container"]}>
          <div className="row">
            <div className="col-12">
              <Breadcrumb list={breadcrumbList} />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <div className="tw-text-teal-700 tw-text-2xl">
                  {t("general.statistics")}
                </div>
                <div className={classes["statics_container"]}>
                  {statistics.map((item) => (
                    <div
                      className="tw-flex tw-items-center tw-space-s-4 tw-p-4 tw-text-white tw-rounded"
                      style={{ backgroundColor: item.color }}
                    >
                      <div className="tw-h-10 tw-w-10">{item.icon}</div>
                      <div>
                        <div className="tw-text-2xl">{item.value}</div>
                        <div className="tw-text-sm">{item.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </MainBox>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <MainBox>
                <MainBoxHead
                  title={t("detail_report")}
                  actions={filterActionsTax({
                    showFilter: showFilterHandler,
                    exportPdf: () => {
                      exportHandler("pdf");
                    },
                    exportExcel: () => {
                      exportHandler("xlsx");
                    },
                  })}
                />
                {showFilter && (
                  <DetialedReportFilter
                    filterData={filterData}
                    changeHandler={changeHandler}
                    resetHandler={resetHandler}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    // filterHandler={getTaxListHandler}
                    resetFilterHandler={resetFilterHandler}
                    isAll={isAll}
                  />
                )}

                <DetialedReportTable isAll={isAll} />
                <Pagination
                  count={0} //taxsListMeta?.totalPages ||
                  showFirstButton
                  showLastButton
                  variant="outlined"
                  shape="rounded"
                  className="main-pagination"
                  onChange={(e, page) => {
                    setCurrentPage(page);
                  }}
                />
              </MainBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetialedReport;
