import { Box, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classes from "./manageSpecialtiesAction.module.css";

const ManageSpecialtiesAction = ({ submitAction, isSubmitting, text }) => {
	const { t, i18n } = useTranslation();

	return (
		<div className={`${classes["manage-specialties__form__field-actions"]} col-12`}>
			<button
				className={classes["manage-specialties__form__field-actions-submit"]}
				type="submit"
				disabled={isSubmitting}
				onClick={submitAction}
			>
				{isSubmitting ? (
					<Box sx={{ display: "flex" }}>
						<CircularProgress color="white" size={30} />
					</Box>
				) : (
					text
				)}
			</button>
		</div>
	);
};

export default ManageSpecialtiesAction;
