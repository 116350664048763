import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router";
import i18n from "../../../../i18n/i18n";
import {Lang} from "../../../../utils";
import logo from "../../../../assets/image/logo.png";
import CourseCardSpinner from "../CourseCardSpinner";
import {TrainerProfileContext} from "../../../../Services/api/toutorials/trainerProfileContext";
import edit from "../../../../assets/image/edit(1).png";
import courserun from "../../../../assets/image/run.png";

function ListComplet() {
  require("./listComplet.css");
  const { t } = useTranslation();
  const history = useHistory();

  const trainerProfileContext = useContext(TrainerProfileContext);

  return (
    <>
      <div className="courses_card_grid">
        {trainerProfileContext.isContentLoaded ? (
          trainerProfileContext.trainersProfile &&
          trainerProfileContext.trainersProfile.coursesFinished
            .slice(0, 3)
            .map((item) => {
              return (
                <div className="courses_card_courses" key={item.id}>
                  <div
                    className="courses_card_first_courses"
                    style={{
                      backgroundImage: "url(" + item.img + ")",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push("trainer/course/" + item.id);
                    }}
                  >
                    <div className="courses_name_course">
                      <div className="create_courses_name">
                        {/* <div className="create_couses_edit">
                          <img src={logo} alt="" className="logo_hover_width" /> */}
                          <div className="create_couses_edit">
                                <img
                                  src={logo}
                                  alt=""
                                  className="logo_hover_width"
                                />
                                <div className=""
                                   onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(
                                      "trainer/course/edit/" + item.id
                                    );
                                    // history.push("trainer/course/edit/" + item.id)
                                  }}
                                >
                                  <img
                                    src={courserun}
                                    alt=""
                                    className="menu_icon"
                                  />
                                  {t("course-run")}
                                </div>

                                {/* <div
                                  className=""
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    history.push(
                                      "trainer/course/edit/" + item.id
                                    );
                                    // history.push("trainer/course/edit/" + item.id)
                                  }}
                                >
                                  <img
                                    src={edit}
                                    alt=""
                                    className="menu_icon"
                                  />
                                  {t("modification")}
                                </div> */}
                              </div>
                        {/* </div> */}
                        <div className="">{item.name}</div>
                      </div>
                      {/* <NavLink to="trainer/course/add" className="profile_inside_btn"> */}
                      <div className="create_couses_edit">
                        <div className="">
                          {t("department")} :{" "}
                          {i18n.language === Lang.AR
                            ? item.department_name_ar
                            : item.department_name_en}
                        </div>
                        <div
                          className=""
                          onClick={(e) => {
                            // history.goBack();
                            e.stopPropagation();
                            history.push("trainer/course/edit/" + item.id);
                            // history.push("trainer/course/edit/" + item.id)
                          }}
                        >
                          <img src={edit} alt="" className="menu_icon" />
                          {t("modification")}
                        </div>
                      </div>
                      {/* </NavLink> */}
                    </div>
                  </div>
                </div>
              );
            })
        ) : (
          <>
            <CourseCardSpinner />
            <CourseCardSpinner />
            <CourseCardSpinner />
          </>
        )}
      </div>
    </>
  );
}

export default ListComplet;
