import React from "react";
import classes from "./loadingSpinner.module.css";
const LoadingSpinner = ({ small = false }) => {
  return (
    <div className={classes["loading_spinner"]}>
      <div className={small ? classes["lds-ring_small"] + " " + classes["lds-ring"] : classes["lds-ring"]}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default LoadingSpinner;
