import { offerConstant } from "../../Constants/offer/offer.constant";

const initialState = {
  loading: false,

  get_offers: {},
  post_offers_status: {},
  delete_offer: {},
  get_offer_by_id: {},
};

const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case offerConstant.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case offerConstant.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    case offerConstant.RESET:
      return {
        ...state,
        get_offers: {},
        post_offers_status: {},
        delete_offer: {},
      };

    case offerConstant.GET_OFFER:
      return {
        ...state,
        get_offers: action.payload,
      };

    case offerConstant.POST_OFFER_STATUS:
      return {
        ...state,
        post_offers_status: action.payload,
      };

    case offerConstant.DELETE_OFFER:
      return {
        ...state,
        delete_offer: action.payload,
      };

    case offerConstant.GET_OFFER_BY_ID:
      return {
        ...state,
        get_offer_by_id: action.payload,
      };

    default:
      return state;
  }
};

export default offerReducer;
