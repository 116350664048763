import React from "react";
import {useTranslation} from "react-i18next";

function DropDownProposedAnswers(props) {
    const {t} = useTranslation();

    const deleteItem = (index) => {
        let activateQuestionCorrectnessCheckbox = false;
        if (props.answers[index].is_correct) {
            activateQuestionCorrectnessCheckbox = true
        }

        let newAnswers = props.answers
        newAnswers.splice(index,1);
        props.onDelete(newAnswers, activateQuestionCorrectnessCheckbox);
    }

    return(
        <>
            <table className="table table-bordered">
                <thead>
                <tr>
                    <th scope="col">{t("trainer.quiz.crud.question_option")}</th>
                    <th scope="col">{t("trainer.quiz.crud.right_answer")}</th>
                    <th scope="col">{t("crud.list.actions")}</th>
                </tr>
                </thead>
                <tbody>
                    {
                        props.answers.map((answer, index) => {
                            return (
                                <tr key={index}>
                                    <td>{answer.name}</td>
                                    <td>{answer.is_correct ? t("yes") : t("no")}</td>
                                    <td>
                                        <i
                                            onClick={() => {
                                                deleteItem(index);
                                            }}
                                            title={"Delete"}
                                            style={{
                                                fontSize: 20,
                                                color:'red',

                                            }} className="fal fa-trash"></i>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}

export default DropDownProposedAnswers;
