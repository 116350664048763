import { useTranslation } from "react-i18next";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";

import { useEffect, useState } from "react";
const PaymentDueFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
  courses,
  programs,
  changeHandlerNationalty,
}) => {
  const { t, i18n } = useTranslation();

  const parentTypeOptions = [
    {
      label: t("coupon.label.course"),
      id: 1,
      value: 1,
    },
    {
      label: t("coupon.label.program"),
      id: 2,
      value: 2,
    },
  ];

  const filterFields = [
    {
      id: "trainee_name",
      component: "input",
      label: t("giftCourse.label.trainee_name"),
      type: "text",
      placeholder: t("giftCourse.label.trainee_name"),
      name: "trainee_name",
      value: filterData.trainee_name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "course_id",
      label: t("coupon.label.select_program"),
      component: "select",
      placeholder: t("coupon.label.select_program"),
      initialValue: t("coupon.label.select_program"),
      name: "course_id",
      value: filterData.course_id,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: programs?.length
        ? programs?.map((program) => ({
            id: program.id,
            value: program.id,
            label: program.name,
          }))
        : [],
    },

    {
      id: "start_date",
      label: t("general.search_start_date"),
      component: "datePicker",
      placeholder: t("general.search_start_date"),
      name: "start_date",
      value: filterData.start_date,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
    },
    {
      id: "end_date",
      label: t("general.search_end_date"),
      component: "datePicker",
      placeholder: t("general.search_end_date"),
      name: "end_date",
      value: filterData.end_date,
      classes: "col-12 col-md-6 col-xl-2",
      onchange: changeHandler,
    },

    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default PaymentDueFilter;
