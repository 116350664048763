import React, {useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

function LessonActivities(props) {
    const [clickedActivity, setClickedActivity] = useState([]);

    const onActivityClickCallback = (item) => {
        setClickedActivity(item);
        props.onActivityClickCallback(item);
    }

    require("./LessonActivities.css")
    return (
        <>
            {
                props.activities.map(item => {
                    return (
                        <div key={item.id} onClick={() => onActivityClickCallback(item)}>
                            <Accordion>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    sx={{
                                        backgroundColor: "rgba(3, 22, 23, 0.8)",
                                    }}
                                >
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        maxWidth:'100%',
                                        wordBreak:"break-word"
                                    }}>
                                        <Typography sx={{color: "#FFFFFF"}}>{item.name}</Typography>
                                    </div>
                                </AccordionSummary>
                            </Accordion>
                        </div>
                    );
                })
            }
        </>
    )
}

export default LessonActivities;
