import React from "react";

function ContentExplanation(props) {
  return (
    <div className="content_explanation">
      <div className="content_explanation_text">
        شرح كيفية إضافة محتوي الدورة التدريبية
      </div>
      <div className="embed-responsive embed-responsive-16by9 content_explanation_video">
        <iframe
          title="Video"
          className="embed-responsive-item"
          src="https://www.youtube.com/embed"
        />
      </div>
    </div>
  );
}

export default ContentExplanation;
