export const educationalGoalsConstants = {
    // GET EDUCATIONAL GOALS
    GET_EDUCATIONAL_GOALS_REQUEST: "GET_EDUCATIONAL_GOALS_REQUEST",
    GET_EDUCATIONAL_GOALS_SUCCESS: "GET_EDUCATIONAL_GOALS_SUCCESS",
    GET_EDUCATIONAL_GOALS_REJECTED: "GET_EDUCATIONAL_GOALS_REJECTED",

    // ADD EDUCATIONAL OBJECTIVE
    ADD_EDUCATIONAL_OBJECTIVE_REQUEST: "ADD_EDUCATIONAL_OBJECTIVE_REQUEST",
    ADD_EDUCATIONAL_OBJECTIVE_SUCCESS: "ADD_EDUCATIONAL_OBJECTIVE_SUCCESS",
    ADD_EDUCATIONAL_OBJECTIVE_REJECTED: "ADD_EDUCATIONAL_OBJECTIVE_REJECTED",

    // EDIT EDUCATIONAL OBJECTIVE
    EDIT_EDUCATIONAL_OBJECTIVE_REQUEST: "EDIT_EDUCATIONAL_OBJECTIVE_REQUEST",
    EDIT_EDUCATIONAL_OBJECTIVE_SUCCESS: "EDIT_EDUCATIONAL_OBJECTIVE_SUCCESS",
    EDIT_EDUCATIONAL_OBJECTIVE_REJECTED: "EDIT_EDUCATIONAL_OBJECTIVE_REJECTED",

    // DELETE EDUCATIONAL OBJECTIVE
    DELETE_EDUCATIONAL_OBJECTIVE_REQUEST: "DELETE_EDUCATIONAL_OBJECTIVE_REQUEST",
    DELETE_EDUCATIONAL_OBJECTIVE_SUCCESS: "DELETE_EDUCATIONAL_OBJECTIVE_SUCCESS",
    DELETE_EDUCATIONAL_OBJECTIVE_REJECTED: "DELETE_EDUCATIONAL_OBJECTIVE_REJECTED",

    // UPDATE EDUCATIONAL OBJECTIVE STATUS
    UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_REQUEST: 'UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_REQUEST',
    UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_SUCCESS: 'UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_SUCCESS',
    UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_REJECTED: 'UPDATE_EDUCATIONAL_OBJECTIVE_STATUS_REJECTED',
}