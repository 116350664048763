import {createContext, useState} from "react";

export const AccountantContext = createContext(null);

export default function AccountantProvider(props) {
  const [sponsor, setSponsor] = useState(null);
  const [values, setValues] = useState({});
  const [sponsorValue, setSponsorValue] = useState("");
  const [page, setPage] = useState();
  const [formSponsors, setFormSponsors] = useState({});
  return (
    <AccountantContext.Provider
      value={{
        sponsor,
        setSponsor,
        values,
        setValues,
        sponsorValue,
        setSponsorValue,
        page,
        setPage,
        formSponsors,
        setFormSponsors,
      }}
    >
      {props.children}
    </AccountantContext.Provider>
  );
}
