import React from "react";
import {TrainerProfileProvider} from "../../Services/api/toutorials/trainerProfileContext";
import TrainerProfile from "./TrainerProfile";

function Profile(props) {

  return (
    <TrainerProfileProvider>
      <TrainerProfile/>
    </TrainerProfileProvider>
  );
}

export default Profile;
