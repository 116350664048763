const LessonTypes = {
  h5p: "h5p",
  xapi: "xapi",
  scorm: "scorm",
  html: "html",
  hotspot: "hotspot",
  image: "image",
  video: "video",
  text: "text",
  file: "file",
  audio: "audio",
  pdf: "pdf",
};

export default LessonTypes;
