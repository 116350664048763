import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";
import { exportApi } from "../Essential";

export const getAllObjective = (id) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_all_objective, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

export const deleteObjective = (id) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .delete(URLBuilder(env.delete_objective, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err.response);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

export const addObjective = (Payload) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.add_objective, Payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

export const EditObjective = (id, Payload) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .put(URLBuilder(env.edit_objective, { id: id }), Payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};
export const saveObjectLesson = (Payload) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.lesson_objective, Payload)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};
export const getlessonObjectives = (id) => {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.get_lesson_objective, { id: id }))
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
};

export function ObjectivesExport(id, params, export_type) {
  return exportApi(
    export_type,
    URLBuilder(env.export_objectives, { id: id }),
    params,
    "Educational goals"
  );
}
