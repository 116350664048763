import React from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

function Degrees() {
  require("./degrees.css");
  const { t } = useTranslation();

  return (
    <>
      <div className="head_box">
        <div className="head_lable">{t("degrees")}</div>
      </div>
      <div className="degree_grid">
        <div className="degree_card">
          <div className="degree_card_first"></div>
          <div className="degree_card_second">
            <div className="courses_card_category">{t("category")} </div>
            <div className="courses_card_profile">
              <div className="courses_card_icon"></div>
              <div className="courses_card_name">hakem</div>
            </div>
          </div>
          <div className="courses_card_second_std">
            <div className="courses_card_point">
              <div className=""></div>
              <div className=""> 44% </div>
            </div>
            <div className=""> {t("point")} 59/136 </div>
          </div>
          <div className="courses_card_button">
            <NavLink to="/show-certificate" className="courses_card_button_in">
              <button className="courses_card_button_in">
                {" "}
                {t("veiwcer")}{" "}
              </button>
            </NavLink>
          </div>
        </div>
        <div className="degree_card">
          <div className="degree_card_first"></div>
          <div className="degree_card_second">
            <div className="courses_card_category">{t("category")} </div>
            <div className="courses_card_profile">
              <div className="courses_card_icon"></div>
              <div className="courses_card_name">hakem</div>
            </div>
          </div>
          <div className="courses_card_second_std">
            <div className="courses_card_point">
              <div className=""></div>
              <div className=""> 44% </div>
            </div>
            <div className=""> {t("point")} 59/136 </div>
          </div>
          <div className="courses_card_button">
            <button className="courses_card_button_in"> {t("veiwcer")} </button>
          </div>
        </div>
        <div className="degree_card">
          <div className="degree_card_first"></div>
          <div className="degree_card_second">
            <div className="courses_card_category">{t("category")} </div>
            <div className="courses_card_profile">
              <div className="courses_card_icon"></div>
              <div className="courses_card_name">hakem</div>
            </div>
          </div>
          <div className="courses_card_second_std">
            <div className="courses_card_point">
              <div className=""></div>
              <div className=""> 44% </div>
            </div>
            <div className=""> {t("point")} 59/136 </div>
          </div>
          <div className="courses_card_button">
            <button className="courses_card_button_in"> {t("veiwcer")} </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Degrees;
