import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import Checkbox from "../../Modules/Shared/Components/Checkbox/Checkbox";
import CustomButton from "../../Modules/Shared/Components/CustomButton/CustomButton";
import Input from "../../Modules/Shared/Components/Input/Input";
import { LessonContentContext } from "../../Providers/LessonContentProvider";
import DeleteIcon from "../../assets/icons/delete.svg";

function AddVideoLinkToLessonModal(props) {
  require("./AddVideoLinkToLessonModal.css");

  const { id } = useParams();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isLocalSubmitting, setIsLocalSubmitting] = useState(false);

  const lessonContentContext = useContext(LessonContentContext);

  const { t } = useTranslation();

  return (
    <>
      <div
        className="add_video_link_modal"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div className="add_video_link_modal_header">
          <h2 className="add_video_link_modal_title">{props.title}</h2>
          <div className="add_video_link_modal_close" onClick={props.dismissModal}>
            <img src={DeleteIcon} alt="close" />
          </div>
        </div>
        <Formik
          initialValues={{
            title: props?.data?.title || "",
            link: props?.data?.link || "",
            // include_achivement_per: props?.data?.include_achivement_per || "",
            include_view_per: props?.data?.include_view_per ? props?.data?.include_view_per : false,
            type: "video",
            lesson_id: id,
            content_type: "content",
            ...(props.data ? { _method: "put" } : {}),
          }}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            setIsLocalSubmitting(true);
            let formData = new FormData();
            Object.keys(values).forEach((field) => {
              if (values[field] != "") {
                formData.append(field, values[field]);
              }
            });

            formData.append("include_view_per", values.include_view_per ? 1 : 0);
            formData.append("parent_id", null);

            try {
              props.data
                ? await lessonContentContext.editContentActivity(formData, props?.data?.id)
                : await lessonContentContext.addContent(formData);
              await lessonContentContext.fetchLessonContent(id);
              props.dismissModal();
            } catch (error) {
              setIsLocalSubmitting(false);
              setSubmitting(false);
              return;
            }
          }}
          validate={(values) => {
            setHasSubmitted(true);
            const errors = {};
            if (!values.title) {
              errors.title = t("crud.errors.required");
            }

            if (!values.link) {
              errors.link = t("crud.errors.required");
            }

            return errors;
          }}
          validateOnChange={hasSubmitted}
        >
          {({ setFieldValue, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} className="add_video_link_modal_form">
              <div className="add_video_link_modal_form_fields">
                <Input
                  type="text"
                  placeholder={t("trainer.course.lesson.crud.attachmentLabel")}
                  label={t("trainer.course.lesson.attachmentLabel")}
                  name="title"
                  onChange={handleChange}
                  value={values.title}
                  error={errors.title}
                  required
                />
                <div style={{ display: "flex", gap: "20px" }}>
                  {/* <Checkbox
                    name="include_achivement_per"
                    label={t("include_achivement_per")}
                    onChange={(event) => {
                      setFieldValue(
                        "include_achivement_per",
                        event.target.checked ? true : false
                      );
                    }}
                    checked={values.include_achivement_per === true}
                    error={errors.include_achivement_per}
                  /> */}
                  <Checkbox
                    name="include_view_per"
                    label={t("include_view_per")}
                    onChange={(event) => {
                      setFieldValue("include_view_per", event.target.checked ? 1 : 0);
                    }}
                    checked={values.include_view_per === true || values.include_view_per === 1}
                    error={errors.include_view_per}
                    value={values.include_view_per === true || values.include_view_per === 1}
                  />
                </div>
                <Input
                  type="text"
                  placeholder={t("trainer.course.lesson.crud.videoLink")}
                  label={t("trainer.course.lesson.videoLink")}
                  name="link"
                  onChange={handleChange}
                  value={values.link}
                  error={errors.link}
                  required
                />
              </div>
              <div className="add_video_link_actions">
                <CustomButton
                  type="submit"
                  value={t("sendNow")}
                  loading={isLocalSubmitting}
                  disable={isSubmitting}
                  colors="#036c77"
                />
              </div>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default AddVideoLinkToLessonModal;
