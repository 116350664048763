function GenericNotFound() {
    return(
        <>
            <div style={{
                height:'100%',
                width:"100%",
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                position:"absolute",
                top:0,
                zIndex: -1
            }}>
                <h1>404</h1>
            </div>
        </>
    )
}

export default GenericNotFound;