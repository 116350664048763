import { useContext, useEffect } from "react";
import { TrainingCourseProfileContext } from "../../../../Services/api/toutorials/TrainingCourseProfileContext";
import TrainingCourseProfileDiscussions from "../Discussions/TrainingCourseDiscussions/TrainingCourseDiscussions";
import TrainingCourseSyllabusContainer from "../Syllabus/TrainingCourseSyllabusContainer/TrainingCourseSyllabusContainer";

function TrainingCourseProfileBody(props) {
  require("./TrainingCourseProfileBody.css");
  const trainingCourseProfileContext = useContext(TrainingCourseProfileContext);

  useEffect(() => {
    trainingCourseProfileContext.setCourseID(props.courseID);
  }, []);

  return (
    <>
      {trainingCourseProfileContext.tab == 2 ? (
        <TrainingCourseProfileDiscussions />
      ) : trainingCourseProfileContext.tab == 0 ? (
        <TrainingCourseSyllabusContainer />
      ) : null}
    </>
  );
}

export default TrainingCourseProfileBody;
