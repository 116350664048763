import env from "../Environment";
import connector from "../Handler";

export function uploadFile(file) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.upload_file, file)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function uploadMultipleFiles(files) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.upload_multiple_files, files)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

export function deleteFile(filePath) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .post(env.delete_file, filePath)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
