import React from "react";
import { useTranslation } from "react-i18next";
import CourseContentSlider from "./CourseContentLessonsSlider";
import { LessonActions } from "./LessonActions";
import classes from "./trainingCourseContentLessons.module.css";

export default function CourseContentLessonsDetails({
  lessonDetails,
  lesson,
  assignmentAction,
  surveyAction,
  subChapterTitle,
  refresh,
  courseId,
  chapterId,
  supChapterId,
  lessonId,
  isClosed,
}) {
  const { t } = useTranslation();

  const slides = [
    {
      id: "content",
      title: t("trainer.course.lesson.content"),
      data: lessonDetails.lessonContent,
    },
    {
      id: "activities",
      title: t("trainer.course.lesson.activities"),
      data: lessonDetails.lessonActivities,
    },
    {
      id: "zoom",
      title: t("trainer.course.lesson.sessions"),
      data: lessonDetails.zoomClassRoomList,
    },
    {
      id: "discussions",
      title: t("trainer.course.lesson.discussions"),
      data: lessonDetails.lessonDiscussions,
    },
  ];

  return (
    <>
      <div className={classes["course-details"]}>
        <div>
          <p className={classes["course-details-title-new"]}>{subChapterTitle}</p>
        </div>
        <LessonActions
          lessonQuiz={lessonDetails.lessonQuiz}
          assignmentAction={isClosed ? null : assignmentAction}
          surveyAction={surveyAction}
          lesson={lesson}
        />
        {slides.map(
          (slide) =>
            slide.data &&
            slide.data.length > 0 && (
              <div className={classes["course-details-item"]} key={slide.id}>
                <div className={classes["course-details-title"]}>
                  <h3>{slide.title}</h3>
                </div>
                <CourseContentSlider
                  slide={slide}
                  refresh={refresh}
                  courseId={courseId}
                  chapterId={chapterId}
                  supChapterId={supChapterId}
                  lessonId={lessonId}
                  isClosed={isClosed}
                />
              </div>
            )
        )}
      </div>
    </>
  );
}
