import {forwardRef, useContext, useEffect, useImperativeHandle} from "react";
import {ModalProviderContext} from "../../../../Providers/ModalProvider";

export const BasicModal = forwardRef((props, ref) => {
  const modalContext = useContext(ModalProviderContext);

  useEffect(() => {
    if (modalContext.showModal) {
      modalContext.setChildren(props.children);
    }
  }, [props.children]);

  useImperativeHandle(ref, () => ({
    showModal() {
      modalContext.setChildren(props.children);
      modalContext.setShowModal(true);
    },
    dismissModal() {
      modalContext.setChildren(<></>);
      modalContext.setShowModal(false);
    },
  }));

  useEffect(() => {
    if (!modalContext.showModal) {
      modalContext.setChildren(<></>);
    }
  }, [modalContext.showModal]);

  useEffect(() => {
    return () => {
      modalContext.setChildren(<></>);
    };
  }, []);

  return <></>;
});
