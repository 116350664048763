import { Container } from "@mui/material";

const ContainerBox = ({ children, width, sx }) => {
	return (
		<Container maxWidth={width || "lg"} sx={{ ...sx }}>
			{children}
		</Container>
	);
};

export default ContainerBox;
