import {useTranslation} from "react-i18next";
import React from "react";
import {useHistory} from "react-router";
import {toast} from "react-toastify";
import {exportReport} from "../../../../Services/api/report/ReportProvider";
import IconTooltips from '../../../../Components/tooltips/IconsTooltip'
import Xls from './../../../../assets/image/rating/xls.png'
import i18n from "../../../../i18n/i18n";
import {Lang} from "../../../../utils";
import clearEmptyProp from "../../../../Helpers/ClearEmptyProp";

function ReportModal(props) {
  require("./reportsTrainee.css");
  const { t } = useTranslation();
  const history = useHistory()
    return (
        <>
            <div
                className={"tajah-basic-modal-md"}

                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className={"tajah-basic-modal-header"}>
                  <div style={{display:'flex',flexDirection:'row'}}>
                    <h4>{props.title}</h4>
                    {props.body.length == 0 ? null : (
                    <button className={"btn btn-sm btn-primary color-button-excel"} style={{
                        marginRight: document.body.dir == 'rtl' ? 10 : 0,
                        marginLeft: document.body.dir == 'ltr' ? 10 : 0,
                        backgroundColor: "transparent",
                        borderColor: "transparent",
                        borderWidth:0,
                        textAlign:'left'
                      }} onClick={async () => {
                        try{
                          await exportReport('traineeQuizzes',clearEmptyProp({user_id: props.userId, course_id: props.courseId}));
                        } catch (e) {
                          toast.error(
                              <span style={{fontSize: 13, fontWeight: "bold"}}>
                                                    {t("failed_fetching")}
                                                </span>
                          );
                        }
                      }}>
                      <IconTooltips
                      title={t('modal.coupon.hover.titleDownloadExcel')}
                      content={<img src={Xls} style={{width:25}}/>}
                      />
                      </button>
                  )}
                  </div>
                    <div>
                        <i
                            className="fas fa-times tajah-modal-dismiss"
                            onClick={() => {
                                props.reportsModalRef.current.dismissModal();
                            }}
                        ></i>
                    </div>
                </div>
                <div className={"tajah-basic-modal-body"} style={{height:props.body.length == 0 ? null : 500,overflow:'scroll',display:'flex',justifyContent:'center',alignItems:'center'}}>
                {props.type == "degrees" ? (
                <div className="table-responsive" style={{
                    width: '90%',
                    margin:'auto'
                  }}>
                  {props.body.length == 0 ? (
                    <p style={{textAlign:'center'}}>{t('report.label.degree.notFound')}</p>
                  ):(
                    <table className="table table-bordered">
                      <thead>
                      <tr style={{textAlign:'center'}}>
                        <th scope="col">#</th>
                        <th scope="col">{t("report.label.trainee")}</th>
                        <th scope="col"> {t("report.label.course")}</th>
                        <th scope="col">{t("report.label.exam")}</th>
                        <th scope="col">{t("report.label.totalDegree")}</th>
                        <th scope="col">{t("report.label.finalDegree")}</th>
                      </tr>
                      </thead>
                      <tbody>
                      {props.body.map((degree,index) => {
                        return (
                            <tr style={{textAlign:'center'}} key={degree.id}>
                              <td scope="row">{index+1}</td>
                              <td>{i18n.language === Lang.AR ? degree.name_ar : degree.name_en}</td>
                              <td>{degree.course}</td>
                              <td>{degree.title}</td>
                              <td>{degree.total_grade}</td>
                              <td>{degree.final_grade}</td>
                            </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  )}
                  {props.pagination}
                  </div>
                  ):props.type == "financial" ? (
                    <div className="table-responsive" style={{
                        width: '90%',
                        margin:'auto'
                      }}>
                      {props.body.length == 0 ? (
                        <p style={{textAlign:'center'}}>{t('survey.trainee.notFound')}</p>
                      ):(
                        <table className="table table-bordered">
                          <thead>
                          <tr style={{textAlign:'center'}}>
                            <th scope="col">#</th>
                            <th scope="col">{t("survey.trainee.name")}</th>
                          </tr>
                          </thead>
                          <tbody>
                          {props.body.map((degree,index) => {
                            return (
                                <tr style={{textAlign:'center'}} key={degree.id}>
                                  <td scope="row">{index+1}</td>
                                  <td>{degree}</td>
                                </tr>
                            );
                          })}
                          </tbody>
                        </table>
                      )}
                      {props.pagination}
                      </div>
                  ):null}
                </div>
            </div>
        </>
    );
}

export default ReportModal;
