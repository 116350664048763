import { peymentRequestsAdminConstants } from "../../Constants/peymentRequestAdmin/peymentRequestAdmin.constants";

const initialState = {
  peymentRequests: [],
  peymentRequestsMeta: {},
  peymentRequestsLoading: false,
  peymentRequestsError: null,
  peymentRequestsSuccess: false,

  acceptPeymentRequestStatusLoading: false,
  acceptPeymentRequestStatusError: null,
  acceptPeymentRequestStatusSuccess: false,

  rejectPeymentRequestLoading: false,
  rejectPeymentRequestError: null,
  rejectPeymentRequestSuccess: false,
};

const peymentRequestsAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case peymentRequestsAdminConstants.GET_PEYMENTREQUESTSADMIN_LIST_REQUEST:
      return {
        ...state,
        peymentRequestsLoading: true,
      };
    case peymentRequestsAdminConstants.GET_PEYMENTREQUESTSADMIN_LIST_SUCCESS:
      return {
        ...state,
        peymentRequestsLoading: false,
        peymentRequestsSuccess: true,
        peymentRequests: action.payload.peymentRequests.data, //////
        peymentRequestsMeta: action.payload.peymentRequests.meta, /////
      };
    case peymentRequestsAdminConstants.GET_PEYMENTREQUESTSADMIN_LIST_REJECTED:
      return {
        ...state,
        peymentRequestsLoading: false,
        peymentRequestsError: action.payload,
        peymentRequestsSuccess: false,
      };

    // REJECT PEYMENTREQUEST
    case peymentRequestsAdminConstants.REJECT_PEYMENTREQUESTADMIN_REQUEST:
      return {
        ...state,
        rejectPeymentRequestLoading: true,
      };

    case peymentRequestsAdminConstants.REJECT_PEYMENTREQUESTADMIN_SUCCESS:
      return {
        ...state,
        rejectPeymentRequestLoading: false,
        rejectPeymentRequestSuccess: true,
        rejectPeymentRequestError: null,

        peymentRequests: state.peymentRequests.filter(
          (peymentRequest) => peymentRequest.id !== action.payload.id
        ),
      };

    case peymentRequestsAdminConstants.REJECT_PEYMENTREQUESTADMIN_REJECTED:
      return {
        ...state,
        rejectPeymentRequestLoading: false,
        rejectPeymentRequestError: action.payload,
        rejectPeymentRequestSuccess: false,
      };

    // ACCEPT PEYMENTREQUEST STATUS
    case peymentRequestsAdminConstants.ACCEPT_PEYMENTREQUESTADMIN_STATUS_REQUEST:
      return {
        ...state,
        acceptPeymentRequestStatusLoading: true,
      };

    case peymentRequestsAdminConstants.ACCEPT_PEYMENTREQUESTADMIN_STATUS_SUCCESS:
      return {
        ...state,
        acceptPeymentRequestStatusLoading: false,
        acceptPeymentRequestStatusSuccess: true,
        acceptPeymentRequestStatusError: null,

        peymentRequests: state.peymentRequests.filter(
          (peymentRequest) => peymentRequest.id !== action.payload.id
        ),
      };

    case peymentRequestsAdminConstants.ACCEPT_PEYMENTREQUESTADMIN_STATUS_REJECTED:
      return {
        ...state,
        acceptPeymentRequestStatusLoading: false,
        acceptPeymentRequestStatusError: action.payload,
        acceptPeymentRequestStatusSuccess: false,
      };

    default:
      return state;
  }
};

export default peymentRequestsAdminReducer;
