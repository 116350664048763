import React from "react";
import {AboutUsProvider} from "../../../Services/api/toutorials/aboutusContext";
import AboutUsEdit from "./AboutEdit";

function AboutUs(props) {
  return (
    <AboutUsProvider>
      <AboutUsEdit />
    </AboutUsProvider>
  );
}

export default AboutUs;
