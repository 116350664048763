import i18n from "../../../../i18n/i18n";
import {useTranslation} from "react-i18next";
import logo from "../../../../assets/image/logo.png";
import icon from "../../../../assets/image/Icon.png";

function CoursesStd() {
  require("./coursesStd.css");
  document.body.dir = i18n.dir();
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="head_box">
            <div className="head_lable">{t("courseteach")}</div>

            <div className="head_view">
              <div className="">{t("ViewAll")}</div>
              <div className="arrow_seeall">
                <img src={icon} alt="" className="arrow_icon" />
              </div>
            </div>
          </div>

          <div className="courses_card_grid">
            <div className="courses_card_std">
              <div className="courses_card_first_std">
                <div className="courses_name">
                  <div className="logo_hover">
                    <img src={logo} alt="" className="logo_hover_width" />
                  </div>
                  <div className="">{t("basicCourse")}</div>
                </div>
              </div>
              <div className="courses_card_second_std">
                <div className="courses_card_point">
                  <div className=""></div>
                  <div className=""> 44% </div>
                </div>
                <div className=""> {t("point")} 59/136</div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "44%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="courses_card_button">
                <button className="courses_card_button_in">
                  {" "}
                  {t("contstudy")}
                </button>
              </div>
            </div>

            <div className="courses_card_std">
              <div className="courses_card_first_std">
                <div className="courses_name">
                  <div className="logo_hover">
                    <img src={logo} alt="" className="logo_hover_width" />
                  </div>
                  <div className="">{t("basicCourse")}</div>
                </div>
              </div>
              <div className="courses_card_second_std">
                <div className="courses_card_point">
                  <div className=""></div>
                  <div className=""> 44% </div>
                </div>
                <div className=""> {t("point")} 59/136 </div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "44%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="courses_card_button">
                <button className="courses_card_button_in">
                  {" "}
                  {t("contstudy")}
                </button>
              </div>
            </div>

            <div className="courses_card_std">
              <div className="courses_card_first_std">
                <div className="courses_name">
                  <div className="logo_hover">
                    <img src={logo} alt="" className="logo_hover_width" />
                  </div>
                  <div className="">{t("basicCourse")}</div>
                </div>
              </div>
              <div className="courses_card_second_std">
                <div className="courses_card_point">
                  <div className=""></div>
                  <div className=""> 44% </div>
                </div>
                <div className=""> {t("point")} 59/136</div>
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "44%" }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="courses_card_button">
                <button className="courses_card_button_in">
                  {" "}
                  {t("contstudy")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CoursesStd;
