// import CreateCourse from "../../Modules/Admin/Courses/Add/AddCourse";
import EvaluationList from "../../Modules/Admin/Evaluation/EvaluationList/EvaluationList";
import FAQListEndUser from "../../Modules/Admin/FAQ/FAQListEndUser";
import Trainees from "../../Modules/Admin/survey/answers/Trainees";
import Statistics from "../../Modules/Admin/survey/statistics/Statistics";
import List from "../../Modules/Trainer/Survey/List";
import FAQ from "../../Modules/FAQ_s/FAQ";
import FAQUser from "../../Modules/FAQ_s/FAQ_User";
import ListGiveGift from "../../Modules/ListGiveGift/ListGiveGift";
import ListMyGift from "../../Modules/ListMyGift/ListMyGift";
import AnswersReply from "../../Modules/LiveChat/answersReply/AnswersReply";
import UpdateAnswersReply from "../../Modules/LiveChat/answersReply/UpdateAnswersReply";
import Schedule from "../../Modules/LiveChat/schedule/Schedule";
import SupportUsers from "../../Modules/LiveChat/supportUsers/SupportUsers";
import UpdateSupportUsers from "../../Modules/LiveChat/supportUsers/UpdateSupportUsers";
import WelcomeMessage from "../../Modules/LiveChat/welcomeMessage/WelcomeMessage";
import LessonCanvas from "../../Modules/Trainer/Course/NewLesson/Canvas/Canvas";
import GiftCourseWrapper from "../../Modules/trainees/GiftCourse/GiftCourseWrapper/GiftCourseWrapper";
import PublishRequestsAdd from "../../Modules/home/PublishRequests/PublishRequestsAdd";

import {
  AssessmentAdd,
  AssessmentAnswers,
  AssessmentEdit,
  AssessmentParticipants,
  AssessmentPreview,
  AssessmentStatistics,
  AssessmentsList,
  AssessmentsStatement,
} from "../../Modules/Admin/Assessments";
import CoursePrivateChat from "../../Modules/Trainer/Course/CoursePrivateChat/CoursePrivateChat";
import CourseGroups from "../../Modules/Trainer/Course/CourseGroups/CourseGroups";
import LessonActivityView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/LessonActivityView/LessonActivityView";
import {
  AddSurvey,
  CoursesList,
  ListSurvey,
  PartnerCoursesList,
  Payments,
} from "../admins";
import {
  Activity,
  AddEduGoals,
  AddQuestionToQuestionBankWrapper,
  EditEduGoals,
  EduGoals,
  ListQuestionBank,
  SurveyAnswers,
  SurveyParticipants,
  SurveyResponse,
} from "../trainers";
import {
  AboutMe,
  AboutUs,
  AcrivitiesMark,
  AddCertificateTemplates,
  AddCourse,
  AddDiscBoard,
  AddFaq,
  AddPeymentRequest,
  AddSession,
  AllCoursesCurrent,
  AllCoursesStd,
  AllProfile,
  AllProgram,
  AllPrograms,
  AllcoursesFinished,
  Certificate,
  CertificateProgram,
  CertificateTemplates,
  ContactUs,
  CourseOutline,
  CourseProfile,
  CourseQuestion,
  CreateNewCourse,
  DepartmentsTrainees,
  DepartmentsTrainers,
  DiscBoard,
  EditCertificateTemplates,
  EditCourse,
  EditFaq,
  EditProfile,
  EditSession,
  Exam,
  ExamAnswers,
  ExamResult,
  FaqList,
  ForgotPassword,
  GeneralSurveys,
  GenericNotFound,
  Home,
  HomeCourses,
  Invoices,
  LessonContent,
  LessonView,
  ListExams,
  MainAuth,
  ModifyTheCourse,
  PaymentDone,
  PaymentProgram,
  PaymentsDue,
  PersonalInformation,
  PeymentsRequest,
  PrivacyAndPolicy,
  PrivateProfile,
  Program,
  PublicCoursesList,
  ReceivedGift,
  RefundRequests,
  RegisterProgram,
  ResetPassword,
  SendGift,
  ShowCertificate,
  SurveyQuestions,
  SurveyView,
  TablePayment,
  TermsAndConditions,
  Trainee,
  TraineeCertificates,
  TrainerDetails,
  Trainers,
  TrainersCreateCourses,
  TrainingCourseContent,
  TrainingCourseContentLessons,
  TrainingCoursePayment,
  TrainingCourseProfile,
  TrainingCoursesDetailsProvider,
  TrainingPayFirst,
  TrainingPayFourth,
  TrainingPaySecond,
  TrainingPayThird,
  Wallet,
  ZoomInvoice,
  chat,
  verifyEmailPage,
} from "./";
import Groups from "../../Modules/Groups/Groups";
import CourseContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContent";
import UnitContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/UnitContentView/UnitContentView";
import LessonContentView from "../../Modules/TrainingCourses/TrainingCourseDetails/TrainingCourseContentLessons/LessonContentView/LessonContentView";
import ActivitiesStatement from "../../Modules/Admin/ActivitesMarks/Statement";
import LearningAnalysis from "../../Modules/Admin/Analysis/LearningAnalysis";

const is_loggedIn = localStorage.getItem("token") ? true : false;
let routesMain;
if (is_loggedIn) {
  routesMain = [
    {
      component: Activity,
      path: "/list/activity/:id",
      exact: true,
    },
    {
      component: () => <Activity preview={true} />,
      path: "/preview/list/activity/:id",
      exact: true,
    },

    {
      component: PublishRequestsAdd,
      path: "/publish-request",
      exact: true,
    },
    // {
    //   component: () => <DiscBoard role="manager" />,
    //   path: "/manager/course/:courseId/discussion-board/:id",
    //   exact: true,
    // },
    {
      component: () => <DiscBoard role="partner" />,
      path: "/partner/course/:courseId/discussion-board/:id",
      exact: true,
    },
    // {
    //   component: () => <AcrivitiesMark role="manager" />,
    //   path: "/manager/course/lesson/:id/:activityId/activities-grades",
    //   exact: true,
    // },
    {
      component: () => <AcrivitiesMark role="partner" />,
      path: "/partner/course/lesson/:id/:activityId/activities-grades",
      exact: true,
    },
    // {
    //   component: () => <Activity isManager={true} />,
    //   path: "/manager/list/activity/:id",
    //   exact: true,
    // },
    {
      component: () => <Activity isPartner={true} />,
      path: "/partner/list/activity/:id",
      exact: true,
    },
    {
      component: LessonCanvas,
      path: "/course/lesson/:id",
      exact: true,
    },
    {
      component: () => <LessonCanvas isPartner={true} />,
      path: "/partner/course/lesson/:id",
      exact: true,
    },
    // {
    //   component: () => <LessonCanvas isManager={true} isTrainer={true} />,
    //   path: "/manager/course/lesson/:id",
    //   exact: true,
    // },
    { component: Home, path: "/", exact: true },
    {
      component: MainAuth,
      path: "/register",
      exact: true,
    },
    // {
    //     component: localStorage.getItem('token') ? PrivateProfile : MainAuth,
    //     path: "/login",
    //     exact: true,
    // },
    {
      component: MainAuth,
      path: "/email-verification",
      exact: true,
    },
    {
      component: Trainers,
      path: "/trainers",
      exact: true,
    },
    {
      component: ContactUs,
      path: "/contactUs",
      exact: true,
    },
    {
      component: AboutUs,
      path: "/about-us",
      exact: true,
    },
    {
      component: TermsAndConditions,
      path: "/terms-and-conditions",
      exact: true,
    },
    {
      component: PrivacyAndPolicy,
      path: "/privacy-and-policy",
      exact: true,
    },
    {
      component: TrainingCoursesDetailsProvider,
      path: "/training-courses/:id/details",
      exact: true,
    },
    {
      component: () => <TrainingCoursesDetailsProvider isProgram={true} />,
      path: "/training-programs/:id/details",
      exact: true,
    },
    {
      component: TrainingCoursePayment,
      path: "/course/:id/payment",
      exact: true,
    },
    {
      component: () => <TrainingCoursePayment isProgram={true} />,
      path: "/program/:id/payment",
      exact: true,
    },
    {
      component: () => (
        <TrainingCoursePayment isProgram={true} isGiftPayment={true} />
      ),
      path: "/program/:id/gift-payment/:giftId",
      exact: true,
    },
    {
      component: () => <TrainingCoursePayment isGift={true} />,
      path: "/course/:id/gift",
      exact: true,
    },
    {
      component: () => <TrainingCoursePayment isProgram={true} isGift={true} />,
      path: "/program/:id/gift",
      exact: true,
    },
    {
      component: CourseContentView,
      path: "/training-courses/:id/content",
      exact: true,
    },
    {
      component: () => <CourseContentView courseFromProgram={true} />,
      path: "/training-courses/:programId/course/:id/content",
      exact: true,
    },
    {
      component: UnitContentView,
      path: "/training-courses/:courseId/chapter/:chapterId/unit/:unitId",
      exact: true,
    },
    {
      component: LessonContentView,
      path: "/training-courses/:courseId/chapter/:chapterId/unit/:unitId/lesson/:lessonId",
      exact: true,
    },
    {
      component: TrainingCourseContentLessons,
      path: "/training-courses/:id/content/:chapterId/:supChapterId/lessons",
      exact: true,
    },
    {
      component: LessonActivityView,
      path: "/training-courses/:id/content/:chapterId/:supChapterId/lesson/:lessonId/activity/:activityId",
      exact: true,
    },
    {
      component: TrainerDetails,
      path: "/trainers/trainer-details/:id",
      exact: true,
    },
    {
      component: Trainee,
      path: "/trainee",
      exact: true,
    },
    {
      component: PrivateProfile,
      path: "/private-profile",
      exact: true,
    },
    {
      component: (props) => <LearningAnalysis {...props} role={"trainee"} />,
      path: "/private-profile/learning-analysis",
      exact: true,
    },
    {
      component: () => <DiscBoard role="partner" />,
      path: "/course/:courseId/discussion-board/:id",
      exact: true,
    },
    {
      component: () => <DiscBoard role="trainee" />,
      path: "/course/:courseId/discussion-board/:id",
      exact: true,
    },
    {
      component: () => <CertificateTemplates />,
      path: "/certificateTemplates",
      exact: true,
    },
    {
      component: () => <AddCertificateTemplates />,
      path: "/certificateTemplates/add",
      exact: true,
    },
    {
      component: () => <AddCertificateTemplates />,
      path: "/certificateTemplates/:id/add",
      exact: true,
    },
    {
      component: () => <CertificateTemplates />,
      path: "/certificateTemplates/:id",
      exact: true,
    },
    {
      component: () => <EditCertificateTemplates />,
      path: "/certificateTemplates/edit/:id",
      exact: true,
    },
    {
      component: () => <TraineeCertificates />,
      path: "/trainee/:id/certificates",
      exact: true,
    },
    {
      component: () => <CoursesList role="content_developer" status="Closed" />,
      path: "/content_developer/courses-history",
      exact: true,
    },
    {
      component: () => <CoursesList role="content_developer" status="" />,
      path: "/content_developer/courses",
      exact: true,
    },

    {
      component: () => (
        <CoursesList
          isProgram={true}
          role="content_developer"
          status="Closed"
        />
      ),
      path: "/content_developer/programs-history",
      exact: true,
    },
    {
      component: () => (
        <CoursesList isProgram={true} role="content_developer" status="" />
      ),
      path: "/content_developer/programs",
      exact: true,
    },
    {
      component: () => <PeymentsRequest role="content_developer" />,
      path: "/content_developer/payments-requests",
      exact: true,
    },
    {
      component: () => <CourseProfile role="content_developer" />,
      path: "/content_developer/course/:id",
      exact: true,
    },
    {
      component: () => <CourseContentView role={"content_developer"} />,
      path: "/content_developer/training-courses/:id/content",
      exact: true,
    },
    {
      component: () => <UnitContentView role={"content_developer"} />,
      path: "/content_developer/training-courses/:courseId/chapter/:chapterId/unit/:unitId",
      exact: true,
    },
    {
      component: () => <LessonContentView role={"content_developer"} />,
      path: "/content_developer/training-courses/:courseId/chapter/:chapterId/unit/:unitId/lesson/:lessonId",
      exact: true,
    },
    {
      component: () => <LessonActivityView role={"content_developer"} />,
      path: "/content_developer/training-courses/:id/content/:chapterId/:supChapterId/lesson/:lessonId/activity/:activityId",
      exact: true,
    },
    {
      component: () => (
        <CourseContentView
          courseFromProgram={true}
          role={"content_developer"}
        />
      ),
      path: "/content_developer/training-courses/:programId/course/:id/content",
      exact: true,
    },
    {
      component: (props) => (
        <Exam {...props} quizable={`chapter`} role={"content_developer"} />
      ),
      path: "/content_developer/course/:courseId/chapter/:quizableId/exam/:id",
      exact: true,
    },
    {
      component: (props) => <AddSurvey {...props} role={"content_developer"} />,
      path: "/content_developer/course/:targetCourseId/survey/add",
      exact: true,
    },
    {
      component: (props) => <AddSurvey {...props} role={"content_developer"} />,
      path: "/content_developer/course/:targetCourseId/lesson/:targetLessonId/survey/add",
      exact: true,
    },
    {
      component: () => (
        <AddQuestionToQuestionBankWrapper
          isTrainer
          role={"content_developer"}
        />
      ),
      path: "/content_developer/course/:courseId/question-bank/add",
      exact: true,
    },
    {
      component: () => (
        <AddQuestionToQuestionBankWrapper
          isTrainer
          editMode={true}
          role={"content_developer"}
        />
      ),
      path: "/content_developer/course/:courseId/question-bank/edit/:id",
      exact: true,
    },
    {
      component: (props) => <List {...props} role={"content_developer"} />,
      path: "/content_developer/course/:targetCourseId/survey/list",
      exact: true,
    },
    {
      component: () => <EduGoals isTrainer={true} role={"content_developer"} />,
      path: "/content_developer/course/educational-goals/:id",
      exact: true,
    },
    {
      component: () => (
        <ListQuestionBank isTrainer role={"content_developer"} />
      ),
      path: "/content_developer/course/:courseId/question-bank",
      exact: true,
    },
    {
      component: () => {
        return (
          <AssessmentsList quizable={"chapter"} role={"content_developer"} />
        );
      },
      path: "/content_developer/course/:courseId/chapter/:quizableId/assessments",
      exact: true,
    },
    {
      component: () => {
        return (
          <AssessmentsList quizable={"lesson"} role={"content_developer"} />
        );
      },
      path: "/content_developer/course/:courseId/lesson/:quizableId/assessments",
      exact: true,
    },
    {
      component: () => (
        <EvaluationList quizable={"lesson"} role={"content_developer"} />
      ),
      path: "/content_developer/course/:courseId/lesson/:quizableId/assessment/evaluation",
      exact: true,
    },
    {
      component: () => (
        <EvaluationList quizable={"chapter"} role={"content_developer"} />
      ),
      path: "/content_developer/course/:courseId/chapter/:quizableId/assessment/evaluation",
      exact: true,
    },
    {
      component: () => (
        <AssessmentAdd
          quizable={"chapter"}
          isTrainer
          role={"content_developer"}
        />
      ),
      path: "/content_developer/course/:courseId/chapter/:quizableId/assessment/add",
      exact: true,
    },
    {
      component: () => (
        <AssessmentAdd
          quizable={"lesson"}
          isTrainer
          role={"content_developer"}
        />
      ),
      path: "/content_developer/course/:courseId/lesson/:quizableId/assessment/add",
      exact: true,
    },
    {
      component: () => <AcrivitiesMark role="content_developer" />,
      path: "/content_developer/course/lesson/:id/:activityId/activities-grades",
      exact: true,
    },
    {
      component: () => <DiscBoard role="content_developer" />,
      path: "/content_developer/course/:courseId/discussion-board/:id",
      exact: true,
    },
    {
      component: (props) => (
        <LessonCanvas role={"content_developer"} isTrainer={true} />
      ),
      path: "/content_developer/course/:courseId/lesson/:id",
      exact: true,
    },
    {
      component: () => <TraineeCertificates />,
      path: "/trainee/:id/:source/certificates",
      exact: true,
    },

    // {
    //   component: () => <PeymentsRequest role="manager" />,
    //   path: "/manager/payments-requests",
    //   exact: true,
    // },
    // {
    //   component: () => <AddPeymentRequest role="manager" />,
    //   path: "/manager/payments-requests/add",
    //   exact: true,
    // },
    // {
    //   component: (props) => <LearningAnalysis {...props} role={"manager"} />,
    //   path: "/manager/learning-analysis",
    //   exact: true,
    // },

    // {
    //   component: () => <CoursesList role="manager" status="Closed" />,
    //   path: "/manager/courses-history",
    //   exact: true,
    // },
    // {
    //   component: () => <CoursesList role="manager" status="" />,
    //   path: "/manager/courses",
    //   exact: true,
    // },
    // {
    //   component: () => (
    //     <CoursesList isProgram={true} role="manager" status="Closed" />
    //   ),
    //   path: "/manager/programs-history",
    //   exact: true,
    // },
    // {
    //   component: () => (
    //     <CoursesList isProgram={true} role="manager" status="" />
    //   ),
    //   path: "/manager/programs",
    //   exact: true,
    // },
    // {
    //   component: () => <AddCourse role="manager" />,
    //   path: "/manager/course/add",
    //   exact: true,
    // },
    // {
    //   component: () => <CourseProfile role="manager" />,
    //   path: "/manager/course/:id",
    //   exact: true,
    // },
    {
      component: CourseProfile,
      path: "/partner/course/:id",
      exact: true,
    },
    {
      component: CoursePrivateChat,
      path: "/course/:id/private-chat",
    },
    {
      component: CourseGroups,
      path: "/course/:id/groups",
    },
    // {
    //   component: () => <EditCourse role="manager" />,
    //   path: "/manager/course/edit/:id",
    //   exact: true,
    // },
    // {
    //   component: (props) => <ListSurvey {...props} role={"manager"} />,
    //   path: "/manager/course/:targetCourseId/survey/list",
    //   exact: true,
    // },
    // {
    //   component: (props) => <AddSurvey {...props} role={"manager"} />,
    //   path: "/manager/course/:targetCourseId/survey/add",
    //   exact: true,
    // },
    // {
    //   component: (props) => <AddSurvey {...props} role={"manager"} />,
    //   path: "/manager/course/:targetCourseId/lesson/:targetLessonId/survey/add",
    //   exact: true,
    // },
    // {
    //   component: (props) => <AddSurvey {...props} role={"manager"} />,
    //   path: "/manager/course/:targetCourseId/survey/edit/:id",
    //   exact: true,
    // },
    // {
    //   component: (props) => <AddSurvey {...props} role={"manager"} />,
    //   path: "/manager/course/:targetCourseId/lesson/:targetLessonId/survey/edit/:id",
    //   exact: true,
    // },
    // {
    //   component: (props) => <SurveyParticipants role="manager" />,
    //   path: "/manager/survey/:id/participants",
    //   exact: true,
    // },
    // {
    //   component: (props) => <SurveyResponse />,
    //   path: "/manager/survey/:id/participants/:responseId",
    //   exact: true,
    // },
    // {
    //   component: (props) => <SurveyParticipants role="manager" />,
    //   path: "/manager/course/:targetCourseId/survey/:id/participants",
    //   exact: true,
    // },
    // {
    //   component: (props) => <SurveyResponse />,
    //   path: "/manager/course/:targetCourseId/survey/:id/participants/:responseId",
    //   exact: true,
    // },
    // {
    //   component: Trainees,
    //   path: "/manager/survey/trainee-trainer/answers",
    //   exact: true,
    // },
    // {
    //   component: () => (
    //     <AssessmentsList quizable={"chapter"} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/chapter/:quizableId/assessments",
    //   exact: true,
    // },
    // {
    //   component: () => <AssessmentsList quizable={"lesson"} role={"manager"} />,
    //   path: "/manager/course/:courseId/lesson/:quizableId/assessments",
    //   exact: true,
    // },
    {
      component: () => (
        <AssessmentsList quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessments",
      exact: true,
    },
    {
      component: () => <AssessmentsList quizable={"lesson"} role={"partner"} />,
      path: "/partner/course/:courseId/lesson/:quizableId/assessments",
      exact: true,
    },
    // {
    //   component: () => <AssessmentsStatement isAdmin={true} role={"manager"} />,
    //   path: "/manager/course/:courseId/assessments",
    //   exact: true,
    // },
    {
      component: () => <AssessmentsStatement isAdmin={true} role={"partner"} />,
      path: "/partner/course/:courseId/assessments",
      exact: true,
    },
    // {
    //   component: () => <EvaluationList quizable={"lesson"} role={"manager"} />,
    //   path: "/manager/course/:courseId/lesson/:quizableId/assessment/evaluation",
    //   exact: true,
    // },
    // {
    //   component: () => <EvaluationList quizable={"chapter"} role={"manager"} />,
    //   path: "/manager/course/:courseId/chapter/:quizableId/assessment/evaluation",
    //   exact: true,
    // },
    {
      component: () => <EvaluationList quizable={"lesson"} role={"partner"} />,
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/evaluation",
      exact: true,
    },
    {
      component: () => <EvaluationList quizable={"chapter"} role={"partner"} />,
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/evaluation",
      exact: true,
    },
    // {
    //   component: () => <AssessmentAdd quizable={"chapter"} role={"manager"} />,
    //   path: "/manager/course/:courseId/chapter/:quizableId/assessment/add",
    //   exact: true,
    // },
    // {
    //   component: () => <AssessmentAdd quizable={"lesson"} role={"manager"} />,
    //   path: "/manager/course/:courseId/lesson/:quizableId/assessment/add",
    //   exact: true,
    // },
    // {
    //   component: () => (
    //     <AssessmentParticipants quizable={"chapter"} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/chapter/:quizableId/assessment/:quizId/participants",
    //   exact: true,
    // },
    // {
    //   component: () => (
    //     <AssessmentParticipants quizable={"lesson"} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/lesson/:quizableId/assessment/:quizId/participants",
    //   exact: true,
    // },
    {
      component: () => (
        <AssessmentParticipants quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/:quizId/participants",
      exact: true,
    },
    {
      component: () => (
        <AssessmentParticipants quizable={"lesson"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/:quizId/participants",
      exact: true,
    },
    // {
    //   component: () => (
    //     <AssessmentAnswers quizable={"chapter"} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/chapter/:quizableId/assessment/:quizId/participants/answers/:id",
    //   exact: true,
    // },
    // {
    //   component: () => (
    //     <AssessmentAnswers quizable={"lesson"} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/lesson/:quizableId/assessment/:quizId/participants/answers/:id",
    //   exact: true,
    // },
    {
      component: () => (
        <AssessmentAnswers quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/:quizId/participants/answers/:id",
      exact: true,
    },
    {
      component: () => (
        <AssessmentAnswers quizable={"lesson"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/:quizId/participants/answers/:id",
      exact: true,
    },
    // {
    //   component: () => (
    //     <AssessmentStatistics quizable={"chapter"} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/chapter/:quizableId/assessment/:quizId/statistics",
    //   exact: true,
    // },
    // {
    //   component: () => (
    //     <AssessmentStatistics quizable={"lesson"} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/lesson/:quizableId/assessment/:quizId/statistics",
    //   exact: true,
    // },
    {
      component: () => (
        <AssessmentStatistics quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/:quizId/statistics",
      exact: true,
    },
    {
      component: () => (
        <AssessmentStatistics quizable={"lesson"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/:quizId/statistics",
      exact: true,
    },
    // {
    //   component: () => (
    //     <AssessmentPreview quizable={"chapter"} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/chapter/:quizableId/assessment/:quizId",
    //   exact: true,
    // },
    // {
    //   component: () => (
    //     <AssessmentPreview quizable={"lesson"} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/lesson/:quizableId/assessment/:quizId",
    //   exact: true,
    // },
    {
      component: () => (
        <AssessmentPreview quizable={"chapter"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/chapter/:quizableId/assessment/:quizId",
      exact: true,
    },
    {
      component: () => (
        <AssessmentPreview quizable={"lesson"} role={"partner"} />
      ),
      path: "/partner/course/:courseId/lesson/:quizableId/assessment/:quizId",
      exact: true,
    },
    // {
    //   component: () => <AssessmentEdit quizable={"chapter"} role={"manager"} />,
    //   path: "/manager/course/:courseId/chapter/:quizableId/assessment/edit/:id",
    //   exact: true,
    // },
    // {
    //   component: () => <AssessmentEdit quizable={"lesson"} role={"manager"} />,
    //   path: "/manager/course/:courseId/lesson/:quizableId/assessment/edit/:id",
    //   exact: true,
    // },
    // {
    //   component: (props) => <EduGoals role={"manager"} />,
    //   path: "/manager/course/educational-goals/:id",
    //   exact: true,
    // },
    {
      component: (props) => <EduGoals role={"partner"} />,
      path: "/partner/course/educational-goals/:id",
      exact: true,
    },
    // {
    //   component: (props) => <AddEduGoals role={"manager"} />,
    //   path: "/manager/course/educational-goals/add/:id",
    //   exact: true,
    // },
    // {
    //   component: (props) => <EditEduGoals role={"manager"} />,
    //   path: "/manager/course/educational-goals/edit/:id",
    //   exact: true,
    // },
    // {
    //   component: (props) => (
    //     <AddQuestionToQuestionBankWrapper role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/question-bank/add",
    //   exact: true,
    // },
    // {
    //   component: () => (
    //     <AddQuestionToQuestionBankWrapper editMode={true} role={"manager"} />
    //   ),
    //   path: "/manager/course/:courseId/question-bank/edit/:id",
    //   exact: true,
    // },
    // {
    //   component: (props) => <ListQuestionBank role={"manager"} />,
    //   path: "/manager/course/:courseId/question-bank",
    //   exact: true,
    // },
    {
      component: (props) => <ListQuestionBank role={"partner"} />,
      path: "/partner/course/:courseId/question-bank",
      exact: true,
    },
    // {
    //   component: Statistics,
    //   path: "/manager/survey/statistics/:id",
    //   exact: true,
    // },
    // {
    //   component: SurveyQuestions,
    //   path: "/manager/survey/:id/questions",
    //   exact: true,
    // },
    // {
    //   component: SurveyAnswers,
    //   path: "/manager/survey/:surveyId/questions/:questionId/answers",
    //   exact: true,
    // },
    // {
    //   component: (props) => (
    //     <div className="tw-px-4">
    //       <SurveyView role={"manager"} editor={true} preview={true} />
    //     </div>
    //   ),
    //   path: "/manager/course/:targetCourseId/survey/preview/:id",
    //   exact: true,
    // },
    // {
    //   component: () => <AddCourse isProgram={true} role="manager" />,
    //   path: "/manager/program/add",
    //   exact: true,
    // },
    // {
    //   component: () => <AddCourse programCourses={true} role="manager" />,
    //   path: "/manager/program/:id/course/add",
    //   exact: true,
    // },
    // {
    //   component: () => <EditCourse isProgram={true} role="manager" />,
    //   path: "/manager/program/edit/:id",
    //   exact: true,
    // },
    // {
    //   component: () => <EditCourse programCourses={true} role="manager" />,
    //   path: "/manager/program/:id/course/edit/:courseId",
    //   exact: true,
    // },
    {
      component: () => <PartnerCoursesList role="partner" status="Closed" />,
      path: "/partner/courses-history",
      exact: true,
    },
    {
      component: () => <PartnerCoursesList role="partner" status="Published" />,
      path: "/partner/courses",
      exact: true,
    },
    {
      component: () => (
        <PartnerCoursesList isProgram={true} role="partner" status="Closed" />
      ),
      path: "/partner/programs-history",
      exact: true,
    },
    {
      component: () => (
        <PartnerCoursesList
          isProgram={true}
          role="partner"
          status="Published"
        />
      ),
      path: "/partner/programs",
      exact: true,
    },
    {
      component: () => <CoursesList role="partner" programCourses={true} />,
      path: "/partner/program/:id/courses",
      exact: true,
    },
    {
      component: () => <PublicCoursesList type="courses" status="Published" />,
      path: "/private-profile/courses",
      exact: true,
    },
    {
      component: () => <PublicCoursesList type="courses" status="Closed" />,
      path: "/private-profile/courses-history",
      exact: true,
    },
    {
      component: () => <PublicCoursesList type="programs" status="Closed" />,
      path: "/private-profile/programs-history",
      exact: true,
    },
    {
      component: () => <PublicCoursesList type="programs" status="Published" />,
      path: "/private-profile/programs",
      exact: true,
    },
    {
      component: CourseProfile,
      path: "/course/:id",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainees isTrainer={true} />,
      path: "/course/:id/trainees",
      exact: true,
    },
    {
      component: DepartmentsTrainers,
      path: "/course/:id/trainers",
      exact: true,
    },
    {
      component: () => <Payments type="course" />,
      path: "/course/:id/trainees-payments",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainers isProgram={true} />,
      path: "/program/:id/trainers",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainees isProgram={true} />,
      path: "/program/:id/trainees",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainees programCourses={true} />,
      path: "/program/:id/course/:courseId/trainees",
      exact: true,
    },
    {
      component: () => <DepartmentsTrainers programCourses={true} />,
      path: "/program/:id/course/:courseId/trainers",
      exact: true,
    },
    {
      component: () => <Payments type="program" isProgram={true} />,
      path: "/program/:id/trainees-payments",
      exact: true,
    },
    // {
    //   component: () => <CoursesList role="manager" programCourses={true} />,
    //   path: "/manager/program/:id/courses",
    //   exact: true,
    // },
    {
      component: Certificate,
      path: "/private-profile/certificates",
      exact: true,
    },
    {
      component: RefundRequests,
      path: "/private-profile/refund-requests",
      exact: true,
    },
    {
      component: AboutMe,
      path: "/private-profile/about-me",
      exact: true,
    },
    {
      component: SendGift,
      path: "/private-profile/sent-gift",
      exact: true,
    },
    {
      component: PaymentsDue,
      path: "/private-profile/sent-gift/payments-due",
      exact: true,
    },
    {
      component: PaymentDone,
      path: "/private-profile/sent-gift/payments-done",
      exact: true,
    },
    {
      component: ReceivedGift,
      path: "/private-profile/received-gift",
      exact: true,
    },
    {
      component: Invoices,
      path: "/private-profile/invoices",
      exact: true,
    },
    {
      component: Wallet,
      path: "/private-profile/wallet",
      exact: true,
    },
    {
      component: AllCoursesStd,
      path: "/study-courses",
      exact: true,
    },
    {
      component: AllPrograms,
      path: "/study-programs",
      exact: true,
    },
    {
      component: AllCoursesCurrent,
      path: "/courses",
      exact: true,
    },
    {
      component: AllcoursesFinished,
      path: "/finished-courses",
      exact: true,
    },
    {
      component: EditProfile,
      path: "/edit-profile",
      exact: true,
    },
    {
      component: Groups,
      path: "/my-groups",
      exact: true,
    },
    {
      component: ListGiveGift,
      path: "/gift-list",
      exact: true,
    },
    {
      component: ListMyGift,
      path: "/my-gift-list",
      exact: true,
    },
    {
      component: ResetPassword,
      path: "/update-password",
      exact: true,
    },
    {
      component: AllProfile,
      path: "/all-profile",
      exact: false,
    },
    {
      component: PersonalInformation,
      path: "/personal-info",
      exact: true,
    },
    {
      component: ShowCertificate,
      path: "/show-certificate",
      exact: true,
    },
    {
      component: TrainersCreateCourses,
      path: "/trainers-create-courses",
      exact: true,
    },
    {
      component: LessonView,
      path: "/training-courses/:courseId/lesson/:id",
      exact: true,
    },
    // {
    //   component: CreateCourse,
    //   path: "/create-new-course",
    //   exact: true,
    // },
    {
      component: ModifyTheCourse,
      path: "/modify-the-course",
      exact: true,
    },
    {
      component: TrainingPayFirst,
      path: "/TrainingCourses/training-de/1",
      exact: false,
    },
    {
      component: TrainingPaySecond,
      path: "/TrainingCourses/training-de/2",
      exact: true,
    },
    {
      component: TrainingPayThird,
      path: "/TrainingCourses/training-de/3",
      exact: true,
    },
    {
      component: TrainingPayFourth,
      path: "/TrainingCourses/training-de/4",
      exact: true,
    },
    {
      component: verifyEmailPage,
      path: "/verifyEmail/:id",
      exact: true,
    },
    {
      component: CourseOutline,
      path: "/course-outline",
      exact: true,
    },
    {
      component: LessonContent,
      path: "/course-outline/add-new-lesson",
      exact: true,
    },
    {
      component: CourseQuestion,
      path: "/course-outline/add-new-lesson/add-question",
      exact: true,
    },
    {
      component: TrainingCourseProfile,
      path: "/training-courses/:id/profile",
      exact: true,
    },

    {
      component: GiftCourseWrapper,
      path: "/gift-course/:id",
      exact: true,
    },
    {
      component: AllProgram,
      path: "/all-program",
      exact: true,
    },
    {
      component: Program,
      path: "/program/:id",
      exact: true,
    },
    {
      component: RegisterProgram,
      path: "/register-program/:id",
      exact: true,
    },
    {
      component: PaymentProgram,
      path: "/program/:id/payment/:paymentId",
      exact: true,
    },
    {
      component: TablePayment,
      path: "/table-payment/:id",
      exact: true,
    },
    {
      component: GenericNotFound,
      path: "/page-not-found",
      exact: false,
    },

    {
      component: AddSession,
      path: "/zoom/add-session/:id",
      exact: true,
    },
    {
      component: ZoomInvoice,
      path: "/lesson/:lessonId/zoom/zoomInvoice/:id",
      exact: true,
    },
    {
      component: EditSession,
      path: "/zoom/edit-session/:id",
      exact: true,
    },
    {
      component: chat,
      path: "/liveChat",
      exact: true,
    },
    {
      component: WelcomeMessage,
      path: "/livechat/welcome-message",
      exact: true,
    },
    {
      component: SupportUsers,
      path: "/livechat/support-users",
      exact: true,
    },
    {
      component: UpdateSupportUsers,
      path: "/livechat/create-support-users",
      exact: true,
    },
    {
      component: UpdateSupportUsers,
      path: "/livechat/edit-support-users/:id",
      exact: true,
    },
    {
      component: AnswersReply,
      path: "/livechat/answers-reply",
      exact: true,
    },
    {
      component: UpdateAnswersReply,
      path: "/livechat/create-answers-reply",
      exact: true,
    },
    {
      component: UpdateAnswersReply,
      path: "/livechat/edit-answers-reply/:id",
      exact: true,
    },
    {
      component: Schedule,
      path: "/livechat/schedule/:id",
      exact: true,
    },

    // {
    //   component: FAQ,
    //   path: "/faq",
    //   exact: true,
    // },
    {
      component: FAQUser,
      path: "/faq/faq-list",
      exact: true,
    },
    {
      component: FAQListEndUser,
      path: "/tickets/faq-list",
      exact: true,
    },
    {
      component: EditFaq,
      path: "/faq/edit/:id",
      exact: true,
    },
    {
      component: AddFaq,
      path: "/faq/add/:id",
      exact: true,
    },
    {
      component: Exam,
      path: "/trainees/Exams/Exam/:id",
      exact: true,
    },
    {
      component: (props) => <Exam {...props} quizable={`chapter`} />,
      path: "/trainees/course/:courseId/chapter/:quizableId/exam/:id",
      exact: true,
    },
    {
      component: (props) => <ActivitiesStatement {...props} isTrainee={true} />,
      path: "/trainees/course/:courseId/activities",
      exact: true,
    },
    {
      component: (props) => <Exam {...props} quizable={`lesson`} />,
      path: "/trainees/course/:courseId/lesson/:quizableId/exam/:id",
      exact: true,
    },
    {
      component: ListExams,
      path: "/trainees/Exams/ListExams",
      exact: true,
    },
    {
      component: ExamResult,
      path: "/trainees/Exams/ExamResult/:id",
      exact: true,
    },
    {
      component: (props) => <ListExams {...props} quizable={`chapter`} />,
      path: "/trainees/course/:courseId/chapter/:quizableId/exams",
      exact: true,
    },
    {
      component: (props) => <ListExams {...props} quizable={`lesson`} />,
      path: "/trainees/course/:courseId/lesson/:quizableId/exams",
      exact: true,
    },
    {
      component: (props) => (
        <AssessmentsStatement {...props} isTrainee={true} />
      ),
      path: "/trainees/course/:courseId/exams",
      exact: true,
    },
    {
      component: (props) => (
        <AssessmentsStatement {...props} isTrainee={true} />
      ),
      path: "/trainees/course/:courseId/exams",
      exact: true,
    },
    {
      component: (props) => <ExamResult {...props} quizable={`chapter`} />,
      path: "/trainees/course/:courseId/chapter/:quizableId/exam/:quizId/result",
      exact: true,
    },
    {
      component: (props) => <ExamResult {...props} quizable={`lesson`} />,
      path: "/trainees/course/:courseId/lesson/:quizableId/exam/:quizId/result",
      exact: true,
    },
    {
      component: (props) => (
        <AssessmentAnswers {...props} quizable={`chapter`} isTrainee />
      ),
      path: "/trainees/course/:courseId/chapter/:quizableId/exam/:quizId/answers",
      exact: true,
    },
    {
      component: (props) => (
        <AssessmentAnswers {...props} quizable={`lesson`} isTrainee />
      ),
      path: "/trainees/course/:courseId/lesson/:quizableId/exam/:quizId/answers",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"trainee"} isTrainee={true} />,
      path: "/survey/:id/course/:targetCourseId",
      exact: true,
    },
    {
      component: (props) => (
        <SurveyView role={"trainee"} isTrainee={true} isPreview={true} />
      ),
      path: "/preview/survey/:id/course/:targetCourseId",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"partner"} general />,
      path: "/survey/:id/exhibition/:targetExhibitionId",
      exact: true,
    },
    {
      component: GeneralSurveys,
      path: "/general-surveys",
      exact: true,
    },
    {
      component: (props) => <SurveyQuestions {...props} isPublic />,
      path: "/survey/:id/statistics",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"partner"} general={true} />,
      path: "/general-surveys/:id",
      exact: true,
    },

    {
      component: Certificate,
      path: "/certificate/:id",
      exact: true,
    },
    {
      component: CertificateProgram,
      path: "/certificate-program/:id",
      exact: true,
    },

    {
      component: ForgotPassword,
      path: "/forgot-password",
      exact: true,
    },
    {
      component: HomeCourses,
      path: "/training-courses",
      exact: true,
    },
    {
      component: () => <HomeCourses isProgram={true} />,
      path: "/training-programs",
      exact: true,
    },
  ];
} else {
  routesMain = [
    { component: Home, path: "/", exact: true },
    {
      component: MainAuth,
      path: "/register",
      exact: true,
    },

    // {
    //     component: localStorage.getItem('token') ? PrivateProfile : MainAuth,
    //     path: "/login",
    //     exact: true,
    // },
    {
      component: UnitContentView,
      path: "/training-courses/:courseId/chapter/:unitId/content",
      exact: true,
    },
    {
      component: LessonContentView,
      path: "/training-courses/:courseId/chapter/:unitId/lesson/:lessonId/content",
      exact: true,
    },
    {
      component: MainAuth,
      path: "/email-verification",
      exact: true,
    },
    {
      component: Trainers,
      path: "/trainers",
      exact: true,
    },
    {
      component: ContactUs,
      path: "/contactUs",
      exact: true,
    },
    {
      component: AboutUs,
      path: "/about-us",
      exact: true,
    },
    {
      component: TermsAndConditions,
      path: "/terms-and-conditions",
      exact: true,
    },
    {
      component: PrivacyAndPolicy,
      path: "/privacy-and-policy",
      exact: true,
    },
    {
      component: TrainingCoursesDetailsProvider,
      path: "/training-courses/:id/details",
      exact: true,
    },
    {
      component: CourseContentView,
      path: "/training-courses/:id",
      exact: true,
    },
    {
      component: CourseContentView,
      path: "/training-courses/:id/content",
      exact: true,
    },
    {
      component: () => <CourseContentView courseFromProgram={true} />,
      path: "/training-courses/:programId/course/:id/content",
      exact: true,
    },
    {
      component: TrainingCourseContentLessons,
      path: "/training-courses/:id/content/:chapterId/:supChapterId/lessons",
      exact: true,
    },
    {
      component: LessonActivityView,
      path: "/training-courses/:id/content/:chapterId/:supChapterId/lesson/:lessonId/activity/:activityId",
      exact: true,
    },
    {
      component: TrainerDetails,
      path: "/trainers/trainer-details/:id",
      exact: true,
    },
    {
      component: Trainee,
      path: "/trainee",
      exact: true,
    },
    {
      component: AllCoursesStd,
      path: "/study-courses",
      exact: true,
    },
    {
      component: AllPrograms,
      path: "/study-programs",
      exact: true,
    },
    {
      component: AllCoursesCurrent,
      path: "/courses",
      exact: true,
    },
    {
      component: HomeCourses,
      path: "/training-courses",
      exact: true,
    },
    {
      component: () => <HomeCourses isProgram={true} />,
      path: "/training-programs",
      exact: true,
    },
    {
      component: AllcoursesFinished,
      path: "/finished-courses",
      exact: true,
    },
    {
      component: AllProfile,
      path: "/all-profile",
      exact: false,
    },
    {
      component: PersonalInformation,
      path: "/personal-info",
      exact: true,
    },
    {
      component: ShowCertificate,
      path: "/show-certificate",
      exact: true,
    },
    {
      component: TrainersCreateCourses,
      path: "/trainers-create-courses",
      exact: true,
    },
    {
      component: verifyEmailPage,
      path: "/verifyEmail/:id",
      exact: true,
    },
    {
      component: CourseOutline,
      path: "/course-outline",
      exact: true,
    },
    {
      component: LessonContent,
      path: "/course-outline/add-new-lesson",
      exact: true,
    },
    {
      component: CourseQuestion,
      path: "/course-outline/add-new-lesson/add-question",
      exact: true,
    },
    {
      component: TrainingCourseProfile,
      path: "/training-courses/:id/profile",
      exact: true,
    },

    {
      component: GiftCourseWrapper,
      path: "/gift-course/:id",
      exact: true,
    },
    {
      component: AllProgram,
      path: "/all-program",
      exact: true,
    },
    {
      component: Program,
      path: "/program/:id",
      exact: true,
    },
    {
      component: RegisterProgram,
      path: "/register-program/:id",
      exact: true,
    },
    {
      component: PaymentProgram,
      path: "/program/:id/payment/:paymentId",
      exact: true,
    },
    {
      component: TablePayment,
      path: "/table-payment/:id",
      exact: true,
    },
    {
      component: GenericNotFound,
      path: "/page-not-found",
      exact: false,
    },

    {
      component: AddSession,
      path: "/zoom/add-session/:id",
      exact: true,
    },
    {
      component: ZoomInvoice,
      path: "/lesson/:lessonId/zoom/zoomInvoice/:id",
      exact: true,
    },
    {
      component: EditSession,
      path: "/zoom/edit-session/:id",
      exact: true,
    },
    {
      component: chat,
      path: "/liveChat",
      exact: true,
    },
    {
      component: WelcomeMessage,
      path: "/livechat/welcome-message",
      exact: true,
    },
    {
      component: SupportUsers,
      path: "/livechat/support-users",
      exact: true,
    },
    {
      component: UpdateSupportUsers,
      path: "/livechat/create-support-users",
      exact: true,
    },
    {
      component: UpdateSupportUsers,
      path: "/livechat/edit-support-users/:id",
      exact: true,
    },
    {
      component: AnswersReply,
      path: "/livechat/answers-reply",
      exact: true,
    },
    {
      component: UpdateAnswersReply,
      path: "/livechat/create-answers-reply",
      exact: true,
    },
    {
      component: UpdateAnswersReply,
      path: "/livechat/edit-answers-reply/:id",
      exact: true,
    },
    {
      component: Schedule,
      path: "/livechat/schedule/:id",
      exact: true,
    },
    {
      component: FaqList,
      path: "/faq/faq-list",
      exact: true,
    },
    {
      component: EditFaq,
      path: "/faq/edit/:id",
      exact: true,
    },
    {
      component: AddFaq,
      path: "/faq/add/:id",
      exact: true,
    },
    {
      component: Exam,
      path: "/trainees/Exams/Exam/:id",
      exact: true,
    },
    {
      component: ListExams,
      path: "/trainees/Exams/ListExams",
      exact: true,
    },
    {
      component: ExamResult,
      path: "/trainees/Exams/ExamResult/:id",
      exact: true,
    },
    {
      component: ExamAnswers,
      path: "/trainees/Exams/ExamAnswers",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"trainee"} isTrainee={true} />,
      path: "/survey/:id/course/:targetCourseId",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"partner"} general />,
      path: "/survey/:id/exhibition/:targetExhibitionId",
      exact: true,
    },
    {
      component: GeneralSurveys,
      path: "/general-surveys",
      exact: true,
    },
    {
      component: (props) => <SurveyQuestions {...props} isPublic />,
      path: "/survey/:id/statistics",
      exact: true,
    },
    {
      component: (props) => <SurveyView role={"partner"} general={true} />,
      path: "/general-surveys/:id",
      exact: true,
    },

    {
      component: Certificate,
      path: "/certificate/:id",
      exact: true,
    },
    {
      component: CertificateProgram,
      path: "/certificate-program/:id",
      exact: true,
    },

    {
      component: ForgotPassword,
      path: "/forgot-password",
      exact: true,
    },
    {
      component: () => <PublishRequestsAdd role={"guest"} />,
      path: "/publish-request",
      exact: true,
    },
  ];
}
export default routesMain;
