import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router";
import React, {useContext, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import SkeletonCard from "../../Spinner/SkeletonCard";
import SkeletonCardOverlay from "../../Spinner/SkeletonCardOverlay";
import {Slider} from '@mui/material';
import {Formik} from "formik";
import levelZeroRating from "../../../../../assets/image/rating/0.png";
import levelOneRating from "../../../../../assets/image/rating/1.png";
import levelTwoRating from "../../../../../assets/image/rating/2.png";
import levelThreeRating from "../../../../../assets/image/rating/3.png";
import levelFourRating from "../../../../../assets/image/rating/4.png";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import {getTicket, ticketRate} from "../../../../../Services/api/Tickets/TicketsProvider";
import {toast} from "react-toastify";
import {AuthContext} from "../../../../../Services/api/auth/AuthContext";
import UploadAdapter from "../../../../../CKEditor/Adapter/UploadAdapter";

function RateTicket(props) {
    require('./Rate.css');
    const {t} = useTranslation();
    const history = useHistory();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [globalIsSubmitting, setGlobalIsSubmitting] = useState(false)
    const [rateInstance, setRateInstance] = useState({});
    const [canRate, setCanRate] = useState(false);

    const authContext = useContext(AuthContext);

    useEffect(() => {
        getTicket(id).then(res => {
            if ((res.status == 200 || res.status == 201 && res.data.status)) {
                if (authContext.inAdminPanel || res.data.data.canRate) {
                    setCanRate(res.data.data.canRate);
                    setRateInstance(res.data.data.rate);
                    setIsLoading(false);
                } else {
                    if(authContext.inAdminPanel){
                        history.push("/admin/tickets");
                    } else {
                        history.push("/tickets");
                    }
                }
            } else {
                toast.error(
                    <span style={{fontSize: 13, fontWeight: "bold"}}>
                        {t("failed_fetching")}
                    </span>
                );
            }
        }, error => {
            toast.error(
                <span style={{fontSize: 13, fontWeight: "bold"}}>
                    {t("failed_fetching")}
                </span>
            );
        })
    },[])

    const getImageBasedOnRating = (value) => {
        if (value == 1) {
            return levelZeroRating;
        } else if (value == 2) {
            return levelOneRating;
        } else if (value == 3) {
            return levelTwoRating;
        } else if (value == 4) {
            return levelThreeRating;
        } else if (value == 5) {
            return levelFourRating;
        }
    }

    return (
        <>
            <div className="container-fluid" style={{
                position: 'relative',
                padding: 0,
                paddingLeft: 0,
                paddingRight: 0,
                marginTop: authContext.inAdminPanel ? 0 : 37
            }}>
                <div className="row">
                    <div className="col-12">
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                        }}>
                            {
                                authContext.inAdminPanel ?
                                    <div className="admin_label">
                                        <NavLink to="/admin">
                                            {t("Main")}
                                        </NavLink>
                                        <p style={{padding: "0 5px"}}>/</p>
                                        <NavLink to="/admin/tickets">
                                            {t("admin.label.list_tickets")}
                                        </NavLink>
                                        <p style={{padding: "0 5px"}}>/</p>
                                        {t("admin.label.rate_ticket")}
                                    </div> :

                                    <div className="admin_label">
                                        <NavLink to="/">
                                            {t("Main")}
                                        </NavLink>
                                        <p style={{padding: "0 5px"}}>/</p>
                                        <NavLink to="/tickets">
                                            {t("admin.label.list_tickets")}
                                        </NavLink>
                                        <p style={{padding: "0 5px"}}>/</p>
                                        {t("admin.label.rate_ticket")}
                                    </div>
                            }

                            <div style={{
                                flex: 1,
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end'
                            }}>
                            </div>
                        </div>

                        {isLoading ?
                            <div style={{
                                height: "65vh"
                            }}>
                                <SkeletonCard/>
                            </div>
                            :
                            <div className={"tajah-form-container"}>
                                {
                                    globalIsSubmitting ?
                                        <SkeletonCardOverlay borderRadius={20}/> : null
                                }
                                <Formik
                                    initialValues={{
                                        rate: rateInstance.count <= 0 ? 3 : (rateInstance.count > 5 ? 5 : rateInstance.count),
                                        rate_dislike_text: rateInstance.rate_dislike_text,
                                        rate_like_text: rateInstance.rate_like_text
                                    }}
                                    onSubmit={async (values, {setSubmitting}) => {
                                        try{
                                            setSubmitting(true);
                                            setGlobalIsSubmitting(true);
                                            let response = await ticketRate(id,values);
                                            if (response.status == 200 && response.data.status) {
                                                if(authContext.inAdminPanel){
                                                    history.push("/admin/tickets");
                                                } else {
                                                    history.push("/tickets");
                                                }
                                            } else {
                                                toast.error(
                                                    <p style={{fontSize: 13, fontWeight: "bold"}}>
                                                        {t("failed_rating")}
                                                    </p>
                                                );
                                            }
                                            setSubmitting(false);
                                            setGlobalIsSubmitting(false);
                                        } catch (err) {
                                            setSubmitting(false);
                                            setGlobalIsSubmitting(false);
                                            toast.error(
                                                <>
                                                    <p style={{fontSize: 13, fontWeight: "bold"}}>
                                                        {t("failed_rating")}
                                                    </p>

                                                    <ul>
                                                        <li>{err.response.data.msg}</li>
                                                    </ul>
                                                </>
                                            );

                                            if(authContext.inAdminPanel){
                                                history.push("/admin/tickets");
                                            } else {
                                                history.push("/tickets");
                                            }
                                        }
                                    }}
                                    validate={(values) => {

                                    }}
                                >
                                    {({
                                          values,
                                          errors,
                                          touched,
                                          handleChange,
                                          handleBlur,
                                          handleSubmit,
                                          isSubmitting,
                                          setFieldValue,
                                          setErrors,
                                      }) => (
                                        <>
                                            <form onSubmit={handleSubmit} className="admin_add_form">
                                                <div className={"tajah-form-row"}>
                                                    <div className={"tajah-form-row-element"}>
                                                        <label className="admin_add_label">
                                                            {t("rating.title")}
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="tajah-form-flex-holder">
                                                    <div className="tajah-form-flex-holder-container">
                                                        <div className={"tajah-rating-box"}>
                                                            <div className={"tajah-form-row"}>
                                                                <div className={"tajah-rating-icon-holder"}>
                                                                    <img
                                                                        src={getImageBasedOnRating(values.rate)}
                                                                        alt=""
                                                                        className="admin_img"
                                                                        style={{
                                                                            width: 30,
                                                                            height: 30
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className={"tajah-form-row-element"}>
                                                                    <Slider
                                                                        aria-label="Temperature"
                                                                        value={values.rate}
                                                                        valueLabelDisplay="off"
                                                                        step={1}
                                                                        marks
                                                                        min={1}
                                                                        max={5}
                                                                        color={'primary'}
                                                                        onChange={(event ,number) => {
                                                                            if (number != values.rate) {
                                                                                setFieldValue('rate', number)
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tajah-form-flex-holder">
                                                    <div className="tajah-form-flex-holder-container">
                                                        <div className={"tajah-form-row"}>
                                                            <div className={"tajah-form-row-element"}>
                                                                <label className="admin_add_label">
                                                                    {t("rating.pros")}
                                                                </label>
                                                                <CKEditor
                                                                    data={values.rate_like_text}
                                                                    editor={ClassicEditor}
                                                                    onReady={(editor) => {
                                                                        editor.plugins.get("FileRepository").createUploadAdapter =
                                                                            function (loader) {
                                                                                return new UploadAdapter(loader);
                                                                            };
                                                                    }}
                                                                    config={{
                                                                        simpleUpload:
                                                                            {
                                                                                uploadUrl:"http://tajah-tebx.test/api/ckeditor/image-upload",
                                                                                withCredentials: true,

                                                                                // Headers sent along with the XMLHttpRequest to the upload server.
                                                                                headers: {
                                                                                    // 'X-CSRF-TOKEN': 'CSRF-Token',
                                                                                    Authorization: `Bearer ${localStorage.getItem('token')}`
                                                                                }
                                                                            }
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setFieldValue('rate_like_text', data);
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="tajah-form-flex-holder">
                                                    <div className="tajah-form-flex-holder-container">
                                                        <div className={"tajah-form-row"}>
                                                            <div className={"tajah-form-row-element"}>
                                                                <label className="admin_add_label">
                                                                    {t("rating.cons")}
                                                                </label>
                                                                <CKEditor
                                                                    data={values.rate_dislike_text}
                                                                    editor={ClassicEditor}
                                                                    onReady={(editor) => {
                                                                        editor.plugins.get("FileRepository").createUploadAdapter =
                                                                            function (loader) {
                                                                                return new UploadAdapter(loader);
                                                                            };
                                                                    }}
                                                                    config={{
                                                                        simpleUpload:
                                                                            {
                                                                                uploadUrl:"http://tajah-tebx.test/api/ckeditor/image-upload",
                                                                                withCredentials: true,

                                                                                // Headers sent along with the XMLHttpRequest to the upload server.
                                                                                headers: {
                                                                                    // 'X-CSRF-TOKEN': 'CSRF-Token',
                                                                                    Authorization: `Bearer ${localStorage.getItem('token')}`
                                                                                }
                                                                            }
                                                                    }}
                                                                    onChange={(event, editor) => {
                                                                        const data = editor.getData();
                                                                        setFieldValue('rate_dislike_text', data);
                                                                    }}
                                                                    onBlur={(event, editor) => {
                                                                    }}
                                                                    onFocus={(event, editor) => {
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    canRate?
                                                    <div className="tajah-form-flex-holder">
                                                        <div className="tajah-form-flex-holder-container">
                                                            <div style={{
                                                                display: 'flex',
                                                                justifyContent: 'center'
                                                            }}>
                                                                <button
                                                                    type="submit"
                                                                    className="admin_add_button"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {t("rating.action")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div> : null
                                                }
                                            </form>
                                        </>
                                    )}

                                </Formik>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default RateTicket;
