import classes from "./specialtiesDetails.module.css";
const SpecialtiesDetailsPreview = ({ title, description,descriptionLabel }) => {
	return (
		<div className={classes["specialties-details__preview"]}>
			<h1 className={classes["specialties-details__preview-title"]}>{title}</h1>
			<div 
				className={classes["specialties-details__preview-desc"]}
			>
				<h4>{descriptionLabel}:</h4>
				<div
					dangerouslySetInnerHTML={{
						__html: description,
					}}
					className={classes["specialties-details__preview-desc-text"]}
				></div>
			</div>
		</div>
	);
};

export default SpecialtiesDetailsPreview;
