import LessonTypes from "../Enums/LessonTypeEnum";

const LessonTypeAcceptanceFactory = (type) => {
    if(type == LessonTypes.h5p){
        return ".h5p";
    } else if (type == LessonTypes.scorm) {
        return ".zip";
    }else if (type == LessonTypes.xapi) {
        return ".zip";
    }else if (type == LessonTypes.file) {
        return "*";
    }

    return "*";
}

export default LessonTypeAcceptanceFactory;
