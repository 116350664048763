import React from "react";
import {CourseProvider} from "../../../../Services/api/courses/courseContext";
import TrainingCoursesDetails from "../TrainingCourseDetails/TrainingCourseDetails";

function TrainingCoursesDetailsProvider({isProgram}) {
  require("./TrainingCoursesDetailsProvider.css");
  return (
    <CourseProvider>
      <TrainingCoursesDetails isProgram={isProgram} />
    </CourseProvider>
  );
}

export default TrainingCoursesDetailsProvider;
