import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { getCourseDetails } from "../../../../Services/api/courses/courseProvider";
import { TrainingCourseProfileProvider } from "../../../../Services/api/toutorials/TrainingCourseProfileContext";
import TrainingCourseProfileBody from "../TrainingCourseProfileBody/TrainingCourseProfileBody";
import TrainingCourseHeader from "../TrainingCourseProfileHeader/TrainingCourseProfileHeader";
import TrainingCourseProfileTabLayout from "../TrainingCourseProfileTabLayout/TrainingCourseProfileTabLayout";

function TrainingCourseProfile(props) {
  require("./TrainingCourseProfile.css");

  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    getCourseDetails(id).then((res) => {
      if (!res.data.course.subscribed) history.push("/private-profile");
    });
  }, []);
  return (
    <>
      <TrainingCourseProfileProvider>
        <div className="container-fluid training-course-body min-hight-78">
          <TrainingCourseHeader courseID={id} />
          <TrainingCourseProfileTabLayout />
          <TrainingCourseProfileBody courseID={id} />
        </div>
      </TrainingCourseProfileProvider>
    </>
  );
}

export default TrainingCourseProfile;
