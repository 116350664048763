import { trainerProfileConstant } from "../../Constants/trainerProfile/trainerProfile.constant";

const initialState = {
  loading: false,

  get_classifications: {},
  get_trainer: {},
  get_trainer_details: {},
  get_category: {},
  get_trainer_course: {},
};

const trainerProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case trainerProfileConstant.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case trainerProfileConstant.STOP_LOADING:
      return {
        ...state,
        loading: false,
      };

    case trainerProfileConstant.GET_CLASSIFICATION:
      return {
        ...state,
        get_classifications: action.payload,
      };

    case trainerProfileConstant.GET_TRAINER:
      return {
        ...state,
        get_trainer: action.payload,
      };

    case trainerProfileConstant.GET_TRAINER_DETAILS:
      return {
        ...state,
        get_trainer_details: action.payload,
      };

    case trainerProfileConstant.GET_CATEGORY:
      return {
        ...state,
        get_category: action.payload,
      };

    case trainerProfileConstant.GET_TRAINER_COURSE:
      return {
        ...state,
        get_trainer_course: action.payload,
      };

    case trainerProfileConstant.RESET_TRAINER:
      return {
        loading: false,

        get_classifications: {},
        get_trainer: {},
        get_trainer_details: {},
        get_category: {},
        get_trainer_course: {},
      };

    default:
      return state;
  }
};

export default trainerProfileReducer;
