export const faqSupportTicketsConstant = {
  // loading
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",

  GET_FAQ_GENERAL_LIST_ST: "GET_FAQ_GENERAL_LIST_ST",

  GET_FAQ_CATEGORIES_LIST_ST: "GET_FAQ_CATEGORIES_LIST_ST",
  GET_FAQ_CATEGORIES_TYPE_LIST_ST: "GET_FAQ_CATEGORIES_TYPE_LIST_ST",

  // faq category
  GET_FAQ_CATEGORY_ADD_ST: "GET_FAQ_CATEGORY_ADD_ST",
  GET_FAQ_CATEGORY_DELETE_ST: "GET_FAQ_CATEGORY_DELETE_ST",
  GET_FAQ_CATEGORY_STATUS_CHANGE_ST: "GET_FAQ_CATEGORY_STATUS_CHANGE_ST",
  GET_FAQ_CATEGORY_RE_ORDER_ST: "GET_FAQ_CATEGORY_RE_ORDER_ST",

  // faq questions
  GET_FAQ_QUESTION_ADD_ST: "GET_FAQ_QUESTION_ADD_ST",
  GET_FAQ_QUESTION_RESET_ST: "GET_FAQ_QUESTION_RESET_ST",
  GET_FAQ_QUESTION_DELETE_ST: "GET_FAQ_QUESTION_DELETE_ST",
  GET_FAQ_QUESTION_EDIT_ST: "GET_FAQ_QUESTION_EDIT_ST",
  GET_FAQ_QUESTION_SHOW_ST: "GET_FAQ_QUESTION_SHOW_ST",
  GET_FAQ_QUESTION_STATUS_CHANGE_ST: "GET_FAQ_QUESTION_STATUS_CHANGE_ST",
  GET_FAQ_QUESTION_RE_ORDER_ST: "GET_FAQ_QUESTION_RE_ORDER_ST",
};
