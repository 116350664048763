import { departmentListConstants } from "../../Constants/departments/departmentList.contants";

const initialState = {
	departmentList: [],
	departmentListMeta: {},
	departmentListLoading: false,
	departmentListError: null,
	departmentListSuccess: false,

	updateDepartmentStatusLoading: false,
	updateDepartmentStatusError: null,
	updateDepartmentStatusSuccess: false,
};

const departmentListReducer = (state = initialState, action) => {
	switch (action.type) {
		// GET DEPARTMENT LIST
		case departmentListConstants.GET_DEPARTMENT_LIST_REQUEST:
			return {
				...state,
				departmentListLoading: true,
			};

		case departmentListConstants.GET_DEPARTMENT_LIST_SUCCESS:
			return {
				...state,
				departmentListLoading: false,
				departmentListSuccess: true,
				departmentListError: null,
				departmentList: action.payload.departments.data,
				departmentListMeta: action.payload.departments.meta,
			};

		case departmentListConstants.GET_DEPARTMENT_LIST_REJECTED:
			return {
				...state,
				departmentListLoading: false,
				departmentListError: action.payload,
				departmentListSuccess: false,
			};

		// UPDATE DEPARTMENT STATUS
		case departmentListConstants.UPDATE_DEPARTMENT_STATUS_REQUEST:
			return {
				...state,
				updateDepartmentStatusLoading: true,
			};

		case departmentListConstants.UPDATE_DEPARTMENT_STATUS_SUCCESS:
			return {
				...state,
				updateDepartmentStatusLoading: false,
				updateDepartmentStatusSuccess: true,
				updateDepartmentStatusError: null,
				departmentList: state.departmentList.map((department) => {
					if (department.id === action.payload.id) {
						return {
							...department,
							status: !department.status,
						};
					}
					return department;
				}),
			};

		case departmentListConstants.UPDATE_DEPARTMENT_STATUS_REJECTED:
			return {
				...state,
				updateDepartmentStatusLoading: false,
				updateDepartmentStatusError: action.payload,
				updateDepartmentStatusSuccess: false,
			};

		default:
			return state;
	}
};

export default departmentListReducer;
