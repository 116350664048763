import Select from 'react-select';

const SearchSelect = ({ options, placeholder, width,onChange,disabled,value }) => {
  return (
    <Select
      name="user"
      options={options}
      getOptionLabel={(option) => option.text}
      getOptionValue={(option) => option.value}
      onChange={(value) => {
        onChange(value.value)
      }}
      placeholder={placeholder}
      styles={{
        control: (style, {isDisabled }) => ({
          ...style,
          width: width || "100%",
          height: "40px",
          cursor: isDisabled ? "not-allowed" : "pointer",
          backgroundColor: isDisabled ? "rgba(239, 239, 239, 0.3)" : "#FFFFFF"
        }),
      }}
      value={options.find(option => option.value === value) || null}
      isDisabled={disabled}
    />
  )
}

export default SearchSelect