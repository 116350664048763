export const formatDate = (d) => {
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const formatTime = (t) => {
  let hours = "" + t.getHours(),
    minutes = "" + t.getMinutes(),
    second = "00";

  hours = hours == "0" ? "00" : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return [hours, minutes, second].join(":");
};