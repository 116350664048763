import env from "./Environment";
import connector from "./Handler";

export function exportBankTransferList(data, export_type) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(env.get_payments, {
          //////////////////
          responseType: "blob",
          params: {
            ...data,
            export: export_type,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Bank Transfer" + "." + export_type); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
