import {useEffect, useState} from "react";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
// import ar from 'react-phone-number-input/locale/ar'
// import en from 'react-phone-number-input/locale/en'

function InputPhoneWithCountry(props) {
  require('./input.css')
  const [content, setContent] = useState("");

  const inputHandleChange = (value, country, event) => {
    setContent(value);
    props.setContent(value);
  };

  useEffect(() => {
    if (props.initialValue != null) {
      setContent(props.initialValue.toString());
    }
  }, []);

  return (
    <div className="input_component_container">
      <div className={props.withoutSpaceAround == true ? "input_container_without_space_around" : "input_container"}>
         <PhoneInput
            value={content}
            className="personal_input_in"
            onChange={inputHandleChange}
            maxLength={props.maxLength}
            withCountryCallingCode={true}
            international={true}
            // labels={i18n.language == Lang.AR ? ar : en}
        />
        <div className="input_icon_container">{props.icon}</div>
      </div>
      <div className="input_hint_container">
        <span style={{ fontSize: 12, color: "#006d77" }}>{props.hint}</span>
      </div>
    </div>
  );
}

export default InputPhoneWithCountry;
