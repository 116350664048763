import React, { useState } from "react";
import AddIcon from "../../../../assets/icons/plus-green.svg";
import classes from "./addNewItem.module.css";

export default function AddNewItem({ title, action, actions }) {
  const [actionOpen, setActionOpen] = useState(false);
  const handleAction = () => {
    setActionOpen(!actionOpen);
  };

  return (
    <div className={classes[`add-new-item__container${actions && actionOpen ? "--active" : ""}`]}>
      <div className={classes["add-new-item__container__content"]}>
        <hr className={classes["add-new-item__container__content__hr"]} />
        <button
          type="button"
          onClick={action ? action.onClick : handleAction}
          className={classes["add-new-item__container__content__btn"]}
          disabled={action && action.disabled}
        >
          <div className={classes["add-new-item__container__content__btn__icon"]}>
            <img src={AddIcon} alt="add" />
          </div>
          <div>{title}</div>
        </button>
      </div>
      {actions && actionOpen ? (
        <div className={classes["add-new-item__container__content__actions"]}>
          {actions.map((action, index) => (
            <button
              key={index}
              type="button"
              onClick={action.onClick}
              className={classes["add-new-item__container__content__actions__btn"]}
              disabled={action.disabled}
            >
              <div>{action.label}</div>
            </button>
          ))}
        </div>
      ) : null}
    </div>
  );
}
