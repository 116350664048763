import React, {createContext, useState} from 'react';

const CoursePaymentContext = createContext();

function CoursePaymentProvider(props) {
    const [paymentScreen, setPaymentScreen] = useState(0)
    const [modal, setModal] = useState(true)

    return (
        <CoursePaymentContext.Provider value={{
            paymentScreen,
            setPaymentScreen,
            modal,
            setModal
        }}>
            { props.children }
        </CoursePaymentContext.Provider>
    );
}

export {CoursePaymentContext, CoursePaymentProvider};
