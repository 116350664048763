import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useRouteMatch } from "react-router";

function Header() {
  require("./header.css");
  const { t } = useTranslation();
  const { id } = useParams();
  const { path } = useRouteMatch();

  return (
    <div className="header_profile">
      <div
        className="container-fluid profile_wrapper"
        style={{
          padding: "0 30px",
        }}
      >
        <div className="row">
          <div className="col-12">
            <div className="show_certificate_grid">
              <div className="profile_dtop">{t("edu.Edu_goals")}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
