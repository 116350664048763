export const peymentRequestsAdminConstants = {
  // GET ALL PEYMENTREQUESTS LIST
  GET_PEYMENTREQUESTSADMIN_LIST_REQUEST:
    "GET_PEYMENTREQUESTSADMIN_LIST_REQUEST",
  GET_PEYMENTREQUESTSADMIN_LIST_SUCCESS:
    "GET_PEYMENTREQUESTSADMIN_LIST_SUCCESS",
  GET_PEYMENTREQUESTSADMIN_LIST_REJECTED:
    "GET_PEYMENTREQUESTSADMIN_LIST_REJECTED",

  // REJECT PEYMENTREQUEST
  REJECT_PEYMENTREQUESTADMIN_REQUEST: "REJECT_PEYMENTREQUESTADMIN_REQUEST",
  REJECT_PEYMENTREQUESTADMIN_SUCCESS: "REJECT_PEYMENTREQUESTADMIN_SUCCESS",
  REJECT_PEYMENTREQUESTADMIN_REJECTED: "REJECT_PEYMENTREQUESTADMIN_REJECTED",

  // ACCEPT PEYMENTREQUESTADMIN STATUS
  ACCEPT_PEYMENTREQUESTADMIN_STATUS_REQUEST:
    "ACCEPT_PEYMENTREQUESTADMIN_STATUS_REQUEST",
  ACCEPT_PEYMENTREQUESTADMIN_STATUS_SUCCESS:
    "ACCEPT_PEYMENTREQUESTADMIN_STATUS_SUCCESS",
  ACCEPT_PEYMENTREQUESTADMIN_STATUS_REJECTED:
    "ACCEPT_PEYMENTREQUESTADMIN_STATUS_REJECTED",
};
