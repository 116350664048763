import ContentLoadingSpinner from '../../Shared/Components/ContentLoadingSpinner/ContentLoadingSpinner'
import classes from './search.module.css'

const SearchActions = ({buttons}) => {
  return (
    <div className={classes["search__actions"]}>
      {buttons.map((button, index) => (
        <button key={index} className={classes["search__actions-button"]}
          onClick={button.onClick}
          style={{
            backgroundColor: button.backgroundColor,
            color: button.color,
            border: button.borderColor ? `1px solid ${button.borderColor}` : "none"
          }}
          disabled={button.disabled}
        >
           {button.isLoading ? <ContentLoadingSpinner /> : button.label}
        </button>
      ))}
    </div>
  )
}

export default SearchActions