import i18n from "../../../../../i18n/i18n";
import { useTranslation } from "react-i18next";
import logo from "../../../../../assets/image/logo.png";
import icon from "../../../../../assets/image/Icon.png";
import { useContext } from "react";
import { ProfileInfoContext } from "../../../../../Services/api/toutorials/profileInfoContext";
import { NavLink } from "react-router-dom";
import TrainingCourseSpinnerCard from "../../../../TrainingCourses/TrainingCoursesLayout/TrainingCourseSpinnerCard/TrainingCourseSpinnerCard";
import noResult from "../../../../../assets/image/Noresult.png";

function CoursesStd() {
  require("./coursesStd.css");
  document.body.dir = i18n.dir();
  const { t } = useTranslation();

  const profileUserContext = useContext(ProfileInfoContext);

  return (
    <>
      <div className="head_box">
        <div className="head_lable">{t("courseteach")}</div>

        <div className="head_view">
          <NavLink
            to="/study-courses"
            style={{
              color: "#000",
            }}
          >
            <div className="">{t("ViewAll")}</div>
          </NavLink>
          <div className="arrow_seeall">
            <img src={icon} alt="" className="arrow_icon" />
          </div>
        </div>
      </div>

      <div
        className={
          profileUserContext.profileUser.courses &&
          profileUserContext.profileUser.courses.length == 0
            ? ""
            : " courses_card_grid"
        }
      >
        {profileUserContext.loading == true ? (
          <>
            <TrainingCourseSpinnerCard />
            <TrainingCourseSpinnerCard />
            <TrainingCourseSpinnerCard />
          </>
        ) : profileUserContext.profileUser.courses &&
          profileUserContext.profileUser.courses.length == 0 ? (
          <span
            className="course-details"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "50px",
            }}
          >
            <img src={noResult} alt="" className="no-result-img" />
            <h5>{t("no_record")}</h5>
          </span>
        ) : (
          profileUserContext.profileUser.courses &&
          profileUserContext.profileUser.courses.slice(0, 6).map((course) => (
            <div className="courses_card_std" key={course.id}>
              <div
                className="courses_card_first_std"
                style={{
                  backgroundImage: "url(" + course.img + ")",
                }}
              >
                <div className="courses_name">
                  <div className="logo_hover">
                    <img src={logo} alt="" className="logo_hover_width" />
                  </div>
                  <div className="">{course.name}</div>
                </div>
              </div>
              <div className="courses_card_second_std">
                <div className="courses_card_point">
                  <div className=""></div>
                  <div className="">
                    {!course.progress.total && !course.quiz_progress.total
                      ? "100%"
                      : Math.round(
                          ((course.progress.progress +
                            course.quiz_progress.progress) /
                            (course.progress.total +
                              course.quiz_progress.total)) *
                            100
                        ) + "%"}
                  </div>
                </div>
                {/* <div className="">
                  {t("point")} {course.progress.progress}/
                  {course.progress.total}
                </div> */}
              </div>
              <div className="progress">
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{
                    width: `${
                      !course.progress.total && !course.quiz_progress.total
                        ? 100
                        : Math.round(
                            ((course.progress.progress +
                              course.quiz_progress.progress) /
                              (course.progress.total +
                                course.quiz_progress.total)) *
                              100
                          )
                    }%`,
                  }}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <NavLink
                to={"/training-courses/" + course.id + "/profile"}
                className=""
              >
                <div className="courses_card_button">
                  <button className="courses_card_button_in">
                    {t("contstudy")}
                  </button>
                </div>
              </NavLink>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default CoursesStd;
