import React from "react";
import classes from "./mainBox.module.css";
const MainBox = ({ children, className, style }) => {
  return (
    <div className={`${classes["main-box"]} ${className}`} style={style}>
      {children}
    </div>
  );
};

export default MainBox;
