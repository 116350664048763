function TrainingCourseSpinnerCard() {
  require("./TrainingCourseSpinnerCard.css");
  return (
    <>
      <div className="courses_card">
        <div
          className="skeleton-box"
          style={{ width: "100%", height: "100%" }}
        ></div>
      </div>
    </>
  );
}

export default TrainingCourseSpinnerCard;
