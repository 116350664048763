import React from "react";

function AnalysisCard({ img, counter, label, smallImage }) {
  return (
    <div className="analysis-card_new">
        {
            smallImage?
            <img
                src={img}
                alt=""
                style={{
                width: "40px",
                }}
            />
            :
            <img
                src={img}
                alt=""
                style={{
                width: "70px",
                }}
            />
        }
      
      <div
        style={{
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: "25px", color: "#14949A", fontWeight: "bold" }}>
          {counter}
        </div>
        <div 
            style={ smallImage ? { fontSize: "15px", fontWeight: "bold" } : { fontSize: "20px", fontWeight: "bold" }}>{label}</div>
      </div>
    </div>
  );
}

export default AnalysisCard;
