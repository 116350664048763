import { useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import timeIcon from "../../../../assets/icons/time.png";
import editIcon from "../../../../assets/icons/pen.svg";
import { utcToLocal } from "../../../../utils/utcToLocal";
import MainTable from "../../../Shared/Components/MainTable/MainTable";
import TableActionsColumn from "../../../Shared/Components/TableActionsColumn/TableActionsColumn";
import SharedModal from "../../../Shared/Components/SharedModal/SharedModal";
import classes from "./PaymentDone.module.css";

const PaymentDoneTable = ({ loading, paymentList }) => {
  const { t, i18n } = useTranslation();

  //   const [taxId, setTaxId] = useState(null);
  const history = useHistory();
  const displayMode = (parent_type) => {
    if (parent_type === 1) {
      return t("rate.label.course");
    } else {
      return t("general.program");
    }
  };

  const giftActionsList = [
    {
      id: "payment",
      icon: <img src={timeIcon} alt="" />,
      action: (id) => {
        //   history.push(`/private-profile/sent-gift/payments-due`);
      },
      tooltip: t("trainer.program.payment_list"),
    },
  ];

  const columns = [
    {
      id: "id",
      name: "#",
      key: "id",
      cell: ({ rowData, rowIndex }) => <>{rowIndex + 1}</>,
    },

    {
      id: "amount",
      name: t("general.amount"),
      key: "amount",
      cell: ({ rowData }) => (
        <>{`${rowData?.amount} ${t("general.rs")}` || "-"}</>
      ),
      sortable: false,
    },
    {
      id: "parent_type",
      name: t("zoom.type"),
      key: "parent_type",
      cell: ({ rowData }) => (
        <>
          <span
            className="cell-font tw-whitespace-nowrap tw-rounded-lg tw-py-1 tw-px-2"
            style={{
              color: rowData?.parent_type == 1 ? "#8599B6" : "#12C689",
              backgroundColor:
                rowData?.parent_type == 1 ? "#C1CEE222" : "#C1E2C822",
            }}
          >
            {displayMode(rowData?.parent_type) || "-"}
          </span>
        </>
      ),
    },
    {
      id: "course_or_program",
      name: t("coupon.label.course_or_program"),
      key: "course_or_program",
      cell: ({ rowData }) => (
        <div className={classes["gift-list__table-name"]}>
          {rowData?.program?.name || rowData?.course?.name || "-"}
        </div>
      ),
    },
    {
      id: "payment_courses",
      name: t("payments.payment_courses"),
      key: "payment_courses",
      cell: ({ rowData }) => (
        <div className={classes["gift-list__table-name"]}>
          {rowData?.payment_course_name || "-"}
        </div>
      ),
    },

    {
      id: "payment_date",
      name: t("general.payment_date"),
      key: "payment_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.payment_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.payment_date)}
        </>
      ),
      sortable: false,
    },
    {
      id: "end_date",
      name: t("general.payment_end_date"),
      key: "end_date",
      cell: ({ rowData }) => (
        <>
          {moment(rowData?.end_date).format("DD/MM/YYYY")}
          <br />
          {utcToLocal(rowData?.end_date)}
        </>
      ),
      sortable: false,
    },
    {
      id: "gifted_to",
      name: t("gifted_to"),
      key: "gifted_to",
      cell: ({ rowData }) => (
        <>
          {i18n.language === "ar"
            ? rowData?.trainee?.name_ar
            : rowData?.trainee?.name_en}
        </>
      ),
      sortable: false,
    },

    // {
    //   id: "actions",
    //   name: t("general.actions"),
    //   key: "actions",
    //   cell: ({ rowData }) => (
    //     <>
    //       {rowData?.parent_type == 1 ? (
    //         "-"
    //       ) : (
    //         <TableActionsColumn actions={giftActionsList} id={rowData.id} />
    //       )}
    //     </>
    //   ),
    // },
  ];

  return (
    <>
      <MainTable
        columns={columns}
        data={paymentList || []}
        isLoading={loading}
      />
    </>
  );
};

export default PaymentDoneTable;
