import { useTranslation } from "react-i18next";
import CustomButton from "../../../Shared/Components/CustomButton/CustomButton";
import MainBox from "../../../Shared/Components/MainBox/MainBox";
import style from "./invalidEmailModal.module.css";

export default function InvalidEmailModal({
  dismiss,
  action,
  img,
  label,
  labelClassName,
  subLabel,
  descriptionOne,
  descriptionTwo,
  descriptionThree,
  descriptionClassName,
  dismissText,
  dismissColor,
  successText,
  successColor,
}) {
  const { t } = useTranslation();
  return (
    <>
      <div className="back-drop" onClick={dismiss}></div>
      <MainBox className={`${style["border-8"]} ${style["modal-w"]} ${style["warning-modal"]} `}>
        <div className={style["warning-img"]}>
          <img src={img} alt="warning" />
        </div>
        <div className={style["warning-text"]}>
          {label && <h2 className={`${style.label} ${labelClassName}`}>{label}</h2>}
          {subLabel && <h3 className={style["sub-label"]}>{subLabel}</h3>}
          <p className={`${style.description} ${descriptionClassName}`}>
            {descriptionOne}{" "}
            <a
              href="https://passwordreset.microsoftonline.com/"
              target="_blank"
              rel="noreferrer"
              className={style.link}
            >
              {t("click_here")}
            </a>{" "}
            {descriptionTwo}
          </p>
          <p className={`${style.description} ${descriptionClassName}`}>{descriptionThree}</p>
        </div>
        <div className={style["modal-buttons"]}>
          {action && <CustomButton value={successText} colors={successColor} type={"button"} action={action} />}
          {dismiss && <CustomButton value={dismissText} colors={dismissColor} type={"button"} action={dismiss} />}
        </div>
      </MainBox>
    </>
  );
}
