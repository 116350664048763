import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import SkeletonCard from '../../Shared/Components/Spinner/SkeletonCard'
import SearchActions from './SearchActions'
import SearchBox from './SearchBox'
import SearchTabs from './SearchTabs'
import classes from './search.module.css'

const SearchFilter = ({
  activeTab,
  searchTabs,
  activeSearchList,
  onMainTabChange,
  updateFieldValues,
  submitSearchHandler,
  showSaveSearchModalHandler,
  sendSearchLoading,
  updateFieldArrayValues,
  resetFieldArrayValues,
  resetSearchListHandler,
  searchLoading
}) => {
  const { t, i18n } = useTranslation();


  const buttons = [
    {
      id: "reset",
      label: t("general.reset"),
      backgroundColor: "transparent",
      color: "#24b3b9",
      borderColor: "#24b3b9",
      onClick: () => {
        resetSearchListHandler()
      }
    },
    {
      id: "save",
      label: t("search.search_and_save"),
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
      onClick: () => {
        showSaveSearchModalHandler()
      }
    },
    {
      id: "search",
      label: t("general.search"),
      backgroundColor: "#046c77",
      color: "#ffffff",
      onClick: () => {
        submitSearchHandler()
      },
      isLoading: sendSearchLoading,
      disabled: sendSearchLoading
    },
  ]


  return (
    <>
      <div className={classes["search__box__item-fields"]}>
        <div className={classes["search__box__item-label"]}>{t("search.search_in")}</div>
        <SearchTabs
          tabs={searchTabs}
          activeTab={activeTab}
          onChange={onMainTabChange}
          size={"small"}
        />
      </div>
      {searchLoading && activeTab ? (
        <div
        style={{
          height: "65vh",
          width: "100%",
        }}
        >
        <SkeletonCard />
        </div>
      ) : (
      <SearchBox
        activeSearchList={activeSearchList}
        updateFieldValues={updateFieldValues}
        updateFieldArrayValues={updateFieldArrayValues}
        resetFieldArrayValues={resetFieldArrayValues}
        buttons={buttons}
      />
      )}
    
    </>
  )
}

export default SearchFilter