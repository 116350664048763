import env from "../Environment";
import connector from "../Handler";
import URLBuilder from "../UrlBuilder";


// export Trainee certificates
export function exportTraineeCertificates(id,type, params) {
  let extension = "";
  type == "pdf" ? (extension = "pdf") : (extension = "xlsx");
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.certificates_id, { id }), {
          responseType: "blob",
          params: {
            export: extension,
            ...params,
          },
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "certificates" + "." + extension); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}

