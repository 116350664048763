import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink, useParams} from "react-router-dom";
import {AuthContext} from "../../../Services/api/auth/AuthContext";
import {checkProgramCertificate} from "../../../Services/api/certificate/CertificateProvider";

function ProgramHeader(props) {
  require("./ProgramHeader.css")
  const { t } = useTranslation();
  const { id } = useParams()
  const authContext = useContext(AuthContext);
  const [isCertificate, setisCertificate] = useState(null)

  useEffect(()=>{
    _checkProgramCertificate(id);
},[]);

    const _checkProgramCertificate = async(id) => {
        const res = await checkProgramCertificate(id)

        if(res.status == 200){
            if(res.data.response){
                setisCertificate(res.data.response.is_certificate)
            }

        }
    }

  return (
    <div className="main_green_header">
      <div className="container-fluid py-3">
        <div className="program_grid">
          <div className="main_green_text">{props.label}</div>
          {authContext.roles.includes("trainee") ?
            props.btn == undefined || props.btn == null ? null : (
              <>
                <div className=""></div>
                <div className="btn_program">

                {
                    isCertificate?
                        <NavLink
                        to={{
                            pathname:`/certificate-program/${id}`
                        }}
                        className="header_button"
                        style={{ width: "50%"}}
                      >
                        {t('certificate.view_certificate')}
                        </NavLink>
                        :null}
                        {props.program && props.program.payments.length && props.program.payments[0].order_id ?
                                          <NavLink
                                          to={{
                                          pathname:`/register-program/${id}`,
                                          program: props.program
                                          }}
                                          className="header_button"
                                          style={{
                                          width: "50%",
                                          }}
                                      >
                                          {t("programs.values.payments_program")}
                                      </NavLink>
                        :
                    <NavLink
                        to={{
                        pathname:`/register-program/${id}`,
                        program: props.program
                        }}
                        className="header_button"
                        style={{
                        width: "50%",
                        }}
                    >
                        {t("programs.values.register_in_the_program")}
                    </NavLink>
                 }
                </div>
              </>
            ) : null
          }

        </div>
      </div>
    </div>
  );
}

export default ProgramHeader;
