import React, {createContext, useState} from "react";

const CourseProfileContext = createContext();

function CourseProfileProvider(props) {
  const [courseID, setCourseID] = useState(null);
  const [course, setCourse] = useState(null);
  const [chapters, setChapters] = useState([]);
  const [isChaptersLoaded, setIsChaptersLoaded] = useState(false);
  const [courseInfo, setCourseInfo] = useState({});
  const [lessonId, setLessonId] = useState(null);
  const [fileLoaded,setFileLoaded] = useState(false)
  const [popupEduGoal,setPopupEduGoal] = useState(false)
  const [survies,setSurvies] = useState([])
  const [showSurviesModal,setShowSurviesModal] = useState(false)
  const [deleteFaqModal,setDeleteFaqModal] = useState(false)

  return (
    <CourseProfileContext.Provider
      value={{
        courseID,
        setCourseID,
        chapters,
        setChapters,
        isChaptersLoaded,
        setIsChaptersLoaded,
        course,
        setCourse,
        courseInfo,
        setCourseInfo,
        lessonId,
        setLessonId,
        fileLoaded,
        setFileLoaded,
        popupEduGoal,
        setPopupEduGoal,
        survies,
        setSurvies,
        showSurviesModal,
        setShowSurviesModal,
        deleteFaqModal,
        setDeleteFaqModal
      }}
    >
      {props.children}
    </CourseProfileContext.Provider>
  );
}

export { CourseProfileContext, CourseProfileProvider };
