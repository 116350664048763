import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./breadcrumb.module.css";

const Breadcrumb = ({ list }) => {
  return (
    <div className={"container-fluid"}>
      <ul className={classes["breadcrumb"]}>
        {list.map((item, index) => (
          <li
            key={item.id + index} // Key assigned directly to the <li> element
            className={`${classes["breadcrumb__item"]} ${
              item.active ? classes["active"] : ""
            }`}
          >
            {item.active ? (
              item.page
            ) : (
              <NavLink to={item.pagePath}>{item.page}</NavLink>
            )}
            {index !== list.length - 1 && (
              <div
                key={`separator-${index}`}
                className={classes["breadcrumb__item-separator"]}
              ></div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumb;
