import i18n from "../../../../i18n/i18n";

export default function courseRowAction({
  completeCourse,
  completePayment,
  courseNotStarted,
  courseEnded,
  paymentWaiting,
  paymentRejected,
  sendNotificationToGiftSender,
  sendNotificationToGiftSenderAfterPaymentRejected,
}) {
  const actionsList = [
    ...(completeCourse
      ? [
          {
            id: "completeCourse",
            type: "text",
            action: completeCourse,
            text: i18n.t("completeCourse"),
            backgroundColor: "#046c77",
            textColor: "#fff",
          },
        ]
      : []),
    ...(courseNotStarted
      ? [
          {
            id: "courseNotStarted",
            type: "text",
            action: courseNotStarted,
            text: i18n.t("courseNotStarted"),
            textColor: "#8599B6",
            style: {
              border: "2px solid #8599B6",
              height: 50,
            },
          },
        ]
      : []),
    ...(courseEnded
      ? [
          {
            id: "courseEnded",
            type: "text",
            action: courseEnded,
            text: i18n.t("courseEnded"),
            textColor: "#8599B6",
            style: {
              border: "2px solid #8599B6",
              height: 50,
            },
          },
        ]
      : []),
    ...(completePayment
      ? [
          {
            id: "completePayment",
            type: "text",
            action: completePayment,
            text: i18n.t("completePayment"),
            backgroundColor: "#24b3b9",
            textColor: "#fff",
          },
        ]
      : []),
    ...(paymentWaiting
      ? [
          {
            id: "paymentWaiting",
            type: "text",
            action: paymentWaiting,
            text: i18n.t("paymentWaiting"),
            textColor: "#8599B6",
            style: {
              border: "2px solid #8599B6",
              height: 50,
            },
          },
        ]
      : []),
    ...(paymentRejected
      ? [
          {
            id: "paymentRejected",
            type: "text",
            action: paymentRejected,
            text: i18n.t("paymentRejected"),
            textColor: "#DF4B43",
            style: {
              border: "2px solid #DF4B43",
              height: 50,
            },
          },
        ]
      : []),
    ...(sendNotificationToGiftSender
      ? [
          {
            id: "sendNotificationToGiftSender",
            type: "text",
            action: sendNotificationToGiftSender,
            text: i18n.t("sendNotificationToGiftSender"),
            backgroundColor: "#046c77",
            textColor: "#fff",
          },
        ]
      : []),
    ...(sendNotificationToGiftSenderAfterPaymentRejected
      ? [
          {
            id: "sendNotificationToGiftSenderAfterPaymentRejected",
            type: "text",
            action: sendNotificationToGiftSenderAfterPaymentRejected,
            text: i18n.t("sendNotificationToGiftSenderAfterPaymentRejected"),
            backgroundColor: "#046c77",
            textColor: "#fff",
          },
        ]
      : []),
  ];

  return actionsList;
}
