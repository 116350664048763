import React from "react";
import { useTranslation } from "react-i18next";
import FilterBox from "../../Shared/Components/FilterBox/FilterBox";

const CertificateTemplatesFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  resetFilterHandler,
}) => {
  const { t, i18n } = useTranslation();

  const statusOptions = [
    {
      label: t("general.active"),
      id: 1,
      value: 1,
    },
    {
      label: t("general.inactive"),
      id: 2,
      value: 0,
    },
  ];
  const filterFields = [
    {
      id: "name",
      component: "input",
      label: t("certificate_templates_name"),
      type: "text",
      placeholder: t("certificate_templates_name"),
      name: "name",
      value: filterData.name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    // {
    //   id: "description",
    //   component: "input",
    //   label: t("certificate_templates_description"),
    //   type: "text",
    //   placeholder: t("certificate_templates_description"),
    //   name: "description",
    //   value: filterData.description,
    //   classes: "col-12 col-md-6 col-xl-4",
    //   onchange: changeHandler,
    //   onReset: resetHandler,
    // },
    {
      id: "status",
      label: t("general.status"),
      component: "select",
      placeholder: t("general.status"),
      initialValue: t("general.status"),
      name: "status",
      value: filterData.status,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: statusOptions || [],
    },
    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default CertificateTemplatesFilter;
