import FilterIcon from "../../../../assets/icons/filter.svg";
import PdfIcon from "../../../../assets/icons/pdf.svg";
import AddIcon from "../../../../assets/icons/plus.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";

export const filterActions = ({ showFilter, exportPdf, exportExcel, addAction, isAdmin }) => {
  const actionsList = [
    ...(addAction
      ? [
          {
            id: "add",
            icon: <img src={AddIcon} alt="" />,
            theme: "add",
            action: addAction,
          },
        ]
      : []),
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};
