import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#18B1B9",
  },
  customArrow: {
    color: "#18B1B9",
  },
}));
function IconsTooltip({title, content}) {
  const classes = useStyles();

  return (
    <>
      <Tooltip
        classes={{
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
        }}
        title={title}
        arrow
      >
        {content}
      </Tooltip>
    </>
  );
}

export default IconsTooltip;
