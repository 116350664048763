import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function AlreadyVerified(props) {
  require("./AlreadyVerified.css");
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <div className="already-verified">
        <h1>{t("verification.alreadyVerified.title")}</h1>
        {/* <p>{t("verification.alreadyVerified.description")}</p> */}
        <button onClick={() => history.push("/")}>{t("verification.alreadyVerified.completeProfile")}</button>
      </div>
    </>
  );
}

export default AlreadyVerified;
