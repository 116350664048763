import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import QuestionTypeEnum from "../../../Enums/QuestionTypeEnum";
import Paper from "@mui/material/Paper";
import DropDownSelectedAnswers from "../../Shared/Components/Trainer/Exam/Question/Answer/DropDownSelectedAnswers/DropDownSelectedAnswers";
import DragAndDropSelectedAnswers from "../../Shared/Components/Trainer/Exam/Question/Answer/DragAndDropSelectedAnswers/DragAndDropSelectedAnswers";
import OrderingSelectedAnswers from "../../Shared/Components/Trainer/Exam/Question/Answer/OrderingSelectedAnswers/OrderingSelectedAnswers";
import ExamAnswersQuestionHeader from "./ExamAnswersQuestionHeader";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

function ExamAnswers(props) {
  require("./ExamAnswers.css");
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    if (props.location.state == null) {
      history.push("/");
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {"عفوا ليس لديك صلاحية لعرض هذة الصفحة"}
        </span>
      );
    }
  }, []);
  return (
    <div className="tajah-list-exams-answers-header">
      <p className="tajah-exams-result-exam-title">
        {props.location.state && props.location.state.quiz_title}
      </p>

      <Paper
        variant={"outlined"}
        sx={{
          width: "90%",
          boxShadow: "2px 2px 10px #ddd",
          height: "auto",
        }}
      >
        {
          <div
            className={"tajah-form-container"}
            style={{
              boxShadow: "none",
              borderRadius: 0,
              padding: 0,
            }}
          >
            {props.location.state &&
              props.location.state.data.answers.map((item) => {
                if (item.type_id === QuestionTypeEnum.checkbox) {
                  return (
                    <div>
                      <ExamAnswersQuestionHeader
                        feedback={item.feedback}
                        title={item.title}
                        grade={item.grade}
                      ></ExamAnswersQuestionHeader>
                      <div style={{marginRight: "5%", width: "90%"}}>
                        <DropDownSelectedAnswers
                          answers={JSON.parse(item.data)}
                          selectedAnswers={JSON.parse(item.answer)}
                        />
                      </div>
                      <hr style={{ color: "black", height: "10px" }} />
                    </div>
                  );
                } else if (item.type_id === QuestionTypeEnum.radio_button) {
                  return (
                    <div>
                      <ExamAnswersQuestionHeader
                        feedback={item.feedback}
                        title={item.title}
                        grade={item.grade}
                      ></ExamAnswersQuestionHeader>
                      <div style={{marginRight: "5%", width: "90%"}}>
                        <DropDownSelectedAnswers
                          answers={JSON.parse(item.data)}
                          selectedAnswers={[JSON.parse(item.answer)]}
                        />
                      </div>
                      <hr style={{ color: "black", height: "10px" }} />
                    </div>
                  );
                } else if (item.type_id === QuestionTypeEnum.drag_and_drop) {
                  return (
                    <div>
                      <ExamAnswersQuestionHeader
                        feedback={item.feedback}
                        title={item.title}
                        grade={item.grade}
                      ></ExamAnswersQuestionHeader>
                      <div style={{marginRight: "5%", width: "90%"}}>
                        <DragAndDropSelectedAnswers
                          answers={JSON.parse(item.answer)}
                        />
                      </div>
                      <hr style={{ color: "black", height: "10px" }} />
                    </div>
                  );
                } else if (item.type_id === QuestionTypeEnum.ordering) {
                  return (
                    <div>
                      <ExamAnswersQuestionHeader
                        feedback={item.feedback}
                        title={item.title}
                        grade={item.grade}
                      ></ExamAnswersQuestionHeader>
                      <div style={{marginRight: "5%", width: "90%"}}>
                        <OrderingSelectedAnswers
                          answers={JSON.parse(item.answer)}
                        />
                      </div>
                      <hr style={{ color: "black", height: "10px" }} />
                    </div>
                  );
                } else if (
                  item.type_id === QuestionTypeEnum.text ||
                  item.type_id === QuestionTypeEnum.essay_question
                ) {
                  return (
                    <>
                      <ExamAnswersQuestionHeader
                        feedback={item.feedback}
                        title={item.title}
                        grade={item.grade}
                      ></ExamAnswersQuestionHeader>
                      <div className={"tajah-form-row"}>
                        <div
                          className={
                            "tajah-form-row-element-v2 tajah-question-info-section"
                          }
                        >
                          <div
                            className={
                              "tajah-form-row tajah-question-info-section-row"
                            }
                            style={{ width: "100%" }}
                          >
                            <div className={"tajah-form-row-element-v2"}>
                              <div
                                className={"tajah-form-row-element-holder"}
                              >
                                <label
                                  htmlFor="title"
                                  className="admin_add_label"
                                >
                                  {t(
                                    "trainer.quiz.crud.question_selected_option"
                                  )}
                                </label>
                                <input
                                  value={JSON.parse(item.answer)}
                                  className="admin_filter_input"
                                  disabled={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr style={{ marginBottom: "20px" }} />
                    </>
                  );
                } else {
                  return (
                    <>
                      <ExamAnswersQuestionHeader
                        feedback={item.feedback}
                        title={item.title}
                        grade={item.grade}
                      ></ExamAnswersQuestionHeader>
                      <hr style={{ color: "black", height: "10px" }} />
                      {/*<div className={"tajah-form-row"}>*/}
                      {/*    <div*/}
                      {/*        className={"tajah-form-row-element-v2 tajah-question-info-section"}>*/}
                      {/*        <div className={"tajah-form-row tajah-question-info-section-row"}>*/}
                      {/*            <div className={"tajah-form-row-element-v2"}>*/}
                      {/*                <div className={"tajah-form-row-element-holder"}*/}
                      {/*                     style={{width: "95%"}}>*/}
                      {/*                    <label htmlFor="title" className="admin_add_label">*/}
                      {/*                        {t("trainer.quiz.crud.question_selected_option")}*/}
                      {/*                    </label>*/}
                      {/*                    <input*/}
                      {/*                        value={JSON.parse(item.answer)}*/}
                      {/*                        className="admin_filter_input"*/}
                      {/*                        disabled={true}*/}
                      {/*                    />*/}
                      {/*                </div>*/}
                      {/*            </div>*/}
                      {/*        </div>*/}
                      {/*    </div>*/}
                      {/*</div>*/}
                    </>
                  );
                }
              })}
          </div>
        }
      </Paper>
    </div>
  );
}

export default ExamAnswers;
