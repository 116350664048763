import FilterIcon from "../../../assets/icons/filter.svg";
import AddIcon from "../../../assets/icons/plus.svg";

export const filterActions = ({ showFilter, addAnswersReply }) => {
  const actionsList = [
    {
      id: "filter",
      icon: <img src={FilterIcon} alt="" />,
      action: showFilter,
    },
    {
      id: "add",
      icon: <img src={AddIcon} alt="" />,
      theme: "add",
      action: addAnswersReply,
    },
  ];
  return actionsList;
};
