import React, {useContext} from "react";
import "react-circular-progressbar/dist/styles.css";
import {useTranslation} from "react-i18next";
import facebook from "../../../assets/image/facebook.png";
import twitter from "../../../assets/image/twitter.png";
import linkedin from "../../../assets/image/linkedin.png";
import {NavLink} from "react-router-dom";
import {TrainerProfileContext} from "../../../Services/api/toutorials/trainerProfileContext";
import {Lang} from "../../../utils";
import i18n from "../../../i18n/i18n";

function TrainersCCHeader() {
  const { t } = useTranslation();
  const trainerProfileContext = useContext(TrainerProfileContext);

  return (
    <div className="header_profile">
      <div className="container-fluid profile_wrapper">
        <div className="show_certificate_grid">
          <div className="profile_dhead">
            <img
              className="trainers_dimage"
              src={
                trainerProfileContext.trainersProfile.info &&
                trainerProfileContext.trainersProfile.info.img
              }
            />

            <div className="profile_dname">
              <div className="profile_dtop">
                {i18n.language === Lang.AR
                  ? trainerProfileContext.trainersProfile.info &&
                    trainerProfileContext.trainersProfile.info.name_ar
                  : trainerProfileContext.trainersProfile.info &&
                    trainerProfileContext.trainersProfile.info.name_en}
              </div>

              <div className="profile_dbottom">
                <div className="profile_head_soical mt-2">
                  {trainerProfileContext.trainersProfile.info &&
                  trainerProfileContext.trainersProfile.info.facebook ? (
                    <a
                      href={trainerProfileContext.trainersProfile.info.facebook}
                      target="_blank"
                    >
                      <img
                        src={facebook}
                        alt="FB"
                        className="profile_soical_img"
                      />
                    </a>
                  ) : null}
                  {trainerProfileContext.trainersProfile.info &&
                  trainerProfileContext.trainersProfile.info.twitter ? (
                    <a
                      href={trainerProfileContext.trainersProfile.info.twitter}
                      target="_blank"
                    >
                      <img
                        src={twitter}
                        alt="TW"
                        className="profile_soical_img pt-1"
                      />
                    </a>
                  ) : null}
                  {trainerProfileContext.trainersProfile.info &&
                  trainerProfileContext.trainersProfile.info.linkedin ? (
                    <a
                      href={trainerProfileContext.trainersProfile.info.linkedin}
                      target="_blank"
                    >
                      <img
                        src={linkedin}
                        alt="LIN"
                        className="profile_soical_img"
                      />
                    </a>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="profile_btn">
            <NavLink to="trainer/course/add" className="profile_inside_btn">
              <button className="profile_inside_btn">
                {t("createneewcourse")}
              </button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrainersCCHeader;
