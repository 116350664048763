import { Formik } from "formik";
import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import UploadModal from "../../../../../Modals/uploadModal/UploadModal";
import { CourseProfileContext } from "../../../../../Services/api/courses/Trainer/CourseProfileContext";
import {
  addSubChapterLesson,
  deleteChapter,
  editChapter,
  exportLessonContent,
  getCourseTree,
} from "../../../../../Services/api/courses/courseProvider";
import WarningIcon from "../../../../../assets/icons/warning.svg";
import AddNewItem from "../../../../Shared/Components/AddNewItem/AddNewItem";
import CourseDetailsHeader from "../../../../Shared/Components/CourseDetailsHeader/CourseDetailsHeader";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../../Shared/Components/Input/Input";
import { BasicModal } from "../../../../Shared/Components/Modal/BasicModal";
import SharedModal from "../../../../Shared/Components/SharedModal/SharedModal";
import LessonRow from "../LessonRow/LessonRow";
import { SubChapterRowActions } from "./subChapterRowActions";

function SubChapterRow(props) {
  require("../ChapterRow/chapterRow.css");
  const { t } = useTranslation();
  const uploadModalRef = useRef();
  const deleteModalRef = useRef();

  const [opened, setOpened] = useState(false);
  const [isLabelEditable, setIsLabelEditable] = useState(false);
  const [toBeChangedText, setToBeChangedText] = useState("");
  const [isChangingText, setIsChangingText] = useState(false);
  const [isAddingLesson, setIsAddingLesson] = useState(false);
  const [hasSubmittedLesson, setHasSubmittedLesson] = useState(false);
  const courseProfileContext = useContext(CourseProfileContext);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // const fetchCourseTree = () => {
  //   const myPromise = new Promise((resolve, reject) => {
  //     getCourseTree(courseProfileContext.courseID)
  //       .then((res) => {
  //         if (res.status && res.status == 200 && res.data.status) {
  //           courseProfileContext.setChapters(res.data.response.chapters);
  //           courseProfileContext.setIsChaptersLoaded(true);
  //         }
  //         resolve();
  //       })
  //       .catch((err) => {
  //         console.log("ERROR");
  //         console.log(err);
  //         resolve();
  //       });
  //   });

  //   return myPromise;
  // };

  const editSubChapterLocal = () => {
    const myPromise = new Promise(async (resolve, reject) => {
      setIsChangingText(true);
      if (toBeChangedText != props.subChapter.name) {
        editChapter(props.subChapter.id, {
          name: toBeChangedText,
          course_id: parseInt(courseProfileContext.courseID),
          parent_id: props.subChapter.parent_id,
          _method: "put",
        })
          .then(async (res) => {
            if (res.status && res.status == 200 && res.data.status) {
              await props.fetchCourse();
            }
            setIsChangingText(false);
            resolve();
          })
          .catch((err) => {
            console.log("ERROR");
            console.log(err);
            setIsChangingText(false);
            resolve();
          });
      } else {
        setIsChangingText(false);
        resolve();
      }
    });

    return myPromise;
  };

  const addLessonLocally = () => {
    let chapters = [];
    courseProfileContext.chapters.forEach((item) => {
      if (item.id == props.subChapter.parent_id) {
        item.sub_chapters.forEach((subChapter) => {
          if (subChapter.id == props.subChapter.id) {
            subChapter.lessons.push({
              emptyField: true,
            });
          }
        });
      }

      chapters.push(item);
    });

    courseProfileContext.setChapters(chapters);
  };

  const removeLessonLocally = (index) => {
    let chapters = [];
    courseProfileContext.chapters.forEach((item) => {
      if (item.id == props.subChapter.parent_id) {
        item.sub_chapters.forEach((subChapter) => {
          if (subChapter.id == props.subChapter.id) {
            // subChapter.lessons = subChapter.lessons.filter(lesson => {
            //     return lesson.emptyField ? false : true;
            // })
            subChapter.lessons.splice(index, 1);
          }
        });
      }

      chapters.push(item);
    });

    courseProfileContext.setChapters(chapters);
  };

  const downloadFile = () => {
    exportLessonContent(
      "lessons",
      props.subChapter.name,
      "zip",
      props.subChapter.id,
      courseProfileContext
    );
    toast.success(
      <span style={{ fontSize: 13, fontWeight: "bold" }}>
        {t("modal.trainer.course.chapter.messages.titleDownload")}
      </span>
    );
  };

  const removeSubChapter = () => {
    deleteChapter(props.subChapter.id)
      .then(() => {
        props.removeSubChapterLocally(props.index);

        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("modal.trainer.course.subChapter.delete.successDeleted")}
          </span>
        );
      })
      .catch((err) => {
        toast.success(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("modal.trainer.course.subChapter.delete.successDeleted")}
          </span>
        );
      })
      .finally(() => {
        closeDeleteModal();
      });
  };

  const openUploadModal = () => {
    setShowUploadModal(true);
    uploadModalRef.current.showModal();
  };

  const closeUploadModal = () => {
    setShowUploadModal(false);
    uploadModalRef.current.dismissModal();
  };

  const openDeleteModal = () => {
    setShowDeleteModal(true);
    // deleteModalRef.current.showModal();
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    // deleteModalRef.current.dismissModal();
  };

  return (
    <>
      <div
        className={`sub-chapter-row-container ${
          opened ? "sub-chapter-row-container-opened" : ""
        }`}
      >
        <BasicModal ref={uploadModalRef}>
          {showUploadModal && (
            <UploadModal
              title={t("modal.trainer.course.chapter.modal.title")}
              dismissModal={closeUploadModal}
              type={"lessons"}
              itemId={props.subChapter.id}
              fetchCourse={props.fetchCourse}
            />
          )}
        </BasicModal>

        {showDeleteModal && (
          <SharedModal
            icon={WarningIcon}
            title={t("modal.trainer.course.subChapter.delete.title")}
            name={props.subChapter.name}
            confirmMsg={t("modal.trainer.course.subChapter.delete.body")}
            confirmText={t(
              "modal.trainer.course.subChapter.delete.delete_aprove"
            )}
            cancelText={t("modal.common.cancel")}
            onConfirm={removeSubChapter}
            onCancel={closeDeleteModal}
          />
        )}

        <CourseDetailsHeader
          title={props.subChapter.name}
          actions={SubChapterRowActions({
            [props.role === "partner" ? "" : "downloadAction"]: downloadFile,
            [props.role === "partner"
              ? ""
              : isLabelEditable
              ? "saveAction"
              : "editAction"]: async () => {
              if (isLabelEditable) {
                await editSubChapterLocal();
                setIsLabelEditable(false);
                setToBeChangedText("");
              } else {
                setIsLabelEditable(true);
                setToBeChangedText(props.subChapter.name);
              }
            },
            [props.role === "partner" ? "" : "deleteAction"]: openDeleteModal,
          })}
          isLabelEditable={isLabelEditable}
          onChange={(event) => {
            setToBeChangedText(event.target.value);
          }}
          value={toBeChangedText}
          disabled={isChangingText}
          setOpened={setOpened}
          opened={opened}
        />
        {props.subChapter.lessons.map((item, index) => {
          if (item.emptyField) {
            return opened ? (
              <Formik
                initialValues={{
                  title: "",
                  chapter_id: props.subChapter.id,
                  duration: 0,
                  day: "2021-08-15",
                  time: "00:00 AM",
                  objective_ids: [],
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    setSubmitting(true);
                    setHasSubmittedLesson(false);
                    let response = await addSubChapterLesson(values);
                    if (response.status === 201 && response.data.status) {
                      await props.fetchCourse();
                    } else {
                      alert("Failure");
                    }
                    setSubmitting(false);
                    setIsAddingLesson(false);
                  } catch (err) {
                    setSubmitting(false);
                    setIsAddingLesson(false);
                    alert(
                      err.response.data && err.response.data.msg
                        ? err.response.data.msg
                        : "Failure in service"
                    );
                  }
                }}
                validateOnChange={hasSubmittedLesson}
                validate={(values) => {
                  const errors = {};
                  if (!values.title) {
                    errors.title = t("crud.errors.required");
                  }

                  setHasSubmittedLesson(true);
                  return errors;
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <>
                    <form
                      onSubmit={handleSubmit}
                      className="course-details-form"
                    >
                      <Input
                        name="title"
                        type="text"
                        label={t("crud.placeholders.name")}
                        placeholder={t("crud.placeholders.name")}
                        value={values.title}
                        onChange={handleChange}
                        error={errors.title}
                        required
                      />
                      <div className="course-details-actions">
                        <CustomButton
                          value={t("AddNewLesson")}
                          colors="#036c77"
                          type="submit"
                          disable={isSubmitting}
                          loading={isSubmitting}
                        />
                        <CustomButton
                          value={t("crud.placeholders.cancel")}
                          colors="#036c77"
                          variant="outlined"
                          type="button"
                          disable={isSubmitting}
                          loading={isSubmitting}
                          action={() => {
                            removeLessonLocally(index);
                            setIsAddingLesson(false);
                          }}
                        />
                      </div>
                    </form>
                  </>
                )}
              </Formik>
            ) : null;
          } else {
            return (
              <LessonRow
                key={index}
                item={item}
                opened={opened}
                index={index}
                lessons={props.subChapter.lessons}
                allLessons={props.allLessons}
                removeLessonLocally={removeLessonLocally}
                isAdmin={props.isAdmin}
                role={props.role}
                fetchCourse={props.fetchCourse}
              />
            );
          }
        })}
        {opened ? (
          props.role !== "partner" ? (
            <div className="add-new-item-container">
              <AddNewItem
                title={t("AddNewLesson")}
                actions={[
                  {
                    label: t("AddNewLesson"),
                    onClick: () => {
                      setHasSubmittedLesson(false);
                      setIsAddingLesson(true);
                      addLessonLocally();
                    },
                    disabled: isAddingLesson,
                  },
                  {
                    label: t(
                      "modal.trainer.course.chapter.hover.titleUploadLesson"
                    ),
                    onClick: openUploadModal,
                    disabled: isAddingLesson,
                  },
                ]}
              />
            </div>
          ) : null
        ) : null}
      </div>
    </>
  );
}

export default SubChapterRow;
