import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CloseIcon from "../../../../../assets/icons/remove-red.svg";
import CustomButton from "../../../../Shared/Components/CustomButton/CustomButton";
import Input from "../../../../Shared/Components/Input/Input";
import SkeletonCardOverlay from "../../../../Shared/Components/Spinner/SkeletonCardOverlay";
import Textarea from "../../../../Shared/Components/Textarea/Textarea";
import classes from "./sendMessagesModal.module.css";

const SendMessagesModal = ({ onClose, onConfirm, type, courseID }) => {
  const { t } = useTranslation();

  const [hasSubmittedLesson, setHasSubmittedLesson] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className={classes.sendMessagesModal}>
      <div className={classes.sendMessagesModal_header}>
        <h2 className={classes.sendMessagesModal_title}>{t("send_messages")}</h2>
        <div className={classes.sendMessagesModal_close} onClick={onClose}>
          <img src={CloseIcon} alt="close" />
        </div>
      </div>
      {loading ? <SkeletonCardOverlay /> : null}
      <Formik
        initialValues={{
          subject: "",
          body: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setLoading(true);
          setSubmitting(true);
          try {
            let data = { ...values };
            type === "course" && (data["trainee-for-course"] = courseID);
            await onConfirm(data);
          } catch (err) {
            toast.error(<span style={{ fontSize: 13, fontWeight: "bold" }}>{err.response.data.msg}</span>);
          } finally {
            setSubmitting(false);
            setLoading(false);
          }
        }}
        validateOnChange={hasSubmittedLesson}
        validate={(values) => {
          const errors = {};
          if (!values.subject) {
            errors.subject = t("crud.errors.required");
          }
          if (!values.body) {
            errors.body = t("crud.errors.required");
          }

          setHasSubmittedLesson(true);
          return errors;
        }}
      >
        {({ values, errors, touched, handleChange, setFieldValue, handleSubmit, isSubmitting }) => (
          <>
            <form onSubmit={handleSubmit}>
              <div className="form-fields">
                <div className={`${classes["sendMessagesModal__form__field"]} col-12`}>
                  <Input
                    label={t("messageTitle")}
                    placeholder={t("messageTitle")}
                    name="subject"
                    value={values.subject}
                    onChange={handleChange}
                    error={errors.subject}
                  />
                </div>
                <div className={`${classes["sendMessagesModal__form__field"]} col-12`}>
                  <Textarea
                    label={t("messageSubject")}
                    placeholder={t("messageSubject")}
                    name="body"
                    value={values.body}
                    onChange={handleChange}
                    error={errors.body}
                  />
                </div>
              </div>
              <div className={classes["sendMessagesModal-actions"]}>
                <CustomButton
                  value={t("submit")}
                  colors="#036c77"
                  type="submit"
                  disable={isSubmitting}
                  loading={isSubmitting}
                  classes={classes["sendMessagesModal-actions-btn"]}
                />
                <CustomButton
                  value={t("crud.placeholders.cancel")}
                  colors="#036c77"
                  variant="outlined"
                  type="button"
                  disable={isSubmitting}
                  loading={isSubmitting}
                  action={onClose}
                  classes={classes["sendMessagesModal-actions-btn"]}
                />
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};
export default SendMessagesModal;
