import PdfIcon from "../../../../assets/icons/pdf.svg";
import XlsIcon from "../../../../assets/icons/xls.svg";
import FilterIcon from "../../../../assets/icons/filter.svg";


export const refundActions = ({ exportPdf, exportExcel, showFilter }) => {
  const actionsList = [
    ...(showFilter
      ? [
          {
            id: "filter",
            icon: <img src={FilterIcon} alt="" />,
            action: showFilter,
          },
        ]
      : []),
    {
      id: "pdf",
      icon: <img src={PdfIcon} alt="" />,
      theme: "pdf",
      action: exportPdf,
    },
    {
      id: "xls",
      icon: <img src={XlsIcon} alt="" />,
      theme: "xls",
      action: exportExcel,
    },
  ];
  return actionsList;
};
