import { Stack, TextField, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function InputWithLabel({
	name,
	id,
	type,
	label,
	error,
	variant,
	labelColor,
	placeholder,
	gap,
	fontWeight,
	handleChange,
	values,
}) {
	const { t } = useTranslation();

	const theme = useTheme();
	return (
		<Stack
			position={"relative"}
			gap={gap || "3px"}
			width={"100%"}
			sx={{
				"& .MuiOutlinedInput-notchedOutline": {
					borderRadius: "6px",
					border: `1px solid ${theme.palette.grey[300]}}`,
				},
				"& .MuiInputBase-root": {
					height: "75px !important",
				},
			}}
		>
			<Typography
				component={"label"}
				variant={variant || "button"}
				color={labelColor || "grey.200"}
				fontWeight={fontWeight || 700}
			>
				{t(label)}
			</Typography>
			<TextField
				id={id}
				placeholder={t(placeholder) || label}
				onChange={handleChange}
				value={`${values[name]}`}
				name={name}
				type={type || "text"}
			/>
			<Typography
				component={"span"}
				variant="button"
				sx={{
					fontWeight: 400,
					textAlign: "end",
					color: "error.main",
					textTransform: "capitalize",
					position: "absolute",
					bottom: "-25px",
					right: "0",
				}}
			>
				{error}
			</Typography>
		</Stack>
	);
}
