import React, { useEffect, useState } from "react";
import { answerLessonActivity } from "../../../../../Services/api/courses/courseProvider";
import {
  launchQuestionUrl,
  launchacctivityUrl,
} from "../../../../../Services/api/exams/ExamsProvider";
import { ReactComponent as YoutubeIcon } from "../../../../../assets/icons/youtube.svg";
import SkeletonCard from "../../../../Shared/Components/Spinner/SkeletonCard";
import { toast } from "react-toastify";

export default function XApiQuestionView({ question, setFieldValue, isActivity }) {
  const [isLoading, setIsLoading] = useState(false);
  const [link, setLink] = useState(false);
  const [registration, setRegistration] = useState(
    "00" + crypto.randomUUID().slice(2)
  );

  const launch = () => {
    if (question.media?.id) {
      setIsLoading(true);
      launchacctivityUrl(question.id, registration, true)
        .then((res) => {
          setFieldValue("answer", registration);
          setLink(res.data.path);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(
            <span style={{ fontSize: 13, fontWeight: "bold" }}>
              {err.response.data?.msg ?? "Your answer to registration failed"}
            </span>
          );
        });
    }
  };

  useEffect(() => {
    launch();
  }, []);

  return (
    <>
      {question.media?.id ? (
        <>
          {isLoading && !link ? (
            <div className="tw-mx-auto">
              <div style={{ height: "644px" }}>
                <SkeletonCard />
              </div>
            </div>
          ) : (
            <iframe
              id={question.id}
              title={"XAPI"}
              src={link}
              display="flex"
              height={"644px"}
              width="100%"
            ></iframe>
          )}
        </>
      ) : (
        <button
          type="button"
          disabled={!question.media?.id}
          className="tw-border-2 tw-border-gray-200 tw-bg-gray-50 tw-w-1/2 tw-aspect-square tw-flex tw-items-center tw-justify-center"
        >
          <YoutubeIcon
            className={`tw-w-16 tw-h-16 ${
              question.media?.id ? "tw-stroke-teal-600" : "tw-stroke-gray-400"
            }`}
          />
        </button>
      )}
    </>
  );
}
