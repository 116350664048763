import {useTranslation} from "react-i18next";

function Failed(props) {
  require("./Failed.css");
  const { t } = useTranslation();

  return (
    <>
      <div className="failed">
        <h1>{t("verification.failed.title")}</h1>
        <p>{t("verification.failed.description")}</p>
      </div>
    </>
  );
}

export default Failed;
