import {Redirect, Route, Switch} from "react-router-dom";
import routesAdmin from "./../../../../routes/admins/RoutesAdmins";

function Admin(props) {
    const ticketPaths = [
        "/ticket/type/add",
        "/ticket/type/edit/:id",
        "/ticket/types",
        "/ticket/type/assign/:id",
        "/ticket/add",
        "/ticket/rate/:id",
        "/ticket/view/:id",
        "/tickets"
    ];

    return (
        <>
            <Switch>
                {routesAdmin.map((route, index) => {
                    const preferences = localStorage.getItem("system_preferences") ? JSON.parse(localStorage.getItem("system_preferences")) : {};
                    if(localStorage.getItem("system_preferences") == null ||
                        preferences.support_tickets_service ||
                        (!preferences.support_tickets_service && !ticketPaths.includes(route.path))){
                        return (
                            <Route
                                exact={route.exact}
                                path={`${props.match.path + route.path}`}
                                component={route.component}
                            />
                        )
                    }
                })}
                <Redirect to="/admin/page-not-found"/>
            </Switch>
        </>
    );

}

export default Admin;
