import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../Shared/Components/footer/footer";
import { PersonalInfoForm, ResetPassword } from "./";

function PersonalInformation() {
  require("./personalInformation.css");
  const { t } = useTranslation();

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 profiles">
            <ul className="nav nav-tabs profile ">
              <li className="active links pri_profile">
                <a data-toggle="tab" href="#personalInfo">
                  {t("personal_info")}
                </a>
              </li>
              <li className="links pri_profile margin_tabs">
                <a data-toggle="tab" href="#accountDe">
                  {t("accountde")}
                </a>
              </li>
            </ul>

            <div className="tab-content">
              <div id="personalInfo" className="tab-pane active">
                <div className="card-body">
                  <>
                    <div className="personal_info_label">
                      <div className="personal_info_top">{t("personal_info")}</div>
                      <div className="personal_info_bottom">{t("completprof")}</div>
                    </div>
                    <PersonalInfoForm />
                  </>
                </div>
              </div>
              <div id="accountDe" className="tab-pane fade">
                <div className="card-body">
                  <>
                    <div className="personal_info_label">
                      <div className="personal_info_top">{t("accountde")}</div>
                      <div className="personal_info_bottom">{t("changepass")}</div>
                    </div>
                    <ResetPassword />
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PersonalInformation;
