import React, { useEffect } from "react";
import classNames from "classnames";

// import { Handle, Remove } from "./components"

import styles from "./Item.module.css";

function MemuIcon({}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g id="grid_1_" data-name="grid (1)" transform="translate(-2 -2)">
        <rect
          id="Rectangle_100"
          data-name="Rectangle 100"
          width="7"
          height="7"
          transform="translate(3 3)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <rect
          id="Rectangle_101"
          data-name="Rectangle 101"
          width="7"
          height="7"
          transform="translate(14 3)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <rect
          id="Rectangle_102"
          data-name="Rectangle 102"
          width="7"
          height="7"
          transform="translate(14 14)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <rect
          id="Rectangle_103"
          data-name="Rectangle 103"
          width="7"
          height="7"
          transform="translate(3 14)"
          fill="none"
          stroke="#9c9c9c"
          strokeLinecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
}

export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        flagColor,
        dragOverlay = false,
        dragging = false,
        disabled = false,
        fadeIn,
        handle,
        handleProps,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting = false,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref
    ) => {
      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = "grabbing";

        return () => {
          document.body.style.cursor = "";
        };
      }, [dragOverlay]);

      return renderItem ? (
        renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          ref,
          style,
          transform,
          transition,
          value,
        })
      ) : (
        <li
          className={classNames(
            styles.Wrapper,
            fadeIn && styles.fadeIn,
            sorting && styles.sorting,
            dragOverlay && styles.dragOverlay
          )}
          style={{
            ...wrapperStyle,

            transition: [transition, wrapperStyle?.transition]
              .filter(Boolean)
              .join(", "),

            "--translate-x": transform
              ? `${Math.round(transform.x)}px`
              : undefined,

            "--translate-y": transform
              ? `${Math.round(transform.y)}px`
              : undefined,

            "--scale-x": transform?.scaleX ? `${transform.scaleX}` : undefined,

            "--scale-y": transform?.scaleY ? `${transform.scaleY}` : undefined,

            "--index": index,
            "--color": color,
          }}
          ref={ref}
        >
          <div
            style={style}
            className={`${classNames(
              styles.Item,
              dragging && styles.dragging,
              //   handle && styles.withHandle,
              dragOverlay && styles.dragOverlay,
              disabled && styles.disabled,
              color && styles.color
            )} tw-relative tw-flex tw-items-center tw-w-full tw-space-s-3 tw-h-12 tw-px-4 tw-bg-white tw-border tw-border-gray-200 tw-text-gray-400 tw-rounded tw-cursor-move`}
            data-cypress="draggable-item"
            {...(!handle ? listeners : undefined)}
            {...props}
            tabIndex={!handle ? 0 : undefined}
          >
            <div className="tw-h-4 tw-w-4">
              <MemuIcon />
            </div>
            <div>{value}</div>

            {index || index == 0 ? (
              <div className={`${flagColor ?? 'tw-bg-teal-500'} tw-text-white tw-p-1 tw-absolute tw-end-3 tw-top-0 tw-h-8 tw-aspect-square tw-text-center`}>
                {index + 1}
              </div>
            ) : (
              <></>
            )}
          </div>
        </li>
      );
    }
  )
);
