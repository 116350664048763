export const departmentListConstants = {
    // GET DEPARTMENT LIST
    GET_DEPARTMENT_LIST_REQUEST: "GET_DEPARTMENT_LIST_REQUEST",
    GET_DEPARTMENT_LIST_SUCCESS: "GET_DEPARTMENT_LIST_SUCCESS",
    GET_DEPARTMENT_LIST_REJECTED: "GET_DEPARTMENT_LIST_REJECTED",


    // UPDATE DEPARTMENT STATUS
    UPDATE_DEPARTMENT_STATUS_REQUEST: "UPDATE_DEPARTMENT_STATUS_REQUEST",
    UPDATE_DEPARTMENT_STATUS_SUCCESS: "UPDATE_DEPARTMENT_STATUS_SUCCESS",
    UPDATE_DEPARTMENT_STATUS_REJECTED: "UPDATE_DEPARTMENT_STATUS_REJECTED",
}