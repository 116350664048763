import React, {createContext, useState} from "react";

const TrainerProfileContext = createContext();

function TrainerProfileProvider(props) {
  const [trainersProfile, setTrainersProfile] = useState([]);
  const [isContentLoaded, setIsContentLoaded] = useState(false);

  return (
    <TrainerProfileContext.Provider
      value={{
        trainersProfile,
        setTrainersProfile,
        isContentLoaded,
        setIsContentLoaded,
      }}
    >
      {props.children}
    </TrainerProfileContext.Provider>
  );
}

export { TrainerProfileContext, TrainerProfileProvider };
