import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./theme";
import App from "./App";
import { store } from "./Redux/store";
import { FooterProvider } from "./Services/api/toutorials/FooterContext";
import "./assets/style/index.css";
import "./assets/style/tailwind-output.css";

ReactDOM.render(
	<BrowserRouter>
		<ThemeProvider theme={theme}>
		<CssBaseline />
		<FooterProvider>
			<Provider store={store}>
				<App />
			</Provider>
		</FooterProvider>
		</ThemeProvider>
	</BrowserRouter>,
	document.getElementById("root")
);