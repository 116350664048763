import { supportTicketsConstants } from "../../Constants/supportTickets/supportTickets.constants";

const initialState = {
  activeSupportTicketsRole: 1,
}

const supportTicketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case supportTicketsConstants.UPDATE_ACTIVE_SUPPORT_TICKET:
      return {
        ...state,
        activeSupportTicketsRole: action.payload,
      };
    default:
      return state
  }
}

export default supportTicketsReducer