import {useTranslation} from "react-i18next";
import React from "react";
import QuestionAnswerPanel from "../QuestionAnswerPanel/QuestionAnswerPanel";
import DropDownSelectedAnswers
    from "../../../../Shared/Components/Trainer/Exam/Question/Answer/DropDownSelectedAnswers/DropDownSelectedAnswers";

function SingularSelectQuestionAnswer(props) {
    require("./SingularSelectQuestionAnswer.css");
    const {t} = useTranslation();

    return (
        <QuestionAnswerPanel
            item={props.item}
        >
            <div style={{
                width: '100%',
                display:'flex',
                justifyContent:'center',
                alignItems: 'center'
            }}>
                <div style={{
                    width: '95%',
                }}>
                    <DropDownSelectedAnswers
                        answers={JSON.parse(props.item.data)}
                        selectedAnswers={[JSON.parse(props.item.answer)]}
                    />
                </div>
            </div>
        </QuestionAnswerPanel>
    )
}

export default SingularSelectQuestionAnswer;
