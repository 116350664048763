import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import {getAllCourses} from "../../../../Services/api/courses/courseProvider";
import SkeletonCard from "../../../Shared/Components/Spinner/SkeletonCard";
import {toast} from "react-toastify";
import IconTooltips from '../../../../Components/tooltips/IconsTooltip'
import SkeletonCardOverlay from "../../../Shared/Components/Spinner/SkeletonCardOverlay";
import editIcon from "../../../../assets/icons/pen.svg";

function CourseListTrainer() {
  require("./courseList.css");
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(true);
  const [courseList, setCourseList] = useState([]);
  const [isUpdating,setIsUpdating] =  useState(false)

  useEffect(() => {
    setIsUpdating(true)
    getAllCourses()
      .then((res) => {
        if (res.status && res.status == 200 && res.data.status) {
          setCourseList(res.data.courses);
          setLoading(false);
          setIsUpdating(false)
        }
      })
      .catch((err) => {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {err.response.data.msg}
          </span>
        );
      });
  }, []);

  return (
    <div className="container-fluid">
      <div className="admin_flex">
        <div className="admin_label">
          <NavLink to="/admin">{t("admin.label.admin_label")}</NavLink>
          <p style={{ padding: "0 5px" }}>/</p>
          {t("assginCourse.label.assign_course")}
        </div>
      </div>
      {isUpdating ?<SkeletonCardOverlay skeletonWidth="100"/>:<div></div>}

      {loading ? (
        <div
          style={{
            height: "65vh",
          }}
        >
          <SkeletonCard />
        </div>
      ) : (
        <div className="admin_card">
          <div className="admin_label_card">
            <div className="">{t("assginCourse.label.assign_course")}</div>
            <div className="search_input">
              <SearchIcon className="icon" />
              <input
                id="filter"
                name="filter"
                type="text"
                value={filter}
                placeholder={t("block.placeholders.name")}
                onChange={(event) => setFilter(event.target.value)}
              />
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{t("assginCourse.label.course_name")}</th>
                  <th scope="col">{t("assginCourse.label.release")}</th>
                </tr>
              </thead>
              <tbody>
                {courseList &&
                  courseList
                    .filter((c) => c.name.includes(filter) || filter === "")
                    .map((course, index) => {
                      return (
                        <tr key={course.id}>
                          <td scope="row">{index + 1}</td>
                          <td>{course.name}</td>
                          <td className="">
                            <div className="release_icon">
                              <NavLink
                                to={`/admin/course/trainer/add/${course.id}`}
                              >

                               <IconTooltips
                                    title={t('modal.coupon.buttons.edit')}
                                    content={
                                      <img src={editIcon} alt=""/>
                                      }
                                    />
                              </NavLink>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseListTrainer;
