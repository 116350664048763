import DatePicker from "react-datepicker";
import "./date-picker.css";
import classes from './search.module.css';
const SearchDateField = ({
  value,
  onChange,
  name,
  disabled,
  placeholder,
  showTime,
  canClear,
}) => {
  return (
    <div className={classes["search__field-date"]}>
      <DatePicker
        showIcon
        onChange={onChange}
        className={classes["search__field-date-input"]}
        dateFormat={showTime ? "yyyy-MM-dd h:mm aa" : "yyyy-MM-dd"}
        showTimeSelect={showTime}
        showYearDropdown
        name={name}
        selected={value}
        placeholderText={placeholder}
        disabled={disabled}
        isClearable={value && canClear}
      />
    </div>
  )
}

export default SearchDateField