import React from "react";

const env = {
  serverPath: process.env.REACT_APP_SERVER_PATH,
  baseurl: process.env.REACT_APP_BASE_URL,
  siteKey: process.env.REACT_APP_SITE_KEY,

  teams_route: "/courses/$courseId/teams",
  team_by_id: "/teams/$id",
  create_team: "/courses/$courseId/teams/create",
  edit_team: "/teams/$id/edit",
  team_status_toggle: "/teams/$id/status-toggle",
  get_course_trainees_teams: "/courses/$id/trainees/$teamId",
  // serverPath: "http://localhost:3001/",
  // baseurl: "http://localhost:3001/api",
  get_all_available_courses: "/specialty?with-published-courses=1",
  get_all_available_programs: "/specialty?with-published-programs=1",
  get_all_courses_home_id: "/contents/courses/list/$id",
  get_all_courses_home: "/contents/courses/list",
  get_all_programs_home: "/contents/programs/list",
  get_all_programs_home_id: "/contents/programs/list/$id",
  get_all_specialty: "/specialty/list",
  get_all_specialties: "/specialty",
  get_public_courses: "/my/$type",

  post: "post",
  delete: "delete",
  get: "get",
  put: "put",
  login: "/login",
  login_sso: "/loginSSO",
  login_sso_Qassem: "/trainerSSO",
  userBasic: "/user/basic",
  social_auth: "/token/exchange",
  user_data: "/user/basic",
  register: "/register/$id",
  create_new_user: "/create/user",
  logout: "/logout",
  email_verify: "/email/verify/",
  email_resend: "/email/resend",
  create_user: "/user",
  update_user: "/api/user",
  assign_role: "/api/user",
  set_active: "/active/user",
  set_inactive: "/inactive/user",
  change_pass: "/inactive/user",
  create_department: "/inactive/user",
  update_department: "/department/update/",
  assign_user: "/department/update/",
  unassign_user: "/unassign/user",
  change_mode: "/course/mode/2",
  update_price: "/course/price/2",
  update_course: "/course/2",
  ban_course: "/course/ban/post/",
  unban_course: "/course/unban/post/",
  join_course: "/course/join/",
  unassign_trainer: "/unassign/trainer",
  set_coures_obj: "/course/objective",
  update_course_obj: "/course/objective/1",
  create_class: "/classification",
  update_class: "/classification/update/1",
  delete_class: "/classification/delete/2",
  get_all_department: "/department/list",
  get_classification: "/classification/home-list",
  get_trainer: "/all/trainer",
  get_counts: "/home/counts",
  get_Ad: "/advertisements/home/list",
  get_trainer_course: "/trainer-courses/$id",
  get_courses: "/AllCourses",
  admin_get_courses: "/getcourses",
  add_to_cart: "/add-to-cart",
  add_booth_to_cart: "/add-booth-to-cart",
  remove_from_cart: "/remove-from-cart",
  cart_pay: "/cart-pay",
  approve_booth: "/booth/approve",
  reject_booth: "/booth/cancel",
  get_course_info: "/courseInfo/$id",
  list_course_categories: "/classification/list",
  list_course_departments: "/department/list",
  add_course_category: "/specialty",
  get_category: "/specialty/$id",
  get_category_new: "/department/list",
  get_department_users: "/department/users/$id",
  sync_department_users: "/department/users",
  add_course_department: "/department",
  get_department: "/getDepartment/$id",
  edit_department: "/department/update/$id",
  edit_category: "/specialty/$id",
  add_course: "/course",
  clone_course: "/course/clone/$id",
  get_courses_info: "/courses/create",
  program_payment_list: "/program/payments/$id",
  add_program_payment: "/program/payment/create",
  update_program_payment: "/program/payment/update/$id",
  delete_program_payment: "/program/payment/delete/$id",
  get_course_partners: "/course/partners/$id",
  assign_partner: "/partner/$type",
  add_chapter: "/chapter",
  edit_chapter: "/chapter/$id",
  get_trainer_courses: "/coursesTrainer",
  upload_intro_video: "intro-video/upload",
  get_intro_video: "intro-video/get",
  get_course_chapters: "/chapters/$id",
  get_chapter_subchapters: "/SubChapters/$id",
  get_subchapter_lessons: "/lessons/$id",
  get_subchapter_lesson: "/chapter/lessons/$id",
  get_exam: "/get/quiz/$id",
  get_course_objectives: "/objectives/$id",
  get_course_trainees: "/trainees/course/$id",
  get_lesson_objectives: "/objectives/lesson/$id",
  add_exam: "/add/quiz",
  edit_exam: "/update/quiz/$id",
  get_exam_grades: "/quiz/scores/$id",
  set_exam_grade: "/revision-quiz/$id",
  get_trainee_exam_answers: "/quiz/answers/$examId/$traineeId",
  get_course_trainees_quiz: "/course/trainees/quiz/$courseId",
  get_quiz_participants: "/quiz/participants/$quiz_id",
  get_assessments: "/quiz/$course_id",
  quiz_status_toggle: "/quiz/status/$id/toggle",
  get_exam_questions: "/get/quiz/$id",
  get_course_trainees_activities: "/course/trainees/activities/$courseId",
  order_exam_questions: "/quiz/order/$id",
  get_exams: "/quiz/$quizable_type/$quizable_id",
  get_question: "/question/$id",
  get_question_types: "/question/types",
  get_question_bank_items: "/question/bank/$id",
  export_question_bank: "/question/bank/export/$id",
  import_question_bank: "/question/bank/import/$id",
  export_question_bank_items: "/question/export/$id",
  export_quiz: "/quiz/export/$id",
  export_quiz_xml: "/export/quiz-xml/$id",
  add_question_to_question_bank: "/question_bank/add/question",
  edit_question_to_question_bank: "/update/question/$id",
  delete_question_to_question_bank: "/delete/question/$id",
  status_question_to_question_bank: "/statusToggle/question/$id",
  delete_exam: "/delete/quiz/$id",
  send_marks: "/quiz/resend-marks/$id",
  add_file_to_question_bank: "/question/upload-file",
  add_attachement_file_to_question: "/question/upload-option-file",
  import_question_to_question_bank: "/question/import",
  import_quiz: "/quiz/import",
  import_quiz_xml: "/import/quiz-xml",
  edit_quiz_xml: "/import/quiz-xml/$id",
  add_subchapter_lesson: "/lesson",
  run_course: "course/start/$id",
  get_course_tree: "course/chapters/lessons/$id",
  send_notification_to_gift_sender: "/notify/gift/$id/payment",
  get_course_subchapter_details: "chapter/lessons/$id",
  get_course_lesson_details: "lesson/content/$id",
  save_watching_progress: "evidence/watch",
  edit_lesson_content: "/lesson/content/$id",

  edit_lesson: "/UpdateLesson/$id",
  get_lesson_activity: "/lesson/activity/$id",
  answer_lesson_activity: "/lesson/activity/$id/answer",
  get_lesson_activity_answers: "/lesson/activity/$id/get-answers",
  list_all_coupon: "/coupon",
  add_coupon: "/coupon",
  coupon_status: "/coupon/status/$id",
  delete_coupon: "/deleteCoupon/$id",
  get_coupon_info: "/getCoupon/$id",
  get_coupon_users: "/coupon/users/$id",
  update_coupon: "/UpdateCoupon/$id",
  get_course_coupon: "/AllCourses",
  list_trainees_coupon: "/all/trainees",
  edit_course: "/course/update/$id",
  edit_course_visibility: "/course/visible/$id",
  lessons_course: "/course/chapters/lessons/$course",
  contactUs: "/ContactUs",
  get_all_users: "/search-users",
  get_roles: "/UsersRoles",
  get_sponsors_list: "/sponsor-users",
  get_partners_list: "/partner-users",
  get_program_payment_list: "/program/payments",
  get_per_trainers_list: "/course/dues",
  get_user_roles: "/UsersRoles/$id",
  get_user: "/getUser/$id",
  assign_user_role: "/assign/role",
  user_profile: "/UserProfile",
  countries: "/countries",
  cities: "/cities",
  update_profile: "/user/update",
  update_user_by_admin: "/updateUser/$id",
  get_trainer_info: "/ProfileTrainer/$id",
  get_course_details: "/courseDetails/$id",
  get_program_details: "/programDetails/$id",
  join_free_course: "/$type/join/$id",
  get_course_payment_details: "/$type/payment/details/$id",
  get_course_gift_payment_details: "/gift/$type/payment/details/$id",
  pay_course: "/$type/purchase",
  export_certificate: "/certificate/trainee/$id",
  pay_program_payments: "/program/pay/payment",
  pay_program_payments_gift: "/gift/$giftId/pay/payment/$paymentId",
  apply_coupon: "/course/$id/coupon/$coupon",
  get_trainer_category: "/trainersCategory/$id",
  get_trainer_profile: "/getProfileTrainer",
  edit_trainer_overview: "/update/Trainer/Description",
  get_lesson_content: "/lesson/content/$id",
  update_content_activity: "/lesson/content/$content",
  add_lesson_content: "/lesson/content",

  add_activity_content: "/interactive-content/lesson",
  edit_activity_content: "/interactive-contents/$id",
  delete_activity: "/interactive-contents/$id",
  get_lesson_activities: "/lesson/interactive-contents/$id",

  send_activity_response: "/activity/responses",
  approve_activity_response: "/activity/responses/approve",
  disapprove_activity_response: "/activity/responses/disapprove",
  organize_lesson_content: "/lesson/content-order/$id",
  get_bank_transfers: "/bank_transfers",
  approve_bank_transfers: "/approve-payment",
  cancel_bank_transfers: "/cancel-payment",
  admin_counters: "/counters",
  about_us: "/about-us",
  edit_about_us_admin: "/aboutus/update",
  user_list: "/user/list",
  active_users: "/active/user",
  trainer_accept: "/user/$id/status/$status",
  profile_info: "/profile-info",
  delete_department: "/department/delete/$id",
  delete_category: "/classification/delete/$id",
  add_h5p_to_lesson: "/lesson/content/h5p/$lesson",
  add_xapi_to_lesson: "/lesson/content/xapi/$lesson",
  add_scorm_to_lesson: "/lesson/content/scorm/$lesson",
  add_video_to_lesson: "/lesson/content/video/$lesson",
  add_file_to_lesson: "/lesson/content/file/$lesson",
  add_text_to_lesson: "/lesson/content/text/$lesson",
  delete_file_from_lesson: "/lesson/content/delete/$content",
  delete_activit_from_lesson: "lesson/activity/delete/$id",
  download_file_from_lesson: "/lesson/content/download/$id",
  download_folder_from_lesson: "/lesson/folder/$id",
  reorder_lesson_plans: "/lesson/$id/plans/reorder",
  get_lesson_plans: "/lesson/$id/plans",
  change_email: "/email/change",
  add_alternative_email: "user/email/alternative",
  verify_email: "/verify/$id",
  change_password: "/password/change",
  social_sign_in: "/social-redirect/$social",
  twitter_sign_in: "auth/twitter",
  list_ticket_types: "/ticketTypes",
  add_ticket_type: "/ticketTypes/create",
  store_ticket_type: "/ticketTypes",
  edit_ticket_type: "/ticketTypes/$id/edit",
  update_ticket_type: "/ticketTypes/$id",
  delete_ticket_type: "/ticketTypes/$id",
  ticket_change_status: "/ticketTypes/status/$id",
  ticket_details: "/ticket/$id",
  list_tickets: "/tickets",
  rate_ticket: "/tickets/rate/$id",
  show_tickets: "/tickets/$id",
  action_on_ticket: "/tickets/action/$id",
  add_ticket: "/tickets/create",
  ticket_subjects: "ticketType/subjects/$id",
  tickets_type_all: "ticketType/all",
  ticket_users: "ticketType/users",
  assign_to_user_data: "/ticketType/users/$id",
  create_block: "/block/create",
  all_block: "/block/all",
  info_block: "/block/info/$id",
  unblock_list: "/block/ListUnblock/$id",
  delete_block: "/block/delete/$id",
  update_block: "/block/update/$id",
  lesson_block: "/lessons/course/$id",
  course_block: "/getcourses",
  block_request: "/block/AllRequest",
  block_allowed_ignored: "/AllIgnore",
  unblock_ip: "/UnBlock/ip",
  user_block_request: "/block/request",
  block_ignore: "/block/request/ignore/$id",
  block_countries: "/block/countries",

  zoom_add_session: "/virtualClassRoom/$id",
  update_Meeting: "/virtualClassRoom/updateMeeting/$id",
  delete_Meeting: "/virtualClassRoom/deleteMeeting/$id",
  zoom_change_status: "/virtualClassRoom/meeting/$id/change-status",
  zoom_send_reminder: "/virtualClassRoom/meeting/$id/send-invitations",
  zoom_generate_report: "/virtualClassRoom/meetingReport/$id",
  zoom_Lsit_types: "/virtualClassRoom/types",
  zoom_class_room_list: "/virtualClassRoom/list/$id",
  zoom_report: "/virtualClassRoom/users-reports/$id",
  zoom_export_report: "/virtualClassRoom/users-reports/$id",

  get_enrolled_users_for_restrictions: "/restriction/enrolled/users/$id",
  get_pre_requests_lessons: "/restriction/required/lesson/$id",
  add_restriction_lesson: "/restriction/lesson/$id",
  get_restrictions: "/lesson/rules/$id",
  get_messages: "/messages/$chat_id",
  send_messages: "/messages",
  get_chats: "/chats",
  program: "/programs",
  specific_program: "/programs/$id",
  programs_payments: "/programs/payments/$id",
  add_program: "/program",
  update_program: "/program/update/$id",
  delete_program: "/program/delete/$id",
  get_preferences: "/settings",
  set_preferences: "/settings/update",
  FAQ_list: "/faq",
  import_File_To_chapter: "/import/chapter",
  import_File_To_lesson: "/import/lesson",
  import_File_To_course: "/import/course",
  export_File_To_chapter: "/export/chapter/$id",
  export_File_To_lesson: "/export/lesson/$id",
  export_File_To_course: "/export/course/$id",
  non_trainees: "/course/non-trainees/$id",
  update_trainee_status: "/course/trainee/toggle-status/$id/$course_id",
  non_trainers: "/course/trainers/$id",
  assign_trainer: "/assign/trainer/$id",
  assign_content_developer: "/assign/content-creator/$id",
  assign_trainee: "/assign/trainee/$id",
  get_trainee_reg: "/$type/trainees/$id",
  add_trainer_dues: "/course/trainer/dues",
  get_payments: "/payments",
  payment_action: "/payments/process/$id",
  FAQ_course: "/faq/course/$id",
  get_courses_coupon: "/coupon/courses/$id",
  Create_Faq_Form: "/faq/create/$id",
  Create_Faq_Form_without_course: "/faq/create",
  Edit_Faq: "/faq/$id",
  Delete_Faq: "/faq/$id",
  FAQ_add_course: "/faq/course/$id",
  send_bulk_emails: "/users/custom-email",
  type_file_list: "/typefile/list",
  type_file_Delete: "/typefile/delete/$id",
  type_file_update: "/typefile/update/$id",
  type_file_add: "/typefile/add",
  analysis: "/data/analysis",
  learning_analysis_rating_by_id: "/course-users/$id",
  learning_analysis_rating: "/course-users",
  learning_analysis_registered: "/countries-users",
  learning_analysis_registered_by_course_id: "/countries-users/$id",
  learning_analysis_statistics: "/course-statistics",
  learning_analysis_statistics_by_id: "/course-statistics/$id",
  analysisById: "/data/analysis/$id",
  analysisStatisticsById: "/quiz/statistics/$id",
  analysisChapter: "/course/chapters/$id",
  analysisLesson: "/chapters/lessons/$id",
  chapterAnalysis: "/chapter/analysis/$id",
  lessonAnalysis: "/lesson/analysis/$id",
  traineeCourseAnalysis: "/trainee-course/analysis/$userId",
  traineeCourseLessonAnalysis: "/trainee-course/analysis/$userId/$courseId",
  traineeCourseChapterAnalysis:
    "/trainee-chapter/analysis/$courseId/$chapterId",

  update_progress: "updateProgress/$id",
  logs: "activity-log",

  add_gift_to_cart: "/add-to-cart",
  list_my_gifts: "/list-my-gifts",
  list_give_gifts: "/list-sent-gifts",
  cancel_gift: "/cancel-gift",
  final_price: "/course/final-price",
  report_trainer_courses: "/report/trainer/courses",
  report_hours_trainer_courses: "/report/trainer/course-hours",
  report_trainee_courses: "/report/trainee/courses",
  report_hours_trainee_courses: "/report/trainee/course-hours",

  list_payment_gifts: "/list-sent-gifts/payments/$type",

  get_all_objective: "/objectives/$id",
  add_objective: "/course/objective",
  edit_objective: "/course/objective/$id",
  object_status: "/objective-status/$id",
  lesson_objective: "/objectives/lesson",
  get_lesson_objective: "/objectives/lesson/$id",
  export_objectives: "/objectives/$id",

  report_trainee_lessons: "/report/trainee/lessons",
  report_trainer_lessons: "/report/trainer/lessons",
  get_sponsors: "/partner/list",
  get_sponsors_data: "/partner",
  get_my_sponsors: "/my-sponsors",
  get_trainees_for_sponsor: "/partner-trainees/$id",
  search_trainee: "/partner/get-trinee",
  create_sponsor: "/partner/create",
  un_assign_trainee: "/unassign-trainee",
  assign_trainees: "/assign-trainee",
  invoice: "/invoices",
  offers: "/offer/list",
  delete_offer: "course/offer/delete/$id",
  add_offer: "/add/course/offer",
  get_offer_by_id: "/getOffer/$id",
  update_offer: "/course/offer/update/$id",
  refund_order: "/refund/$type/$id",
  refunds: "/refunds",
  get_refunds_request: "/refund/requests",
  refund_action: "/refund/process/$id",
  refund_setting: "/read-refund-setting",
  userWalletStatistics: "/wallet-statistics",

  refund_setting_list: "/refund/settings",
  refund_setting_list_id: "/refund/settings/$id",
  refund_setting_list_export: "/refund/settings/list",
  add_refund_setting: "add/refund/setting",
  get_refund_setting: "/get/refund/setting/$id",
  delete_refund_setting: "/refund/settings/$id/delete",
  status_refund_setting: "/refund/settings/$id/change-status",
  update_refund_setting: "/update/refund/setting/$id",

  chapter_interactive_content: "/chapter/interactive-contents/$id",
  add_activity_to_chapter: "/interactive-content/chapter",

  lesson_interactive_content: "/interactive-content/$id",
  lesson_add_comment: "/comments",
  lesson_delete_comment: "/comments/$id",
  lesson_update_is_published_comment: "/publish-comment/$id",
  lesson_publish_all_comment: "/publish-all-comments/$id",
  lesson_update_is_banned_comment: "/ban-comment/$id",
  lesson_ban_all_comment: "/ban-all-comments/$id",
  lesson_like_comment: "/like-comment/$id",
  lesson_unlike_comment: "/unlike-comment/$id",
  download_comment_file: "/comments/download/$id",

  publish_req: "/publish-requests/send",
  publish_req_guest: "/publish-requests/guest/send",
  publish_req_list: "/publish-requests",
  publish_req_details: "/publish-requests/$id",
  reject_req: "/publish-requests/$id/reject",
  accept_req: "/publish-requests/$id/approve",
  send_custom_email: "publish-requests/$id/send-email",

  groups: "/groups",
  groups_update: "/groups/$id",
  createNeededData_groups: "/groups/create",
  editNeededData_groups: "/groups/$id/edit",
  myGroups: "/my-groups",
  groupsForUser: "/groups/for/user/$id",

  advertisements: "/advertisements",
  advertisements_update: "/advertisements/$id",
  createNeededData_ad: "/advertisements/create",
  editNeededData_ad: "/advertisements/$id/edit",
  active_ad: "/advertisements/$id/status/active",
  deactive_ad: "/advertisements/$id/status/inactive",
  delete_ad: "/advertisements/$id",

  events: "/events",
  cancel_event: "events/$id/cancel",
  createNeededData: "/events/create",
  update_event: "events/$id",
  editNeededData: "/events/$id/edit",
  eventTypes: "/calendar/event/types",
  calender: "/calendar",

  refund_setting_update: "/update-refund-setting",
  tax: "/tax/list",
  delete_tax: "/tax/$id/delete",
  add_tax: "/tax",
  get_tax_by_id: "/tax/$id",
  update_tax: "/tax/$id",
  finanicalDashboard: "/finanical-dashboard",
  programPaymentDate: "/program-payments-reports",
  update_user_wallet: "/update-user-wallet",
  read_user_wallet: "/read-user-wallet",
  user_wallet: "/users-wallet",
  tax_status_update: "/tax/status/$id",

  department_status_update: "/department/status/$id",

  add_payment_request: "/payout",
  get_payment_request: "/my/payout",
  get_peyment_request_admin: "/payout",

  peyment_action: "/payout/process/$id",

  add_discussion: "/discussion/$id",
  get_discussion: "/discussion/$id",
  update_discussion: "/discussion/update/$id",
  get_view_discussion: "/discussion/view/$id",
  post_like_discussion: "/post/like/$id",
  post_dislike_discussion: "/post/dislike/$id",
  create_post_discussion: "/post/$id",
  delete_post_discussion: "/post/$id",
  abuse_report_discussion: "/abuse-report/$id",
  order: "/orders",
  statistics_trainer: "/report/trainer/statistics",
  virtual_class_token: "/virtual-class/token/",
  add_virtual_class_token: "/virtual-class/token",
  Update_virtual_class_token: "/virtual-class/token/$id",

  add_survey: "/survey",
  update_survey: "/survey/$id",
  update_tracking_status: "/survey/tracker/$id",
  get_survies: "/survey",
  get_survey: "/survey/$id",
  response_survey: "/survey-response/$id",
  get_survey_response: "/survey-response",
  delete_survey: "/survey/$id",
  disable_survey: "/survey/toggle/accessibility/$id",
  get_surveys_lesson_and_course: "/survey/user/available",
  get_surveys_statistics: "/survey/statistics/$id",
  get_surveys_participants: "/survey/participants/$id",
  get_public_surveys: "/survey/public",
  get_all_courses: "/courses-list",
  get_lesson_by_id: "/lessons/course/$id",

  create_exhibition: "/exhibition",
  edit_exhibition: "exhibition/$id",
  exhibitions_list: "/exhibition",
  exhibition_rate: "exhibition/rate",
  exhibitions_export: "exhibitions/export",
  exhibitions_booths_export: "exhibition/$id/booths-list",
  exhibitions_users_export: "exhibition/$id/visit",
  exhibitions_products_booth: "booth/$id/products-export",

  courses_partnar_list: "/user/$id/partnered/courses",
  programs_partnar_list: "/user/$id/partnered/programs",

  exhibitions_status: "/exhibition/$id/status",
  get_exhibition: "/exhibition/$id",
  get_exhibition_booths_bookings: "/exhibition/$id/booths",
  get_exhibition_booths_bookings_details: "/booth/$id/payment",
  exhibition_coming: "/exhibition/coming",
  exhibition: "/exhibition/list",
  exhibition_guest_list: "exhibition/list-guest",
  exhibition_visit: "/exhibition/visit",
  add_exhibition_visit_to_cart: "/add-exhibition-visit-to-cart",
  booth: "/booth",
  trainee_get_exam: "/get/trainee-quiz/$id",
  trainee_get_question: "/get/trainee-question/$id",
  trainee_join_exam: "/join/quiz/$id",
  trainee_answer: "/answer",
  trainee_get_exam_result: "quiz/my-result/$id",
  exhibition_positions: "/exhibition/positions/$id",
  exhibition_booths_list: "/exhibition/$id/booths-list",
  exhibition_sponsors: "/exhibition/$id/sponsors",
  exhibition_visitors: "/exhibition/$id/visit",
  exhibition_approve_payment_by_booth: "/booth/payment/approve",
  exhibition_reject_payment_by_booth: "/booth/payment/cancel",
  exhibition_approve_by_visitor: "/exhibition/$id/visit/approve",
  exhibition_reject_by_visitor: "/exhibition/$id/visit/cancel",
  exhibition_approve_payment_by_visitor:
    "/exhibition/$id/visit/approve-payment",
  exhibition_reject_payment_by_visitor: "/exhibition/$id/visit/cancel-payment",

  get_booth: "/booth/$id",
  get_booth_draft: "/booth-draft/$id",
  approve_draft_booth: "/booth-draft/$id/approve",
  reject_draft_booth: "/booth-draft/$id/cancel",
  update_booth: "/booth/$id",
  update_booth_draft: "/booth-draft",
  update_booth_status: "/booth/$id/status",
  get_booth_visitors: "/booth/$id/visits",
  export_booth_visitors: "/booth/$id/visits",
  export_exhibitions_visitors: "/exhibition/booth/list/$id",
  export_exhibitions_financial_report: "/booth/payments/$id",
  get_exhibitions_survey: "/exhibitions",
  get_exhibition_list_survey: "/exhibition/list",
  certificates: "/certificates",
  certificates_download: "/download/certificate/course/$id",
  program_certificate: "/program-certificate/$id",
  certificates_download_program: "/download/certificate/program/$id",
  report_trainee_programs: "/report/trainee/programs",
  get_quizzes_trainee: "/quizzes/course",
  get_trainers_rate: "/user/ratings",
  add_rate: "/user/rate",
  add_groups_to_user: "/user/$id/assign/groups",
  Add_existing_email_toGroup: "/group/assign/email",
  add_activity_rate: "lesson/activity-rate",
  add_note: "/notes/$model",
  get_course_grades_report: "/quizzes/course/grades",
  get_course_trainers_dues: "/course/trainers/dues/$id",
  edit_course_trainers_dues: "/course/trainers/dues",
  remove_question_survey: "/survey/question/$id",
  remove_choose_question_survey: "/survey/option/$id",
  get_medical_fields: "/medical-fields",
  get_specialties: "/specialty",
  get_specialties_new: "/courses/create",

  update_end_time_survey: "/survey/reopen/$id",
  get_trainers_dues: "/get/trainers/dues",
  get_exhibition_booths: "/exhibition/statistics",
  get_statistics_booths: "/booth/statistics/$id",
  get_booth_ratings: "/booth/ratings/$id",
  // get_course_analysis: "/academic-record/$courseId",
  // get_quiz_analysis: "/academic-record/$courseId/$quizId",
  // get_course_analysis: "/academic-record/$courseId",
  get_course_analysis: "/learning_analytics/course/$courseId",
  get_two_course_analysis:
    "/learning_analytics/course/$courseId/$secondCourseId",
  // get_quiz_analysis: "/academic-record/$courseId/$quizId",
  get_quiz_analysis: "/learning_analytics/course/$courseId/quiz/$quizId",
  get_trainee_analysis: "/learning_analytics/course/$courseId/trainee",
  get_trainee_analysis_by_id:
    "/learning_analytics/course/$courseId/trainee/$traineeId",
  dashboard_report: "/get/dashboard-report",
  dashboard_course_dues_report: "/get/dashboard-course-dues-report",
  get_surveys_statistics_dashboard: "/surveys/statistics",
  get_surveys_statistics_questions: "/survey/statistics/options/$id",
  add_clone_from_survey: "/survey/clone/$id",
  get_course_rate: "/course/rateings/$id",
  add_rate_course: "/course/rate",
  forgot_password: "/password/forgot",
  change_to_alternative_email: "/user/email/alternative/activate",
  update_password: "/password/reset/forgotten",
  check_password_reset_token: "/password/reset/check-token",
  alternative_email: "/email/alternative",
  get_notifications: "/get-notifications",
  read_notifications: "/notifications",
  remove_user_from_ticket: "ticketType/delete-user/$id",
  download_invoice: "/invoice/pdf/$id",
  delete_objective: "/objectives/delete/$id",
  delete_chapter: "/chapter/delete/$id",
  delete_Lesson: "/lesson/delete/$id",
  downloadTicketFile: "/tickets/download/$id",
  downloadTicketActivityFile: "/ticket/activity/$id",
  edit_terms_and_conditions: "/terms",
  get_terms_and_conditions: "/terms",

  order_details: "/order-details/$id/$userId",
  visitor_order_details: "/order-details/$id/$userId/exhibition_visit",

  faq_category: "/general-faqs-category",
  faq_category_id: "/general-faqs-category/$id",
  faq_category_change_status: "/general-faqs-category/$id/change-status",

  faq_question: "/general-faqs",
  faq_question_id: "/general-faqs/$id",
  faq_question_change_status: "/general-faqs/$id/change-status",

  faq_category_re_order: "/general-faqs-category/$id/reorder",
  faq_question_re_order: "/general-faqs/$id/reorder",

  faq_general_categories_list: "/general-faqs-categories-list",

  faq_category_ST: "/faq-category",
  faq_category_ST_id: "/faq-category/$id",
  faq_category_ST_type_list: "/faq-category/list-all-types",
  faq_category_change_status_ST: "/faq-category/$id/change-status",
  faq_category_ST_re_order: "/faq-category/$id/reorder",

  faq_add_question_ST: "/faq",
  faq_add_question_ST_id: "/faq/$id",
  faq_question_change_status_ST: "/faq/$id/change-status",
  faq_question_ST_re_order: "/faq/$id/reorder",

  faqs_list_ST: "/faqs-list",

  upload_file: "/image-upload",
  upload_multiple_files: "/multiple-upload",
  delete_file: "/delete-image",

  livechat_setting: "/live-chat/settings",
  livechat_send_message: "/live-chat/send-message",
  private_chat_send_message: "/private-chat/send-message",
  livechat_close_session: "/live-chat/session/close",
  livechat_sessions: "/live-chat/sessions",
  livechat_status: "/live-chat/status-toggle",
  livechat_autoreply: "/live-chat/autoreply-status-toggle",
  livechat_reply: "/live-chat/replies",
  support_ticket_reply: "/support-ticket/replies",
  livechat_add_edit_reply: "/live-chat/replies",
  livechat_delete_reply: "/live-chat/reply/$id",
  livechat_reply_status: "/live-chat/replies/status-toggle",
  livechat_reply_by_id: "/live-chat/reply/$id",
  livechat_standardـmessages: "/live-chat/standard-messages",
  livechat_support_users: "/live-chat/support-users",
  livechat_users_filter: "/users/filter",
  livechat_update_support_users: "/live-chat/support-users",
  livechat_support_users_status: "/live-chat/support-users/status-toggle",
  livechat_delete_support_users: "/live-chat/support-users/$id",
  livechat_support_users_by_id: "/live-chat/support-users/$id",
  livechat_schedule: "/live-chat/support-user/$id",
  livechat_session_assign: "/live-chat/session/assign",
  livechat_view_session: "/live-chat/view-session/$id",
  livechat_messages: "/messages/$id",
  livechat_private_chat_sessions: "/private-chat/sessions/$id",
  send_first_private_message: "/private-chat/send-message",
  get_trainee_chat_sessions: "/private-chat/trainee/$id",
  evaluation_get: "/evaluation-standard",
  evaluation_change_status: "/evaluation-standard/$id/change-status",
  evaluation_actions: "/evaluation-standard",
  evaluation_total_weights: "/standards/total-weights",
  courses_list: "/courses/$role",
  export_one_program: "/program-export/$id",
  export_one_course: "/course-export/$id",
  partner_courses_list: "/my/partnered/$type",
  all_courses_list: "/courses",
  delete_course: "/course/delete/$id",
  update_course_status: "/course/status/$id",
  update_course_visibility: "/course/visible/$id",
  update_specialty_status: "/specialty/status/$id",
  update_department_status: "/department/status/$id",
  all_courses: "/courses",

  peyment_programs_setting: "/programs/payments/$id",

  get_offers: "/offer",
  get_offers_by_id: "/offer/$id",
  offer_status: "/offer/$id/change-status",
  delete_offers: "/offer/delete/$id",
  offer_export: "/offer/list",
  search_data: "/search-form",
  search_data_tab: "/search-form/$tab",
  search: "/search",
  launch_question: "/quiz/question/launch/$id",
  launch_content: "lesson/content/launch/$id",
  saved_searches: "/saved-searches",
  saved_search_details: "/saved-searches/$id/generate-report",
  update_saved_search: "/saved-searches/$id",
  free_search: "/free-search",
  user_wallet_id: "/user/wallet/$id",
  users_wallet: "/user/wallet",
  wallet_credit: "/user/wallet-credit",
  payment_statistics: "/payment-statistics",
  certificate_templates: "/certificate-templates",
  background_image_template: "/background/upload",
  certificate_templates_id: "/certificate-templates/$id",
  update_certificate_templates_status: "/certificate-template/status/$id",
  assign_certificate: "/template/assign",
  certificates_id: "/certificates/$id",
  certificate_template_preview: "certificate-template/preview/$id",
};

export default env;
