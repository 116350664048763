import React from "react";
import {useTranslation} from "react-i18next";

function DeleteModal(props) {
    require("./DeleteModal.css");
    const {t} = useTranslation();
    return (
        <>
            <div
                className={"tajah-basic-modal-md"}
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <div className={"tajah-basic-modal-header"}>
                    <h4>{props.title}</h4>
                    <div>
                        <i
                            className="fas fa-times tajah-modal-dismiss"
                            onClick={() => {
                                props.deleteModalRef.current.dismissModal();
                            }}
                        ></i>
                    </div>
                </div>

                <div className={"tajah-basic-modal-body"}>
                    <h5>{props.deleteMsg}</h5>
                </div>
                <div className={"tajah-basic-modal-footer"}>
                    <button
                        style={{
                            marginLeft: document.body.dir == "rtl" ? 10 : 0,
                            marginRight: document.body.dir == "ltr" ? 10 : 0,
                        }}
                        className={"btn btn-danger"}
                        onClick={() => {
                            props.accept();
                            props.deleteModalRef.current.dismissModal();
                        }}
                    >
                        {props.label}
                    </button>
                    <button
                        type={"button"}
                        onClick={() => {
                            props.deleteModalRef.current.dismissModal();
                        }}
                        className={"btn"}
                    >
                        {t("modal.common.cancel")}
                    </button>
                </div>
            </div>
        </>
    );
}

export default DeleteModal;
