import {useTranslation} from "react-i18next";
import QuestionAnswerPanel from "../QuestionAnswerPanel/QuestionAnswerPanel";
import React from "react";

function HotSpotQuestionAnswer(props) {
    require("./HotSpotQuestionAnswer.css");

    const {t} = useTranslation();

    return (
        <>
            <QuestionAnswerPanel
                item={props.item}
            >
            </QuestionAnswerPanel>
        </>
    )
}

export default HotSpotQuestionAnswer;