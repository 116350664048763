import env from "./Environment";
import { exportApi } from "./Essential";
import connector from "./Handler";
import URLBuilder from "./UrlBuilder";

export function downloadCommentFile(id, filename) {
  const myPromise = new Promise((resolve, reject) => {
    try {
      connector
        .get(URLBuilder(env.download_comment_file, { id: id }), {
          responseType: "blob",
        })
        .then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            resolve();
          },
          (error) => {
            reject(error);
          }
        );
    } catch (error) {
      reject(error);
    }
  });

  return myPromise;
}
