import React from "react";
import {useTranslation} from "react-i18next";
import trackIcon from "../../../assets/image/Group 50.png";
import icon from "../../../assets/image/Icon.png";

function Tracks() {
  require("./tracks.css");
  const { t } = useTranslation();

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="tracks">
            <div className="head_box">
              <div className="head_lable">{t("Educationaltracks")}</div>

              <div className="head_view">
                <div className="">{t("ViewAll")}</div>
                <div className="arrow_seeall">
                  <img src={icon} alt="" className="arrow_icon" />
                </div>
              </div>
            </div>

            <div className="tracks_grid">
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />
                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>

              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>

              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
              <div className="tracks_card">
                <img src={trackIcon} alt="" className="tracks_icon" />

                <div className="tracks_section_name">إسم القسم هنا</div>
                <div className="tracks_count">عدد الدورات 36 k</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tracks;
