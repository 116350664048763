import React from "react";
import CourseOutlineHeader from "../courseOutlineHeader/courseOutlineHeader";
import Footer from "../../Shared/Components/footer/footer";
import CourseOutlineContent from "./courseOutlineContent/courseOutlineContent";

function CourseOutline(props) {
  return (
    <div>
      <CourseOutlineHeader />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="training_first_label">
              تاريخ بدأ الدورة 20/10/2021
            </div>

            <CourseOutlineContent />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CourseOutline;
