import { Checkbox } from "@mui/material"
import { useTranslation } from "react-i18next"
import SearchActions from "./SearchActions"
import SearchDateField from "./SearchDateField"
import SearchInput from "./SearchInput"
import SearchRadioDate from "./SearchRadioDate"
import SearchSelect from "./SearchSelect"
import SearchTabs from "./SearchTabs"
import classes from "./search.module.css"
const SearchBox = ({ activeSearchList, updateFieldValues,updateFieldArrayValues,resetFieldArrayValues, buttons }) => {
  const { t, i18n } = useTranslation();

  const displayFields = (id, field) => {
    switch (field.type) {
      case "text":
        return (
          <SearchInput
            name={field.name}
            placeholder={field.label}
            type={"text"}
            value={field.activeValue}
            onChange={(value) => {
              updateFieldValues(id, value, "activeValue")
            }}
            disabled={!field.available_for_filter}
            canDelete={true}
            deleteHandler={() => {
              updateFieldValues(id, "", "activeValue")
            }}
          />
        )
      case "radio":
        const childs = [];
        if (field?.values?.length) {
          field.values.forEach((item) => {
            childs.push({
              value: item.value,
              text: item.text,
              is_percentage: item?.is_percentage,
              filter: item?.filter,
              differenceFilter: !field.filter,
              is_array: item?.is_array || false
            })
          })
          if (field?.not_specified_option) {
            childs.push({
              value: "not-specified",
              text: t("search.not-specified"),
              is_array: false
            })
          }
        }
        return (
          <SearchTabs
            tabs={childs}
            size={"small"}
            activeTab={field.activeValue}
            onChange={(tab, filter,is_array) => {
              updateFieldValues(id, is_array ? (tab)?.toString()?.split(",") : tab, "activeValue");
              if (filter) {
               
                updateFieldValues(id, null, "radioFilter");
                updateFieldValues(id, filter, "radioFilter");
              }
            }}
            disabled={!field.available_for_filter}
          />
        )
      case "date":
      case "datetime":
        return (
          <SearchDateField
            value={field.activeValue}
            placeholder={field.label}
            onChange={(date) => {
              updateFieldValues(id, date, "activeValue")
            }}
            disabled={!field.available_for_filter}
            showTime={field.type === "datetime"}
            canClear={true}
          />
        )
      case "number":
        if (field?.childs && Object.keys(field?.childs).length) {
          const childs = [];
          Object.entries(field.childs).forEach(([key, value]) => {
            childs.push({
              value: value.filter,
              text: value.label,
              is_percentage: value?.is_percentage
            })
          })
          if (field?.not_specified_option) {
            childs.push({
              value: "not-specified",
              text: t("search.not-specified")
            })
          }
          return (
            <SearchTabs
              tabs={childs}
              size={"small"}
              hasInput={true}
              inputType={"number"}
              onChange={(tab) => {
                updateFieldValues(id, tab, "activeRadio")
                updateFieldValues(id, field.activeValue, "activeValue")
              }}
              onInputChange={(value, tab) => {
                if(field.activeValue === value) {
                  updateFieldValues(id, "", "activeValue")
                }
                updateFieldValues(id, value, "activeValue")
                if (field.activeRadio !== tab) {
                  updateFieldValues(id, tab, "activeRadio")
                }
              }}
              activeTab={field.activeRadio}
              inputVale={field.activeValue}
              disabled={!field.available_for_filter}
            />
          )
        }
        return (
          <SearchInput
            name={field.name}
            placeholder={field.label}
            onChange={(value) => {
              updateFieldValues(id, value, "activeValue")
            }}
            type={"number"}
            value={field.activeValue}
            disabled={!field.available_for_filter}
          />
        )
      case "select":
        const resetOption = {
          value: null,
          text: t("search.not-specified")
        }
        
        let selectOptions = [resetOption,...field.values];

        return (
          <SearchSelect
            options={selectOptions}
            placeholder={field.label}
            onChange={(value) => {
              updateFieldValues(id, value, "activeValue")
            }}
            value={field.activeValue}
            disabled={!field.available_for_filter}

          />
        )
      case "radio_date":
        const dateList = [];
        Object.entries(field.childs).forEach(([key, value]) => {
          dateList.push({
            value: key,
            filter: value.filter,
            text: value.label,
            type: value.type,
            ...(value?.filters && { filters: value.filters })
          })
        })
        return (
          <SearchRadioDate
            tabs={dateList}
            size={"small"}
            disabled={!field.available_for_filter}
            onRadioChange={(filter,tab) => {
              updateFieldValues(id, filter, "radioFilter")
              updateFieldValues(id, tab, "activeValue")
            }}
            dateValue={field.activeValue}
            dateArrayValues={field.activeArrayValue}
            onDateChange={(filter,date,type) => {
              if(type === "custom-date") {
                updateFieldArrayValues(id, {
                  filter: filter,
                  value: date,
                }, "activeArrayValue")
                updateFieldValues(id, "", "radioFilter")
                updateFieldValues(id, "", "activeValue")
              } else {
                updateFieldValues(id, date, "activeValue")
                // updateFieldValues(id, filter, "radioFilter")
                resetFieldArrayValues(id, "activeArrayValue")
              }
            }}
            resetFieldArrayValues={() => {
              resetFieldArrayValues(id, "activeArrayValue")
            }}
            activeTab={field.activeRadioDate}
            onRadioValueChange={(tab) => {
              updateFieldValues(id, tab, "activeRadioDate")
            }}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className={classes["search__box"]}>
      <div className={classes["search__box__list"]}>
        {activeSearchList.map((item) => (
          <div className={classes["search__box__item"]}>
            <div className={classes["search__box__item-status"]}>
              <Checkbox
                onChange={(e) => {
                  updateFieldValues(item?.id, e.target.checked, "available_for_column")
                }}
                checked={item?.field?.available_for_column}
                size="small"
                sx={{
                  padding: "0.5rem",
                  '&.Mui-checked': {
                    color: "#24b3b9"
                  },
                }}
               
              />
            </div>
            <div className={classes["search__box__item-fields"]}>
              <div className={classes["search__box__item-label"]}>{item?.field?.label}</div>
              {displayFields(item?.id, item?.field)}
            </div>
          </div>
        ))}
      </div>
      <SearchActions buttons={buttons} />
    </div>
  )
}

export default SearchBox