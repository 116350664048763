import React, {useState} from "react";

export const ModalProviderContext = React.createContext();

export function ModalContextProvider(props) {
  const [showModal, setShowModal] = useState(false);
  const [children, setChildren] = useState(<></>);

  return (
    <ModalProviderContext.Provider
      value={{
        showModal,
        setShowModal,
        children,
        setChildren,
      }}
    >
      {props.children}
    </ModalProviderContext.Provider>
  );
}
