import env from "../../Environment";
import connector from "../../Handler";
import URLBuilder from "../../UrlBuilder";

export function getCourses() {
    const myPromise = new Promise((resolve, reject) => {        
        try {
            connector                
                .get(env.get_courses)
                .then((res) => {
                    resolve(res);                    
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });
    return myPromise;
}
export function getCourseTrainersList(id) {
    const myPromise = new Promise((resolve, reject) => {        
        try {
            connector                
                .get(URLBuilder(env.get_course_trainers_dues, { id: id }))
                .then((res) => {
                    resolve(res);                    
                })
                .catch((err) => {
                    reject(err);
                });
        } catch (error) {
            reject(error);
        }
    });
    return myPromise;
}

export function EditTrainerDues(payload) {    
    const myPromise = new Promise((resolve, reject) => {
      try {
        connector
          .post(env.edit_course_trainers_dues, payload)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      } catch (error) {
        reject(error);
      }
    });
  
    return myPromise;
  }