export const blockConstants = {
  // GET ALL BLOCKS
  GET_ALL_BLOCKS_REQUEST: "GET_ALL_BLOCKS_REQUEST",
  GET_ALL_BLOCKS_SUCCESS: "GET_ALL_BLOCKS_SUCCESS",
  GET_ALL_BLOCKS_REJECTED: "GET_ALL_BLOCKS_REJECTED",

  // DELETE BLOCK BY ID
  DELETE_BLOCK_BY_ID_REQUEST: "DELETE_BLOCK_BY_ID_REQUEST",
  DELETE_BLOCK_BY_ID_SUCCESS: "DELETE_BLOCK_BY_ID_SUCCESS",
  DELETE_BLOCK_BY_ID_REJECTED: "DELETE_BLOCK_BY_ID_REJECTED",

  // GET COUNTRIES
  GET_COUNTRIES_REQUEST: "GET_COUNTRIES_REQUEST",
  GET_COUNTRIES_SUCCESS: "GET_COUNTRIES_SUCCESS",
  GET_COUNTRIES_REJECTED: "GET_COUNTRIES_REJECTED",

  // UNBLOCK REQUEST BY IP
  UNBLOCK_REQUEST_BY_IP_REQUEST: "UNBLOCK_REQUEST_BY_IP_REQUEST",
  UNBLOCK_REQUEST_BY_IP_SUCCESS: "UNBLOCK_REQUEST_BY_IP_SUCCESS",
  UNBLOCK_REQUEST_BY_IP_REJECTED: "UNBLOCK_REQUEST_BY_IP_REJECTED",
};
