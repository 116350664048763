function CourseCardSpinner(){
    return (
        <>
            <div className="courses_card_courses_spinner">
                <div
                    className="skeleton-box"
                    style={{ width: "100%", height: "100%" }}
                ></div>
            </div>
        </>
    )
}

export default CourseCardSpinner;