import { percentageTrainersListConstant } from "../../Constants/PercentageTrainersList/percentageTrainersList.constant";

const initialState = {
  percentageTrainersList: [],
  percentageTrainersListMeta: {},
  percentageTrainersListLoading: false,
  percentageTrainersListError: null,
  percentageTrainersListSuccess: false,
};

const percentageTrainersListReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET SYSTEM USERS LIST
    case percentageTrainersListConstant.GET_PERCENTAGE_TRAINERS_LIST_REQUEST:
      return {
        ...state,
        percentageTrainersListLoading: true,
      };
    case percentageTrainersListConstant.GET_PERCENTAGE_TRAINERS_LIST_SUCCESS:
      return {
        ...state,
        percentageTrainersListLoading: false,
        percentageTrainersListSuccess: true,
        percentageTrainersList: action.payload.percentageTrainersList.dues,
        percentageTrainersListMeta: action.payload.percentageTrainersList.meta,
      };
    case percentageTrainersListConstant.GET_PERCENTAGE_TRAINERS_LIST_REJECTED:
      return {
        ...state,
        percentageTrainersListLoading: false,
        percentageTrainersListError: action.payload,
        percentageTrainersListSuccess: false,
      };

    default:
      return state;
  }
};

export default percentageTrainersListReducer;
