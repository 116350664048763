import { useTranslation } from "react-i18next";
import FilterBox from "../../../Shared/Components/FilterBox/FilterBox";

const PaymentRequestsCoursesProgramsFilter = ({
  filterData,
  changeHandler,
  resetHandler,
  resetFilterHandler,
  currentPage,
  setCurrentPage,
  filterHandler,
  activeTab,
  courses,
  programs,
  isProgram,
}) => {
  const { t, i18n } = useTranslation();

  const roles = [
    {
      id: 1,
      value: 1,
      name: t("selection.role.admin"),
    },
    {
      id: 2,
      value: 2,
      name: t("selection.role.manager"),
    },
    {
      id: 3,
      value: 3,
      name: t("selection.role.trainer"),
    },
    {
      id: 4,
      value: 4,
      name: t("selection.role.trainee"),
    },
    {
      id: 5,
      value: 5,
      name: t("selection.role.accountant"),
    },
    {
      id: 6,
      value: 6,
      name: t("selection.role.partner"),
    },
    {
      id: 7,
      value: 7,
      name: t("selection.role.sponser"),
    },
  ];

  const filterFields = [
    {
      id: "user_name",
      component: "input",
      label: t("financial.payments_request.username"),
      type: "text",
      placeholder: t("financial.payments_request.username"),
      name: "user_name",
      value: filterData.user_name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },
    {
      id: "email",
      component: "input",
      label: t("general.email"),
      type: "text",
      placeholder: t("general.email"),
      name: "email",
      value: filterData.email,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "role",
      label: t("financial.payments_request.user_role"),
      component: "select",
      placeholder: t("financial.payments_request.user_role"),
      initialValue: t("financial.payments_request.user_role"),
      name: "role",
      value: filterData.role,
      classes: isProgram
        ? "col-12 col-md-6 col-xl-2"
        : "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: roles?.map((role) => ({
        id: role.id,
        value: role.id,
        label: role.name,
      })),
    },

    ...(isProgram
      ? [
          {
            id: "program_id",
            label: t("financial.payments_request.program_title"),
            component: "select",
            placeholder: t("financial.payments_request.program_title"),
            initialValue: t("financial.payments_request.program_title"),
            name: "program_id",
            value: filterData.program_id,
            classes: "col-12 col-md-6 col-xl-2",
            onchange: changeHandler,
            options: programs?.length
              ? programs?.map((program) => ({
                  id: program.id,
                  value: program.id,
                  label: program.name,
                }))
              : [],
          },

          {
            id: "program_code",
            component: "input",
            label: t("financial.payments_request.program_code"),
            type: "text",
            placeholder: t("financial.payments_request.program_code"),
            name: "program_code",
            value: filterData.program_code,
            classes: "col-12 col-md-6 col-xl-4",
            onchange: changeHandler,
            onReset: resetHandler,
          },
        ]
      : []),
    {
      id: "course_id",
      label: t("financial.payments_request.course_title"),
      component: "select",
      placeholder: t("financial.payments_request.course_title"),
      initialValue: t("financial.payments_request.course_title"),
      name: "course_id",
      value: filterData.course_id,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      options: courses?.length
        ? courses?.map((course) => ({
            id: course.id,
            value: course.id,
            label: course.name,
          }))
        : [],
    },
    {
      id: "course_code",
      component: "input",
      label: t("financial.payments_request.course_code"),
      type: "text",
      placeholder: t("financial.payments_request.course_code"),
      name: "course_code",
      value: filterData.course_code,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
      onReset: resetHandler,
    },

    {
      id: "run_serial_number",
      label: t("financial.payments_request.course_run_serial_number"),
      component: "input",
      placeholder: t("financial.payments_request.course_run_serial_number"),
      name: "run_serial_number",
      value: filterData.run_serial_number,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
    },
    {
      id: "processor_name",
      label: t("financial.payments_request.action_by"),
      component: "input",
      placeholder: t("financial.payments_request.action_by"),
      name: "processor_name",
      value: filterData.processor_name,
      classes: "col-12 col-md-6 col-xl-4",
      onchange: changeHandler,
    },

    {
      id: "reset",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.reset"),
      classes: "col-12 col-md-6 col-xl-2 border-button",
      action: () => {
        resetFilterHandler();
      },
      backgroundColor: "transparent",
      color: "#046c77",
      borderColor: "#046c77",
    },
    {
      id: "action",
      component: "button",
      type: "button",
      name: "action",
      value: t("general.search"),
      classes: "col-12 col-md-6  col-xl-2",
      action: () => {
        if (currentPage != 1) {
          setCurrentPage(1);
        } else {
          filterHandler();
        }
      },
    },
  ];

  return <FilterBox fields={filterFields} />;
};

export default PaymentRequestsCoursesProgramsFilter;
