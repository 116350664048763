import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import QuestionDraggableContext from "../../../Shared/Components/Trainer/Exam/Question/OrderingQuestion/DraggableContext/DraggableContext";
import { NavLink } from "react-router-dom";
import {
  getExamQuestions,
  orderExamQuestions,
} from "../../../../Services/api/exams/ExamsProvider";
import { toast } from "react-toastify";

function SortExamQuestions(props) {
  require("./SortExamQuestions.css");
  const { t } = useTranslation();
  const { id, quizable_id, exam_id } = useParams();
  const history = useHistory();

  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchExamQuestions = () => {
    const myPromise = new Promise(async (resolve, reject) => {
      try {
        let response = await getExamQuestions(exam_id);
        setQuestions(
          response.data.quiz.questions.map((item) => {
            return {
              id: item.id,
              name: item.title,
            };
          })
        );
        setIsLoading(false);
        resolve();
      } catch (e) {
        toast.error(
          <span style={{ fontSize: 13, fontWeight: "bold" }}>
            {t("failed_fetching")}
          </span>
        );
        reject();
      }
    });

    return myPromise;
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const payload = {
        questions: questions.map((item, index) => {
          return {
            question_id: item.id,
            order: index,
          };
        }),
      };
      await orderExamQuestions(exam_id, payload);
      toast.success(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("success_sorting")}
        </span>
      );
      history.push(
        `/trainer/course/${id}/${props.quizable}/${quizable_id}/assessments`
      );
    } catch (e) {
      setIsSubmitting(false);
      toast.error(
        <span style={{ fontSize: 13, fontWeight: "bold" }}>
          {t("failed_sorting")}
        </span>
      );
    }
  };

  useEffect(() => {
    fetchExamQuestions();
  }, []);

  return (
    <>
      <div className={"sorting-questions-canvas"}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div className="admin_label" style={{ marginBottom: 30 }}>
            <NavLink
              to={`/trainer/course/${id}/${props.quizable}/${quizable_id}/assessments`}
            >
              {t("trainer.quiz.exams")}
            </NavLink>
            <p style={{ padding: "0 5px" }}>/</p>
            {t("trainer.quiz.sorting_questions")}
          </div>
        </div>
        <Paper
          variant={"outlined"}
          sx={{
            width: "100%",
            boxShadow: "2px 2px 10px #ddd",
            height: isLoading ? 500 : "auto",
          }}
        >
          {isLoading ? (
            <div className={"tajah-question-progress"}>
              <CircularProgress />
            </div>
          ) : (
            <div
              className={"tajah-form-container"}
              style={{
                boxShadow: "none",
                borderRadius: 0,
              }}
            >
              <div className={"tajah-pre-table-header add_margin_bottom"}>
                <span>{t("trainer.quiz.questions")}</span>
              </div>

              <div className={"tajah-form-row"}>
                <div className={"tajah-form-row-element-v2"}>
                  <div
                    className={"tajah-form-row-element-holder"}
                    style={{ width: "95%" }}
                  >
                    <QuestionDraggableContext
                      options={questions}
                      setOptions={(newQuestions) => {
                        setQuestions(newQuestions);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={"tajah-form-row"}>
                <div className={"tajah-form-row-element-v2"}>
                  <div
                    className={"tajah-form-row-element-holder"}
                    style={{ width: "95%" }}
                  >
                    <button
                      type={"button"}
                      onClick={handleSubmit}
                      className="admin_add_button"
                      disabled={isSubmitting}
                    >
                      {t("crud.placeholders.save")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Paper>
      </div>
    </>
  );
}

export default SortExamQuestions;
