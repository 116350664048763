import {H5P} from "h5p-standalone";
import {useEffect} from "react";

function H5PElement(props) {
  require("./h5pElement.css");
  useEffect(() => {
    var el = document.getElementById("h5p-container");
    if(el.innerHTML!="")
    el.innerHTML=""
    var options = {
      h5pJsonPath: props.link,
      frameJs: process.env.REACT_APP_h5p_frame_bundle_js_url,
      frameCss: process.env.REACT_APP_h5p_css_url,
    };
    new H5P(el, options);
  });
  return <div id={"h5p-container"}></div>;
}
export default H5PElement;
