import env from "../../Services/api/Environment";

const url = [
  [env.action_on_ticket, env.post],

  [env.add_chapter, env.post],
  [env.lessons_course, env.get],
  [env.add_subchapter_lesson, env.post],
  [env.get_course_tree,env.get],
  ["/lesson/content", env.post],
  ["/lesson/content/$id", env.get],
  // ["/survey", env.get],
  ["/getcourses", env.get],
  ["/bank_transfers", env.get],
  ["/course/final-price", env.post],
  ["/getcourses", env.get],
  ["/getProfileTrainer", env.get],
  ["/update/quiz/$id", env.post],
  ["/get/quiz/$id", env.get],
  ["/bank_transfers", env.get],
  ["/approve-payment", env.post],
  ["/bank_transfers", env.get],
  ["/add-to-cart", env.post],
  ["/cart-pay", env.post],
];

export default url;
