import { blockConstants } from "../../Constants/block/block.constants";

const initialState = {
  // GET ALL BLOCKS
  allBlocksLoading: false,
  allBlocks: [],
  allBlocksMeta: {},
  allBlocksError: null,
  allBlocksSuccess: false,
  allBlocksErrorMessage: null,

  // DELETE BLOCK BY ID
  deleteBlockByIdLoading: false,
  deleteBlockByIdSuccess: false,
  deleteBlockByIdError: false,
  deleteBlockByIdMessage: null,
  deleteBlockByIdErrorMessage: null,

  // GET COUNTRIES
  countriesLoading: false,
  countries: [],
  countriesError: false,
  countriesSuccess: false,
  countriesErrorMessage: null,

  // UNBLOCK REQUEST BY IP
  unblockRequestByIpLoading: false,
  unblockRequestByIpSuccess: false,
  unblockRequestByIpError: false,
  unblockRequestByIpErrorMessage: null,
  unblockRequestByIpSuccessMessage: null,
};

const blockReducer = (state = initialState, action) => {
  switch (action.type) {
    // GET ALL BLOCKS
    case blockConstants.GET_ALL_BLOCKS_REQUEST:
      return {
        ...state,
        allBlocksLoading: true,
      };
    case blockConstants.GET_ALL_BLOCKS_SUCCESS:
      return {
        ...state,
        allBlocksLoading: false,
        allBlocks: action.payload.list,
        allBlocksMeta: action.payload.meta,
        allBlocksSuccess: true,
      };
    case blockConstants.GET_ALL_BLOCKS_REJECTED:
      return {
        ...state,
        allBlocksLoading: false,
        allBlocksError: action.payload,
        allBlocksSuccess: false,
        allBlocksErrorMessage: action.payload,
      };

    // DELETE BLOCK BY ID
    case blockConstants.DELETE_BLOCK_BY_ID_REQUEST:
      return {
        ...state,
        deleteBlockByIdLoading: true,
      };
    case blockConstants.DELETE_BLOCK_BY_ID_SUCCESS:
      return {
        ...state,
        deleteBlockByIdLoading: false,
        deleteBlockByIdError: false,
        deleteBlockByIdSuccess: true,
        deleteBlockByIdMessage: action.payload.message,
        allBlocks: state.allBlocks.filter((item) => item.id !== action.payload.id),
      };
    case blockConstants.DELETE_BLOCK_BY_ID_REJECTED:
      return {
        ...state,
        deleteBlockByIdLoading: false,
        deleteBlockByIdError: true,
        deleteBlockByIdSuccess: false,
        deleteBlockByIdErrorMessage: action.payload,
      };
    // GET COUNTRIES
    case blockConstants.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        countriesLoading: true,
      };
    case blockConstants.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countriesLoading: false,

        countries: Object.keys(action.payload).map((key) => ({
          id: key,
          value: action.payload[key],
          label: action.payload[key],
        })),

        countriesError: false,
        countriesSuccess: true,
      };
    case blockConstants.GET_COUNTRIES_REJECTED:
      return {
        ...state,
        countriesLoading: false,
        countriesError: true,
        countriesSuccess: false,
        countriesErrorMessage: action.payload,
      };

    // UNBLOCK REQUEST BY IP
    case blockConstants.UNBLOCK_REQUEST_BY_IP_REQUEST:
      return {
        ...state,
        unblockRequestByIpLoading: true,
      };
    case blockConstants.UNBLOCK_REQUEST_BY_IP_SUCCESS:
      return {
        ...state,
        unblockRequestByIpLoading: false,
        unblockRequestByIpSuccess: true,
        unblockRequestByIpSuccessMessage: action.payload,
      };
    case blockConstants.UNBLOCK_REQUEST_BY_IP_REJECTED:
      return {
        ...state,
        unblockRequestByIpLoading: false,
        unblockRequestByIpError: true,
        unblockRequestByIpErrorMessage: action.payload,
      };

    default:
      return state;
  }
};

export default blockReducer;
