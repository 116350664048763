import {Formik} from "formik";
import FileSection from "../FileSection/FileSection";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {AddQuestionToQuestionBankContext} from "../../../../../../../Providers/AddQuestionToQuestionBankProvider";
import {TrainerAddExamContext} from "../../../../../../../Providers/TrainerAddExamProvider";
import Select from "react-select";
import QuestionTypeEnum from "../../../../../../../Enums/QuestionTypeEnum";

function EssayQuestion(props) {
    require("./EssayQuestion.css");

    const {t} = useTranslation();
    const wrapperContext = useContext(props.renderedAs === 'questionBank' ? AddQuestionToQuestionBankContext : TrainerAddExamContext);

    const levels = [
      {
        id: 1,
        name: t("trainer.quiz.crud.easy"),
      },
      {
        id: 2,
        name: t("trainer.quiz.crud.medium"),
      },
      {
        id: 3,
        name: t("trainer.quiz.crud.hard"),
      },
    ];

    return (
        <>
            <Formik
                initialValues={{
                    title: wrapperContext.isEdit ? wrapperContext.question.title : "",
                    text: wrapperContext.isEdit ? wrapperContext.question.text : "",
                    degree: wrapperContext.isEdit ? wrapperContext.question.degree : "",
                    level: wrapperContext.isEdit ? wrapperContext.question.level : "",
                    media_id: wrapperContext.isEdit && wrapperContext.question.media && wrapperContext.question.media ? wrapperContext.question.media.id : "",
                    feedback: wrapperContext.isEdit ? wrapperContext.question.feedback : "",
                    course_objectives: wrapperContext.isEdit ? wrapperContext.question.course_objectives : [],
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    let payload = {
                        title: values.title,
                        text: values.text,
                        degree: values.degree,
                        level: values.level,
                        media_id: values.media_id,
                        feedback: values.feedback,
                        course_objectives: values.course_objectives,
                    }

                    try{
                        await props.onSubmit(payload);
                        setSubmitting(false);
                    }catch (err) {
                        setSubmitting(false);
                    }
                }}
                validateOnChange={false}
                validate={(values) => {
                    const errors = {};

                    if (!values.title) {
                        errors.title = t("crud.errors.required");
                    }

                    if (!values.text) {
                        errors.text = t("crud.errors.required");
                    }

                    if (!values.degree) {
                        errors.degree = t("crud.errors.required");
                    }

                    if (!values.level) {
                      errors.level = t("crud.errors.required");
                    }

                    if (!Array.isArray(values.course_objectives) || values.course_objectives.length == 0) {
                        errors.course_objectives = t("crud.errors.required");
                    }

                    return errors;
                }}
            >
                {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      setErrors,
                      setSubmitting
                  }) => (
                    <form onSubmit={handleSubmit} className="admin_add_form">
                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <label htmlFor="title" className="admin_add_label">
                                        {t("trainer.quiz.crud.question_title")}
                                    </label>
                                    <input
                                        value={values.title}
                                        onChange={(elem) =>
                                            setFieldValue('title',elem.target.value)
                                        }
                                        placeholder={t(
                                            "trainer.quiz.crud.input.question_title"
                                        )}
                                        className="admin_filter_input"
                                    />
                                    <p className={"form-input-error-space"}>
                                        {errors.title ? errors.title : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <FileSection
                                        mediaId={values.media_id}
                                        setMediaId={(mediaId) => {
                                            setFieldValue('media_id', mediaId);
                                        }}
                                        setSubmitting={setSubmitting}
                                        typeId={QuestionTypeEnum.essay_question}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <label htmlFor="text" className="admin_add_label">
                                        {t("trainer.quiz.crud.ideal_answer")}
                                    </label>
                                    <textarea
                                        id="text"
                                        name="text"
                                        rows="7"
                                        className="tajah-bordered-text-area"
                                        onChange={handleChange}
                                        placeholder={t("trainer.quiz.crud.input.ideal_answer")}
                                        value={values.text}
                                    />
                                    <p className={"form-input-error-space"}>
                                        {errors.text ? errors.text : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <label className="admin_add_label">
                                        {t("trainer.quiz.crud.grade")}
                                    </label>
                                    <input
                                        value={values.degree}
                                        onChange={(elem) =>
                                            setFieldValue('degree',elem.target.value)
                                        }
                                        placeholder={t(
                                            "trainer.quiz.crud.input.grade"
                                        )}
                                        className="admin_filter_input"
                                    />
                                    <p className={"form-input-error-space"}>
                                        {errors.degree ? errors.degree : null}
                                    </p>
                                </div>
                            </div>
                        </div>
            
            <div className={"tajah-form-row"}>
              <div className={"tajah-form-row-element-v2"}>
                <div
                  className={"tajah-form-row-element-holder"}
                  style={{ width: "95%" }}
                >
                  <label htmlFor="level" className="admin_add_label">
                    {t("trainer.quiz.crud.level")}
                  </label>
                  <Select
                    name="level"
                    id="level"
                    // isClearable={true}
                    // isMulti={true}
                    value={levels.filter((item) => values.level == item.id ? true :  false)}
                    options={levels}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(item) => {
                      setFieldValue("level", item.id);
                    }}
                    placeholder={t("trainer.quiz.crud.select.level")}
                  />
                  <p className={"form-input-error-space"}>
                    {errors.level ? errors.level : null}
                  </p>
                </div>
              </div>
            </div>

                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <label htmlFor="name_en" className="admin_add_label">
                                        {t("trainer.quiz.crud.objectives")}
                                    </label>
                                    <Select
                                        name="course_objectives"
                                        id="course_objectives"
                                        isClearable={true}
                                        isMulti={true}
                                        defaultValue={values.course_objectives}
                                        options={wrapperContext.objectives}
                                        getOptionLabel ={(option)=>option.name}
                                        getOptionValue ={(option)=>option.id}
                                        onChange={(items) => {
                                            const courseObjectives = [];
                                            items.forEach(item => {
                                                courseObjectives.push(item.id);
                                            })
                                            setFieldValue('course_objectives', courseObjectives);
                                        }}
                                        placeholder={t("trainer.quiz.crud.select.objectives")}/>
                                    <p className={"form-input-error-space"}>
                                        {errors.course_objectives ? errors.course_objectives : null}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{width:"95%"}}>
                                    <label className="admin_add_label">
                                        {t("trainer.quiz.crud.negative_feedback")}
                                    </label>
                                    <input
                                        value={values.feedback}
                                        onChange={(elem) =>
                                            setFieldValue('feedback',elem.target.value)
                                        }
                                        placeholder={t(
                                            "trainer.quiz.crud.input.negative_feedback"
                                        )}
                                        className="admin_filter_input"
                                    />
                                    <p className={"form-input-error-space"}>
                                        {errors.feedback ? errors.feedback : null}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className={"tajah-form-row"}>
                            <div className={"tajah-form-row-element-v2"}>
                                <div className={"tajah-form-row-element-holder"} style={{
                                    width:"95%",
                                    display:"flex",
                                    flexDirection:"row"
                                }}>
                                    <button
                                        type="submit"
                                        className="admin_add_button"
                                        disabled={isSubmitting}
                                    >
                                        {t("crud.placeholders.save")}
                                    </button>

                                    {
                                        props.renderedAs !== 'questionBank' ?
                                            <button
                                                type="button"
                                                className="admin_add_button"
                                                disabled={isSubmitting}
                                                style={{
                                                    backgroundColor: '#CDCDCD',
                                                    color: '#000000',
                                                    marginRight: document.body.dir == 'rtl' ? 10 : 0,
                                                    marginLeft: document.body.dir == 'ltr' ? 10 : 0
                                                }}
                                                onClick={() => {
                                                    wrapperContext.setActivePanel(0);
                                                }}
                                            >
                                                {t("crud.placeholders.previous")}
                                            </button> : null
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    )
}

export default EssayQuestion;