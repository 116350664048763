export const searchConstants = {
  // GET SEARCH DATA
  GET_SEARCH_DATA_REQUEST: "GET_SEARCH_DATA_REQUEST",
  GET_SEARCH_DATA_SUCCESS: "GET_SEARCH_DATA_SUCCESS",
  GET_SEARCH_Tabs_SUCCESS: "GET_SEARCH_Tabs_SUCCESS",
  GET_SEARCH_DATA_FAILURE: "GET_SEARCH_DATA_FAILURE",

  // Send SEARCH 
  SEND_SEARCH_REQUEST: "SEND_SEARCH_REQUEST",
  SEND_SEARCH_SUCCESS: "SEND_SEARCH_SUCCESS",
  SEND_SEARCH_FAILURE: "SEND_SEARCH_FAILURE",

  // GET SAVED SEARCHES
  GET_SAVED_SEARCHES_REQUEST: "GET_SAVED_SEARCHES_REQUEST",
  GET_SAVED_SEARCHES_SUCCESS: "GET_SAVED_SEARCHES_SUCCESS",
  GET_SAVED_SEARCHES_FAILURE: "GET_SAVED_SEARCHES_FAILURE",

  // GET SAVED SEARCH DETAILS
  GET_SAVED_SEARCH_DETAILS_REQUEST: "GET_SAVED_SEARCH_DETAILS_REQUEST",
  GET_SAVED_SEARCH_DETAILS_SUCCESS: "GET_SAVED_SEARCH_DETAILS_SUCCESS",
  GET_SAVED_SEARCH_DETAILS_FAILURE: "GET_SAVED_SEARCH_DETAILS_FAILURE",

  // DELETE SAVED SEARCH
  DELETE_SAVED_SEARCH_REQUEST: "DELETE_SAVED_SEARCH_REQUEST",
  DELETE_SAVED_SEARCH_SUCCESS: "DELETE_SAVED_SEARCH_SUCCESS",
  DELETE_SAVED_SEARCH_FAILURE: "DELETE_SAVED_SEARCH_FAILURE",

  // UPDATE SAVED SEARCH
  UPDATE_SAVED_SEARCH_REQUEST: "UPDATE_SAVED_SEARCH_REQUEST",
  UPDATE_SAVED_SEARCH_SUCCESS: "UPDATE_SAVED_SEARCH_SUCCESS",
  UPDATE_SAVED_SEARCH_FAILURE: "UPDATE_SAVED_SEARCH_FAILURE",

  RESET_SEARCH: "RESET_SEARCH",

  // GET FREE SEARCH 
  GET_FREE_SEARCH_REQUEST: "GET_FREE_SEARCH_REQUEST",
  GET_FREE_SEARCH_SUCCESS: "GET_FREE_SEARCH_SUCCESS",
  GET_FREE_SEARCH_FAILURE: "GET_FREE_SEARCH_FAILURE",

  // RESET FREE SEARCH
  RESET_FREE_SEARCH: "RESET_FREE_SEARCH",
};
