import QuestionTypeEnum, {
  QuestionTypeWithFile,
  QuestionTypeWithOptions,
} from "../../../../../Enums/QuestionTypeEnum";
import { hasExtension } from "../../../../../utils";

export default function QuestionValidator(values, t) {
  const errors = {};
  if (!values.type_id) {
    errors.type_id = t("crud.errors.required");
  }
  if (!values.title) {
    errors.title = t("crud.errors.required");
  }
  // else if (values.title.length > 180) {
  //   errors.title = t("The field text is too long");
  // }
  if (!values.degree && values.degree != "0") {
    errors.degree = t("crud.errors.required");
  } else if (parseFloat(values.degree) < 0) {
    errors.degree = `${t("crud.errors.min")} 0`;
  }
  if (!values.level) {
    errors.level = t("crud.errors.required");
  }
  if (
    [QuestionTypeEnum.text, QuestionTypeEnum.essay_question].includes(
      values.type_id
    )
  ) {
    const weight = values.standards.reduce(
      (pre, next) => pre + parseInt(next.weight),
      0
    );
    if (!values.standards.length) {
      errors.standards = t("crud.errors.required");
    } else if (weight < 0 || weight > 100) {
      errors.standards = `${t("criteriastandard")} 100`;
    } else if (weight != 100) {
      errors.standards = `${t("criteriastandard")} ${101 - weight}`;
    }
  }

  // if (!values.feedback && values.feedback != "0") {
  //   errors.feedback = t("crud.errors.required");
  // }
  if (!values.course_objectives?.length) {
    errors.course_objectives = t("crud.errors.required");
  }

  if (
    [...QuestionTypeWithFile, QuestionTypeEnum.hotspot].includes(
      values.type_id
    ) &&
    !values.media
  ) {
    errors.media = t("crud.errors.required");
  } else {
    if (!values.media?.id && values.media?.name) {
      if (
        !hasExtension(values.media.name, getQuestionFileTypes(values.type_id))
      ) {
        errors.media = `${t(
          "Invalid attachment type, please select a file type"
        )} (${getQuestionFileAcceptMsg(values.type_id)})`;
      } else if (values.media.size > 10485760) {
        errors.media = `${t("The attachment size must not exceed")} 10MG`;
      }
    }
  }

  if (
    [...QuestionTypeWithOptions, QuestionTypeEnum.hotspot].includes(
      values.type_id
    )
  ) {
    if (!values.options?.length) {
      errors.options = t("crud.errors.required");
    } else {
      let options = [];
      values.options?.forEach((option, optionIndex) => {
        if (values.type_id == QuestionTypeEnum.ordering) {
          option.value = optionIndex;
          values.options[optionIndex] = option;
        }
        let optionErr = {};
        if (!option.text) optionErr.text = t("crud.errors.required");
        else if (values.type_id == QuestionTypeEnum.drag_and_drop) {
          if (!option.value) optionErr.value = t("Image field is required");
          else if (option.value?.name) {
            if (!hasExtension(option.value.name, [".png", ".jpg", ".jpeg"])) {
              optionErr.value = `${t(
                "Invalid image type, please select an image type"
              )} (PNG, JPG, JPEG)`;
            } else if (option.value.size > 5242880)
              optionErr.value = `${t("The image size must not exceed")} 5MG`;
          }
        }
        if (Object.keys(optionErr).length) options[optionIndex] = optionErr;
      });
      if (options.length) errors.options = options;
      else if (
        [QuestionTypeEnum.radio_button, QuestionTypeEnum.checkbox].includes(
          values.type_id
        )
      ) {
        let hasCorrect = false;
        values.options.forEach((item) => {
          if (item.value) {
            hasCorrect = true;
          }
        });
        if (!hasCorrect) errors.options = t("You must select a correct answer");
      }
    }
  }
  return errors;
}

export function getQuestionFileTypes(typeId) {
  let accepetTypes = [];
  if (typeId == QuestionTypeEnum.hotspot) {
    accepetTypes = [".png", ".jpg", ".jpeg"];
  } else if (
    typeId == QuestionTypeEnum.scorm ||
    typeId == QuestionTypeEnum.xapi
  ) {
    accepetTypes = [".zip"];
  } else if (typeId == QuestionTypeEnum.html) {
    accepetTypes = [".html"];
  } else if (typeId == QuestionTypeEnum.h5p) {
    accepetTypes = [".h5p"];
  }
  return accepetTypes;
}

export function getQuestionFileAcceptMsg(typeId) {
  return getQuestionFileTypes(typeId)
    .join()
    .replaceAll(".", "")
    .toLocaleUpperCase();
}
